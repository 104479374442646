import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import * as charts from '../../../../../services/charts'

const RevenueBarChart = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    charts.getRevnue()?.then((data) => {
      if (data?.data?.data?.bar_chart_revenue?.length) {
        setData(data?.data?.data?.bar_chart_revenue)
      }
    })
  }, [])
  return (
    <ResponsiveContainer height={300} width={'100%'}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='net_revenue' fill='#8884d8' />
        <Bar dataKey='total_revenue' fill='#82ca9d' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueBarChart;
