import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
const columns: ColumnProps<any>[] = [

  {
    key: 'transaction_id',
    dataIndex: 'transaction_id',
    title: 'Transaction Id',
    fixed: 'left',
    className: 'text-align-rigth',
    sorter: (a, b) => (a.transaction_id > b.transaction_id ? 1 : -1),
    render: (transaction_id) => (
      <strong className='text-right text-color-200'>{transaction_id}</strong>
    )
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: 'Amount',
    className: 'text-align-rigth',
    sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
    render: (amount) => <span className='text-right text-color-200'>{amount} OMR</span>
  },
  {
    key: 'thawani_fee',
    dataIndex: 'thawani_fee',
    title: 'Thawani Fee',
    sorter: (a, b) => (a.thawani_fee > b.thawani_fee ? 1 : -1),
    render: (thawani_fee) => <span>{`${thawani_fee} OMR`}</span>
  },
  {
    key: 'commision',
    dataIndex: 'commision',
    title: 'Commision',
    sorter: (a, b) => (a.commision > b.commision ? 1 : -1),
    render: (commision) => <span>{commision ? `${commision} OMR` : '-'}</span>
  },
  {
    key: 'markup',
    dataIndex: 'markup',
    title: 'Markup',
    sorter: (a, b) => (a.markup > b.markup ? 1 : -1),
    render: (markup) => <span>{markup ? `${markup} OMR` : '-'}</span>
  },
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
  type?: any;
};
const tableClasses = {
  accent: 'accent-header',
  none: ''
};
const TransactionTable = ({ data, actions, pagination, loading, handleTableChange, type = 'none' }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    fixed: 'right',
    render: actions
  };
  const displayedColumns = actions ? [...columns, actionColumn] : columns;
  const tableClass = tableClasses[type];

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      className={tableClass}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default TransactionTable;
