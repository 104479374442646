import React, { useEffect, useState } from 'react';

import { Button, Input, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as healthPrograms from '../../../services/health-programs';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import UploadGallery from '../../../components/UploadImages/FileUploadGallery';
import FileUpload from '../../../components/UploadImages/FileUpload';
// import ImageLoader from '../../../layout/components/ImageLoader';
import { useParams, useNavigate } from 'react-router-dom'; //useHistory relpace usenavigate
import api from '../../../services/Api';

const { Item } = Form;
const { TextArea } = Input;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const HealthProgramAddUpdateForm = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [languages, setLanguages] = useState([]);
  const isView = localStorage.getItem('isViewLabTest');

  const history: any = useNavigate(); //useHistory replace useNavigate
  const { id } = useParams<any>();
  /* Fetch fields data */
  useEffect(() => {
    if (id && id !== 'create') {
      fetchAndSetData();
    }
    api.get('/languages').then((res: any) => {
      if (res?.data?.data?.length) {
        setLanguages(res?.data?.data);
      }
    });
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    healthPrograms.getById(+id).then((res: any) => {
      setValues(res?.data?.data);
    });
  };
  useEffect(() => {
    const { id } = values;
    if (Object?.keys(values)?.length && id !== 'create') {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues(clonedValues);
    }
  }, [values]);
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'latLng') {
      form.setFieldsValue({
        lat: value?.lat,
        longitude: value?.longitude,
        address: value?.address
      });
    } else {
      if (formName === 'country_id') {
        form.resetFields(['state_id', 'city_id']);
      } else if (formName === 'state_id') {
        form.resetFields(['city_id']);
      }
      setCustomFormValues({ ...customFormValues, [formName]: value });
      console.log(value);
    }
  };
  /* performing operations before hitting api */
  const formateFormBeforeSubmit = (formValues: any) => {
    formValues.cover_image = customFormValues?.cover_image;
    formValues.images = customFormValues?.images;
    formValues.language_id = formValues?.language_id;
    return formValues;
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    setTimeout(() => {
      history(-1);
    }, 1000);
  };
  const handleSubmit = async (formValues: any) => {
    const formValuesFormate = formateFormBeforeSubmit(formValues);
    try {
      setLoading(true);
      if (values?.id) {
        const data = await healthPrograms.update(formValuesFormate, values.id);
        setLoading(false);
        if (data?.status === 200) {
          operationsAfterSubmit(data);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await healthPrograms.add(formValuesFormate);
        setLoading(false);
        if (data?.status === 200) {
          operationsAfterSubmit(data);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  // function TextComponent({ text }) {
  //   const isEnglish = /^[A-Za-z\s]+$/u.test(text);

  //   const style = {
  //     fontFamily: isEnglish ? 'Arial, sans-serif' : 'Serif, sans-serif',
  //     fontSize: '16px', // You can include other styles as needed
  //     color:  isEnglish ? 'red' : 'blue'
  //   };

  //   return (
  //     <div style={style}>
  //       {text}
  //     </div>
  //   );
  // }
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <>
      <Form
        disabled={ViewPageDisable()}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          is_active: 1
        }}
      >
        {/* <h1 style={{color:(/^[A-Za-z\s]+$/u.test("ads") )}} */}

        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <Item
                name='language_id'
                rules={[{ required: true, message: 'language is required' }]}
              >
                <Select
                  placeholder='Select Language'
                  onChange={(value) => handleCustomFormChange(value, 'language_id')}
                >
                  {languages?.length > 0 &&
                    languages?.map((item: any, i) => (
                      <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                        {item?.title}{' '}
                      </Select.Option>
                    ))}
                </Select>
              </Item>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                  <Item
                    name='title'
                    label='Title'
                    rules={[{ required: true, message: 'title is required' }]}
                  >
                    <Input placeholder='Title' type='text' />
                  </Item>
                </div>
              </div>

              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                  <Item
                    name='creator'
                    label='Creator'
                    rules={[{ required: true, message: 'creator is required' }]}
                  >
                    <Input placeholder='creator' type='text' />
                  </Item>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <Item
                name='short_description'
                label='Short Description'
                // rules={[{ required: true, message: 'description is required' }]}
              >
                <TextArea
                  className={`${/^[A-Za-z\s]+$/u.test('dnaish')} ? "":arabic`}
                  rows={2}
                  placeholder='Short description'
                />
              </Item>
            </div>
            <div className='form-group'>
              <Item
                name='description'
                label='Description'
                rules={[{ required: true, message: 'Description is required' }]}
              >
                <TextArea rows={5} placeholder='Description' />
              </Item>
            </div>

            <div className='form-group'>
              <Item name='cover_image' label='Upload Cover Image'>
                <FileUpload
                  uploadType='coverImage'
                  mediaLink={form.getFieldValue('cover_image')}
                  placeholder='Upload Cover Image'
                  handleUpload={(img) => handleCustomFormChange(img, 'cover_image')}
                />
              </Item>
            </div>
            <Item label='Upload Gallery Images'>
              <div style={{ marginTop: 10 }}>
                <UploadGallery
                  imagesList={form.getFieldValue('images')}
                  placeholder='Upload Gallery Images'
                  handleUpload={(imges) => handleCustomFormChange(imges, 'images')}
                />
              </div>
            </Item>
            {isView ? (
              <></>
            ) : (
              // <div className='d-flex justify-content-between'>
              //   <Button onClick={() => history(-1)} type='default'>              {/* goback() replace with (-1)  in 212 215 221*/}
              //     Go back
              //   </Button>
              //   <Button block={false} onClick={() => history(-1)} type='primary'>
              //     Ok
              //   </Button>
              // </div>
              <div className='d-flex justify-content-between'>
                <Button onClick={() => history(-1)} type='default'>
                  Cancel
                </Button>
                <Button
                  block={false}
                  type='primary'
                  disabled={loading}
                  // loading={loading}
                  htmlType='submit'
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
      <div style={{ width: '530px' }}>
        {isView ? (
          <div className='d-flex justify-content-between'>
            <Button onClick={() => history(-1)} type='default'>
              {' '}
              {/* goback() replace with (-1)  in 212 215 221*/}
              Go back
            </Button>
            <Button block={false} onClick={() => history(-1)} type='primary'>
              Ok
            </Button>
          </div>
        ) : (
          <></>
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={() => history(-1)} type='default'>
          //     Cancel
          //   </Button>
          //   <Button
          //     block={false}
          //     type='primary'
          //     disabled={loading}
          //     // loading={loading}
          //     htmlType='submit'
          //   >
          //     Submit
          //   </Button>
          // </div>
        )}
      </div>
    </>
  );
};

export default HealthProgramAddUpdateForm;
