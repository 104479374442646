import React, { useEffect, useState } from 'react';

import { Button, Form, Input } from 'antd';
import PublicLayout from '../../layout/public/Public';
import { useForm } from 'antd/es/form/Form';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import { forgetPassword } from '../../services/login';
import { useNavigate } from 'react-router-dom';

const { Item } = Form;
const ForgetPassword = () => {
    const history: any = useNavigate();
    const [form] = useForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        try {
            setLoading(true);
            let data = await forgetPassword(values);
            setLoading(false);
            if (data?.status === 200) {
                showSuccessMessage(data)
                history.goBack()
            } else {
                showErrorMessage(data);
                setLoading(false);
            }
        } catch (e: any) {
            showErrorMessage(e.data);
            setLoading(false);
        }
    };

    return (
        <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
            <h4 className='mt-0 mb-1 center-align'>Forget Password</h4>

            <p className='text-color-200 center-align margin-bottom-small'>
                Type your email for recovery link
            </p>

            <Form form={form} layout='vertical' className='mb-4' onFinish={handleSubmit}>
                <Item name='email' rules={[{ required: true, message: 'Email is required' }]}>
                    <Input placeholder='Email' type='email' />
                </Item>
                <div className='d-flex justify-content-between'>
                    <Button onClick={() => history.goBack()} type='link' >
                        Go Back
                    </Button>
                    <Button
                        block={false}
                        type='primary'
                        loading={loading}
                        htmlType='submit'
                        className='center-align width-100'
                    >
                        Submit
                    </Button>
                </div>
            </Form>
            <br />
        </PublicLayout>
    );
};

export default ForgetPassword;
