import React from 'react';
import { NavLink } from 'react-router-dom';
import { IMenuItemIcon } from '../../../interfaces/main-menu';

type Props = {
  title: string;
  route: string;
  layout: string;
  icon: IMenuItemIcon;
  is_active?: IMenuItemIcon;
};

const SimpleItem = ({ route, title, layout, icon }: Props) => (
  <li className='menu-item'>
    <NavLink className='item-link' to={`/${layout}/${route}`}
    //  activeClassName='active' 
     replace>
      {icon && (
        <span
          className={`link-icon ${icon}`}
          // style={{ backgroundColor: icon.bg, color: icon.color }}
        />
      )}
      <span className='link-text'>{title}</span>
    </NavLink>
  </li>
);

export default SimpleItem;
