import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Select,
  Timeline
} from 'antd';

import { usePageData } from '../../hooks/usePage';
import { useParams, useNavigate } from 'react-router-dom'; //useHistory remove kia hai
import * as appointments from '../../services/appointments';
import Spinner from '../../components/Spinner';
import { useForm } from 'antd/lib/form/Form';
import pillsLogo from '../../assets/img/pills.png';
import moment from 'moment';
import TablePageAddAction from '../../components/common/tables-components/TablePageAddAction';
import PrescriptionForm from './prescriptions/PrescriptionForm';

const getPageData = (id) => {
  const pageData: any = {
    title: 'Appointment',
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Dashboard',
        route: 'dashboard'
      },
      {
        title: 'Appointments',
        route: 'appointments'
      },
      {
        title: id ? id : 'profile'
      }
    ]
  };
  return pageData;
};

const { Item } = Form;
const ProfileForm = ({ values }) => {
  const [form] = useForm();
  const clonedValues = { ...values };
  if (clonedValues.date) {
    clonedValues.date = moment(clonedValues.date);
  }
  form.setFieldsValue({
    ...clonedValues
  });
  return (
    <Form
      disabled
      form={form}
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      initialValues={values}
    >
      <div className='form-group'>
        <Item label='Doctor Name'>
          {' '}
          {/* name='doctor_id' */}
          <Input
            defaultValue={values?.doctor?.fullName}
            readOnly
            placeholder='Doctor Name'
            type='text'
          />
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='booking_for'
          label='Booking'
          rules={[{ required: true, message: 'Consultation fee currency  is required' }]}
        >
          <Select placeholder='Booking For' open={false}>
            <Select.Option value='self'>Self</Select.Option>
            <Select.Option value='someone_else'>Other</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='form-group'>
        <Item
          // name='name'
          label='Patient Name'
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input
            defaultValue={values?.patient?.name}
            readOnly
            placeholder='Patient Name'
            type='text'
          />
        </Item>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              // name='age'
              label='Patient Age'
              rules={[{ required: true, message: 'age is required' }]}
            >
              <Input defaultValue={values?.patient?.age} readOnly placeholder='Age' type='number' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              // name='gender'
              label='Gender'
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <Select placeholder='Gender' open={false} defaultValue={values?.patient?.gender}>
                <Select.Option value='male'>Male</Select.Option>
                <Select.Option value='female'>Female</Select.Option>
                <Select.Option value='other'>Other</Select.Option>
              </Select>
            </Item>
          </div>
        </div>
      </div>
      <div className='form-group'>
        <Item
          // name='type'
          label='Treatment Type'
          rules={[{ required: true, message: 'Consultation fee currency  is required' }]}
        >
          <Select placeholder='Type' open={false}>
            <Select.Option value='physical'>Physical</Select.Option>
            <Select.Option value='video'>Video</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              //  name='date'
              label='Date'
            >
              <DatePicker
                disabled
                open={false}
                placeholder='Date'
                size='large'
                style={{ width: '100%', borderRadius: 30 }}
              />
            </Item>
          </div>
        </div>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              // name='time'
              label='Time'
              rules={[{ required: true, message: 'Time shift is required' }]}
            >
              <Select open={false} placeholder='Time shift'></Select>
            </Item>
          </div>
        </div>
      </div>
      <div className='form-group'>
        <Item
          // name='mobile'
          label='Mobile Number'
          rules={[{ required: true, message: 'Mobile number is required' }]}
        >
          <Input
            defaultValue={values?.patient?.phone}
            readOnly
            placeholder='Mobile Number'
            type='number'
          />
        </Item>
      </div>
      <Divider orientation='left' className='divider-legend'>
        Insurance Detail
      </Divider>
      {values?.patient?.expiry && (
        <div className='form-group'>
          <Item
            // name='expiry'
            label='Insurance Expiry'
          >
            <DatePicker
              disabled
              open={false}
              placeholder='Date'
              size='large'
              style={{ width: '100%', borderRadius: 30 }}
              // @ts-ignore
              defaultValue={moment(values?.patient?.expiry, 'YYYY-MM-DD')}
            />
            {/* <Input
                  readOnly
                  placeholder='Insurance Expiry'
                  type='number'
                /> */}
          </Item>
        </div>
      )}
      {values?.patient?.policy_number && (
        <div className='form-group'>
          <Item
            // name='policy_number'
            label='Policy Number'
          >
            <Input
              defaultValue={values?.patient?.policy_number}
              readOnly
              placeholder='Insurance policy_number'
              type='number'
            />
          </Item>
        </div>
      )}
    </Form>
  );
};

const AppointmentViewPage = () => {
  const [values, setValues] = useState<any>({});
  const [editPrescriptions, setEditPrescriptions] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const history: any = useNavigate(); //useNavigate use agianst useHistory()
  const { id } = useParams<any>();

  usePageData(getPageData(id));

  /* Fetch fields data */
  useEffect(() => {
    /* Fetch data */

    if (id && id !== 'create') {
      fetchAndSetData();
    }
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    appointments.getById(+id).then((res: any) => {
      if (res?.data?.data) {
        setValues(res?.data?.data);
        /* Set prescriptallPrescriptions  */
        // setAllPrescription(res?.data?.data?.prescriptions)
      }
    });
  };
  const openPrescriptionModal = () => {
    setModalVisibility(true);
  };

  const closePrescriptionModal = () => {
    setModalVisibility(false);
    setEditPrescriptions(false);
  };

  const handleEditModal = () => {
    setModalVisibility(true);
    setEditPrescriptions(true);
  };
  return Object?.keys(values)?.length ? (
    <>
      <div className='row mb-4'>
        <div className='col-md-6 col-sm-12'>
          <div className='info stack'>
            <ProfileForm values={values} />
            <div className='d-flex justify-content-between'>
              <Button onClick={() => history(-1)} type='default'>
                {' '}
                {/*replce goback with (-1)*/}
                Go back
              </Button>
              <Button onClick={() => history(-1)}  block={false} type='primary'>
                Ok
              </Button>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-sm-12'>
          <Card>
            <AppointmentTimeline values={values} handleEditModal={handleEditModal} />
          </Card>
        </div>
        {/* Add prescription option if doctor have consulted */}
        {values?.status === 'consulted'
          ? TablePageAddAction({ onAdd: openPrescriptionModal, module: 'prescriptions' })
          : null}
        {/* Add update form appointment */}
        {modalVisibility && values?.doctor_id && (
          <PrescriptionForm
            onClose={closePrescriptionModal}
            visible={modalVisibility}
            isEditModal={editPrescriptions}
            values={values?.prescriptions ? values?.prescriptions : []}
            doctor_id={values?.doctor_id}
            appointment_id={+id}
          />
        )}
      </div>
    </>
  ) : (
    <Spinner />
  );
};
const AppointmentTimeline = ({ values, handleEditModal }) => {
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState<any>({});

  const handlePrescription = (data) => {
    setPrescriptionData(data);
    setPrescriptionModal(true);
  };
  const generatePrescriptionPdf = async (id) => {
    appointments.generatePrescriptionPdf(id);
  };
  const getMedicineCard = (medicine) => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className='text-base text-color-100'>
            {`Date Start => ${moment(new Date(medicine?.starts)).format('DD MMM YYYY')}`}
          </span>
          <span className='text-base text-color-100'>
            {`Date End => ${moment(new Date(medicine?.ends)).format('DD MMM YYYY')}`}
          </span>
        </div>
        <p>{`${medicine?.repeats}`}</p>
      </div>
    );
  };
  let appointmentHistory = values?.history;

  return (
    <>
      <Timeline
        mode='left'
        items={[
          {
            children: values?.prescriptions?.length && (
              // <Timeline.Item color='#14BFFF'>
              <div className='d-flex flex-column color-[#14BFFF]'>
                <div className='d-flex flex-row' style={{ color: '#14BFFF', cursor: 'pointer' }}>
                  <h4 className='m-0' onClick={() => handlePrescription(values)}>
                    Prescription
                  </h4>
                  <span
                    className='icofont icofont-edit-alt'
                    onClick={handleEditModal}
                    style={{ cursor: 'pointer', marginLeft: 5, alignSelf: 'center' }}
                  />
                </div>
                <span className='text-base text-color-100'>
                  {moment(new Date(values?.prescriptions[0]?.created_at)).format('DD MMM YYYY')}
                </span>
                <span className='text-base'>Dr.{values?.doctor?.fullName} prescribed</span>
              </div>
            )
            // {/* </Timeline.Item> */}
          },
          {
            children:
              appointmentHistory?.length &&
              appointmentHistory?.reverse()?.map((item, index) => {
                let status = item?.status?.toString();
                return (
                  // <Timeline.Item color='#ed5564' key={`appoinnment-history-${index}`}>
                  <div
                    key={`appoinnment-history-${index}`}
                    className='d-flex flex-column color-[#ed5564]'
                  >
                    <h4 className='m-0'>
                      {status[0]?.toUpperCase() + status?.slice(1)?.toLowerCase()}
                    </h4>
                    <span className='text-base text-color-100'>
                      {moment(new Date(item?.created_at)).format('DD MMM YYYY')}
                    </span>
                    <span className='text-base'>{item?.description}</span>
                  </div>
                  // </Timeline.Item>
                );
              })
          }
        ]}
      />
      {/* 
        {values?.prescriptions?.length && (
          <Timeline.Item color='#14BFFF'>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row' style={{ color: '#14BFFF', cursor: 'pointer' }}>
                <h4
                  className='m-0'
                  onClick={() => handlePrescription(values)}
                >
                  Prescription
                </h4>
                <span className='icofont icofont-edit-alt' onClick={handleEditModal} style={{ cursor: "pointer", marginLeft: 5, alignSelf: "center" }} />
              </div>
              <span className='text-base text-color-100'>
                {moment(new Date(values?.prescriptions[0]?.created_at)).format('DD MMM YYYY')}
              </span>
              <span className='text-base'>Dr.{values?.doctor?.fullName} prescribed</span>
            </div>
          </Timeline.Item>
        )}
        {appointmentHistory?.length &&
          appointmentHistory?.reverse()?.map((item,index) => {
            let status = item?.status?.toString();
            return (
              <Timeline.Item color='#ed5564' key={`appoinnment-history-${index}`}>
                <div className='d-flex flex-column'>
                  <h4 className='m-0'>
                    {status[0]?.toUpperCase() + status?.slice(1)?.toLowerCase()}
                  </h4>
                  <span className='text-base text-color-100'>
                    {moment(new Date(item?.created_at)).format('DD MMM YYYY')}
                  </span>
                  <span className='text-base'>{item?.description}</span>
                </div>
              </Timeline.Item>
            );
          })}
      </Timeline> */}
      {prescriptionModal && (
        <Modal
          open={prescriptionModal}
          onCancel={() => setPrescriptionModal(false)}
          footer={null}
          destroyOnClose
          title={<h3 className='title'>Prescription</h3>}
        >
          <h5>
            <span style={{ fontSize: 24 }}>Dr. {prescriptionData?.doctor?.fullName}</span>{' '}
            prescribed to <span style={{ fontSize: 24 }}>{prescriptionData?.patient?.name}</span>
          </h5>
          {prescriptionData?.prescriptions && prescriptionData?.prescriptions?.length && (
            <List
              itemLayout='vertical'
              size='small'
              dataSource={
                prescriptionData?.prescriptions?.length ? prescriptionData?.prescriptions : []
              }
              renderItem={(item: any) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={pillsLogo}
                        alt='pills'
                        width={60}
                        height={60}
                        style={{ marginTop: 20 }}
                      />
                    }
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <span>{`${item?.medicine_name} ${item?.dosage}${item?.dosage_unit}`}</span>
                          <p>
                            {item?.repeats} {item?.when}
                          </p>
                        </div>
                        <p>{item?.quantity}</p>
                      </div>
                    }
                    description={getMedicineCard(item)}
                  />
                  <div style={{ marginLeft: 76 }}>{item?.message}</div>
                </List.Item>
              )}
            />
          )}
          {prescriptionData.lab_tests && prescriptionData?.lab_tests?.length ? (
            <div>
              <h5 style={{ marginBottom: 0 }}>Lab Tests</h5>
              <List
                itemLayout='vertical'
                size='small'
                dataSource={prescriptionData?.lab_tests}
                renderItem={(item: any) => {
                  return (
                    <>
                      <List.Item key={item?.id}>
                        <List.Item.Meta
                          // avatar={
                          //   <img
                          //     src={pillsLogo}
                          //     alt='pills'
                          //     width={60}
                          //     height={60}
                          //     style={{ marginTop: 20 }}
                          //   />
                          // }
                          title={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: 0
                              }}
                            >
                              <div>
                                <span>{`${item?.test_name}`}</span>
                              </div>
                            </div>
                          }
                          description={item?.message}
                        />
                      </List.Item>
                    </>
                  );
                }}
              />
            </div>
          ) : null}
          {prescriptionData?.referred_doctor
            ? Object.values(prescriptionData?.referred_doctor)?.length && (
                <>
                  <h5>
                    Refered to{' '}
                    <span style={{ fontSize: 24 }}>
                      Dr.{prescriptionData?.referred_doctor?.doctor?.fullName}
                    </span>
                  </h5>
                  <p>{prescriptionData?.referred_doctor?.message}</p>
                </>
              )
            : null}
          {/* {pdfRes} */}
          <div className='d-flex justify-content-between'>
            <Button onClick={() => setPrescriptionModal(false)} type='default'>
              Close
            </Button>
            <Button block={false} onClick={() => generatePrescriptionPdf(prescriptionData?.id)}>
              Generate Pdf
            </Button>
            <Button block={false} onClick={() => setPrescriptionModal(false)} type='primary'>
              Ok
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
export default AppointmentViewPage;
