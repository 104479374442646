import React, { PropsWithChildren, useEffect, useState } from 'react';
// import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, ChannelList, LoadingIndicator, MessageInput, MessageList, Thread, Window, ChannelListMessengerProps, ChatDownProps, CustomClasses, CustomStyles } from 'stream-chat-react';
import './chatbox.css'
import 'stream-chat-react/dist/css/index.css';
import { useSelector } from 'react-redux';
import AllUsersList from '../../components/chat/AllUsersList';
import AllChannelList from '../../components/chat/ChannelList';
import ChatUsersList from '../../components/chat/ChatUserList';
import { IPageData } from '../../interfaces/page';
import { useFetchPageData, useOnlyPageData, usePageData } from '../../hooks/usePage';
import PageAction from '../../layout/components/page-action/PageAction';

const customStyles: CustomStyles = {
  '--primary-color': '#14BFFF',
  // '--md-font': '1.2rem',
  // '--xs-m': '1.2rem',
  // '--xs-p': '1.2rem',
  // '--bg-gradient-end': '#101214',
  // '--bg-gradient-start': '#070a0d',
  // '--black': '#ffffff',
  // '--blue-alice': '#00193d',
  // '--border': '#141924',
  // '--button-background': '#ffffff',
  // '--button-text': '#005fff',
  '--grey': '#f7f7f7',
  '--grey-gainsboro': '#f7f7f7',
  '--grey-whisper': '#f7f7f7',
  // '--modal-shadow': '#000000',
  // '--overlay': '#00000066',
  // '--overlay-dark': '#ffffffcc',
  // '--shadow-icon': '#00000080',
  // '--targetedMessageBackground': '#302d22',
  // '--transparent': 'transparent',
  // '--white': '#101418',
  // '--white-smoke': '#13151b',
  // '--white-snow': '#070a0d',

  '--border-radius': '10px',

};

const customClasses: CustomClasses = {
  chat: 'str-chat',
  // channel: 'str-chat__channel-list-messenger str-chat str-chat-channel-list',
};
// .str-chat .str-chat-channel
// .str-chat__channel-list-messenger
//.str-chat str-chat-channel-list


const pageData: IPageData = {
  title: '',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Chat'
    }
  ]
};
const ChatBox = () => {
  const [newChat, setNewChat] = useState(false)
  useOnlyPageData(pageData);
  const client = useSelector((state: any) => state?.chat?.client)

  if (!client) {
    return <LoadingIndicator />;
  }

  const ChatComponent =
    <div className='d-flex justify-content-between'>
      {/* <div style={{ width: "20%" }}>
        <AllUsersList />
      </div> */}
      <div style={{ width: "100%" }}>
        <Chat client={client} theme='messaging light' customStyles={customStyles} customClasses={customClasses}>
          <AllChannelList />
          <Channel >
            <Window >
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </div>
    </div>
  return <>
    <PageAction onClick={() => setNewChat(!newChat)} icon='icofont-plus' type={'primary'} />
    {newChat &&
      <ChatUsersList visible={newChat} onClose={() => setNewChat(false)} />
    }
    {ChatComponent}
  </>
};

export default ChatBox;