import React, { ReactNode } from 'react';

import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';

const columns: ColumnProps<any>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'name_ar',
    dataIndex: 'name_ar',
    title: 'Arabic Name',
    // width: 150,
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong className='arabic'>{name}</strong>
  },
  {
    key: 'capital',
    dataIndex: 'capital',
    title: 'Capital',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (capital) => <strong>{capital}</strong>
  },
  {
    key: 'region',
    dataIndex: 'region',
    title: 'Region',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (region) => <strong>{region}</strong>
  },
  // {
  //   key: 'subregion',
  //   dataIndex: 'subregion',
  //   title: 'Subregion',
  //   sorter: (a, b) => (a.name > b.name ? 1 : -1),
  //   render: (subregion) => <strong>{subregion}</strong>
  // },
  {
    key: 'phone_code',
    dataIndex: 'phone_code',
    title: 'Phone Code',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (phone_code) => <strong>{phone_code}</strong>
  },
  {
    key: 'currency',
    dataIndex: 'currency',
    title: 'Currency',
    render: (currency) => <strong>{currency}</strong>
  },
  // {
  //   key: 'currency_symbol',
  //   dataIndex: 'currency_symbol',
  //   title: 'Currency Symbol',
  //   render: (currency_symbol) => <strong>{currency_symbol}</strong>
  // }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const CountriesTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 200,
    fixed: 'right',
    render: actions
  };
console.log(data);

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      // scroll={{ x: 1500 }}
      onChange={handleTableChange}
    />
  );
};

export default CountriesTable;
