
// new code
import React from 'react';
import { Collapse } from 'antd';
import PermissionsAddUpdateForm from './PermissionsAddUpdate';
const { Panel } = Collapse;
const PermissionsGroups = () => {
  const allUserTypes = [
    {
      key: 'doctor',
      title: 'Doctor'
    },
    {
      key: 'laboratory',
      title: 'Laboratory'
    },
    {
      key: 'hospital',
      title: 'Hospital'
    },
    {
      key: 'pharmacy',
      title: 'Pharmacy'
    },
    {
      key: 'admin',
      title: 'Admin'
    }
  ];
  const items = allUserTypes.map((user) => ({
    key: user.key,
    label: `${user.title}`,
    children:     <PermissionsAddUpdateForm user={user} />
  }));
  const onChange = (key: any) => {
    console.log(key);
  };
  return (
    <Collapse items={items}  onChange={onChange} />
  );
};
export default PermissionsGroups;











// old code



// import React from 'react';

// import { Collapse } from 'antd';
// import PermissionsAddUpdateForm from './PermissionsAddUpdate';

// const { Panel } = Collapse;
// const PermissionsGroups = () => {
//   const allUserTypes = [
//     {
//       key: 'doctor',
//       title: 'Doctor'
//     },
//     {
//       key: 'laboratory',
//       title: 'Laboratory'
//     },
//     {
//       key: 'hospital',
//       title: 'Hospital'
//     },
//     {
//       key: 'pharmacy',
//       title: 'Pharmacy'
//     },
//     {
//       key: 'admin',
//       title: 'Admin'
//     }
//   ];

//   function callback(key) {
//     console.log(key);
//   }

//   // const items = allUserTypes?.map((user) => {});

//   return (
//     <Collapse
//       onChange={callback}
//       items={allUserTypes?.map((user) => {
//         return (
//           <Panel header={user?.title} key={user?.key}>
//             <Collapse>
//               <PermissionsAddUpdateForm user={user} />
//             </Collapse>
//           </Panel>
//         );
//       })}
//     />
//     // </Collapse>
//   );
// };

// export default PermissionsGroups;
