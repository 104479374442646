// import { SendOutlined } from '@ant-design/icons';
// import { ExportOutlined  } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useState } from 'react';
import { exportData } from '../../services/common';
// import { getPermissions } from '../../utils/StorageService';
import ImportFileUpload from '../UploadImages/ImportFileUpload';
import iconExport from "../../assets/img/Danish-Kranti-Icon.png"


const ImportExportData = ({ url, notImport }: any) => {
  //   const permissions = JSON.parse(getPermissions());
  //   const modulePermissions = permissions?.['import_export'] || {};
  const [exportLoading, setExportLoading] = useState(false)

  return (
    <Space style={{ marginBottom: 16 }} className='d-flex justify-content-end'>
      <Button
        className='custom-border tranparent color-black'
        type='primary'
        block={false}
        icon={<img src={iconExport} height={"12px"} width={"12px"} />}
        loading={exportLoading}
        onClick={() => exportData(url, (flag) => 
         setExportLoading(flag))}
      >
        Export
      </Button>
      {notImport !== true &&
        <ImportFileUpload url={url} />
      }
    </Space>
  );
};
export default ImportExportData;
