import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
import AvatarImageDisplay from '../../../components/common/AvatarImageDisplay';

const columns: ColumnProps<any>[] = [
  {
    key: 'cover_image',
    title: 'Cover Image',
    dataIndex: 'cover_image',
    width: 120,
    render: (img) => <AvatarImageDisplay img={img} type='cover' />
  },
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Title',
    width: 150,
    sorter: (a, b) => (a.title > b.title ? 1 : -1),
    render: (title) => <strong  className={/^[A-Za-z\s]+$/u.test(title) ? "" : "arabic"}>{title}</strong>
  },
  // {
  //   key: 'is_active',
  //   dataIndex: 'is_active',
  //   title: 'Status',
  //   render: (is_active) => (
  //     <Tag style={{ backgroundColor: is_active === 1 ? '#17bd6a' : '#ed5564' }}>
  //       {is_active === 1 ? 'Active' : 'In-Active'}
  //     </Tag>
  //   )
  // },
  {
    key: 'description',
    dataIndex: 'description',
    title: 'Description',
    // width: 200,
    render: (address: string) => {
      // {console.log(address)}
      return(

        <span  className={/^[A-Za-z\s]+$/u.test(address) ? "" : "arabic"} style={{ color: '#a5a5a5' }}>
        {address.length > 100 ? address.slice(0,100) + "..." : address}
      </span>
    )
      }
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const HealthProgramsTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 150,
    fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;
  // console.log(columns[2].render);

  const KeyForTable = () => {
    return Date.now() * Math.random();
  };

  return (
    <Table
      rowKey={KeyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default HealthProgramsTable;
