import { notification } from 'antd';
import { showErrorMessage } from '../utils/AppUtils';
import api, { mediaExport } from './Api';

export const fetchSelectBoxData = async (url: string, dataObj: string) => {
  try {
    const res = await api.get(url);
    const res2 = url.slice(1, url.indexOf('?'));
    console.log(url.slice(1, url.indexOf('?')), 'common');
    console.log(res, 'common');
    console.log(res?.data?.data?.[res2]?.data, 'common');
    console.log(res?.data?.data?.data === undefined ? true : false);

   return res?.data?.data?.data === undefined
      ? res?.data?.data?.[res2]?.data?.map((item) => getLabelAndValue(item, dataObj))
      : res?.data?.data?.data?.map((item) => getLabelAndValue(item, dataObj));

    // return res?.data?.data?.data?.map((item) => getLabelAndValue(item, dataObj));
    // return res?.data?.data?.data?.map((item) => getLabelAndValue(item, dataObj));
  } catch (err: any) {
    return err?.data;
  }
};

const getLabelAndValue = (item, dataObj) => {
  if (dataObj && item[dataObj]) {
    return {
      label: `${
        item[dataObj]?.name
          ? item[dataObj]?.name
          : item[dataObj]?.title
          ? item[dataObj]?.title
          : item[dataObj]?.fullName
          ? item[dataObj]?.fullName
          : item[dataObj]?.email
      }`,
      value: dataObj && item[dataObj] ? item[dataObj]?.id : item.id
    };
  } else {
    return {
      label: `${
        item?.fullName
          ? item?.fullName
          : item?.name
          ? item?.name
          : item?.title
          ? item?.title
          : item?.email
      }`,
      value: item.id
    };
  }
};
export const exportData = async (moduleUrl: string, isLoading: (loading: boolean) => void) => {
  try {
    isLoading(true);
    const res = await api.get(`${mediaExport}/${moduleUrl}`);
    
    if (res?.data?.data?.url) {
      isLoading(false);
      window.open(res?.data?.data?.url, '_blank');
      console.log(res?.data?.data?.url, "url");
      
    } else {
      isLoading(false);
      notification.error({
        message: 'Error while exporting the data'!
      });
    }
    return res;
  } catch (err: any) {
    isLoading(false);
    showErrorMessage(err?.data);
    return err?.data;
  }
};
