import { Card } from 'antd';
import React, { useEffect, useState } from 'react';

import * as charts from '../../../../../services/charts'
import ReactEcharts from 'echarts-for-react';
import { pieStyle } from '../chart-pattern-styles';
import Spinner from '../../../../../components/Spinner';
import { showErrorMessage } from '../../../../../utils/AppUtils';


const patientsAgeOptions = {

};
const RevenuePieChart = () => {

    const getPieChart = (data) => {
        let chartOptions: any = {
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br>{c} ({d}%)'
            },
            series: [
                {
                    name: 'pie',
                    type: 'pie',
                    selectedMode: 'single',
                    selectedOffset: 30,
                    clockwise: true,
                    radius: [60, '90%'],
                    label: {
                        // normal: {
                            position: 'inner',
                            // textStyle: {
                                fontSize: 14,
                                fontWeight: 700,
                                color: '#000'
                            // }
                        // }
                    },
                    labelLine: {
                        // normal: {
                            lineStyle: {
                                color: '#336cfb'
                            // }
                        }
                    },
                    data: [],
                    itemStyle: pieStyle
                }
            ]
        }
        chartOptions.series[0].data = data
        return chartOptions
    };
    const [data, setData] = useState({})
    const [isloading, setIsloading] = useState(false)
    useEffect(() => {
        setIsloading(true)
        charts.getRevnue()?.then((data) => {
            setIsloading(false)
            if (data?.data?.data?.pie_chart_revenue?.length) {
                setData(getPieChart(data?.data?.data?.pie_chart_revenue))
            } else {
                showErrorMessage(data)
                setIsloading(false)
            }
        }).catch((error) => {
            showErrorMessage(error)
            setIsloading(false)
        })
    }, [])
    return (
        !isloading ?
            <Card title={'Revenue'} >
                <ReactEcharts
                    // className='chart-container container-h-300'
                    option={data}
                />
            </Card> :
            <Spinner />

    )


};

export default RevenuePieChart;
