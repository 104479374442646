import React, { ReactNode } from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';
import AvatarImageDisplay from '../../../components/common/AvatarImageDisplay';
import CustomTag from '../../../components/common/CustomTag';

const columns: ColumnProps<any>[] = [

  {
    key: 'name',
    dataIndex: 'name',
    title: 'Branch Name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'pharmacy',
    dataIndex: 'pharmacy',
    title: 'Parent Pharmacy',
    // width: 300,
    render: (pharmacy) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {pharmacy?.name}
      </span>
    )
  },
  {
    key: 'address',
    dataIndex: 'address',
    title: 'Address',
    width: 300,
    render: (address) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {address?.slice(0, 45) + '...'}
      </span>
    )
  },
  // {
  //   key: 'is_active',
  //   dataIndex: 'is_active',
  //   title: 'Status',
  //   render: (is_active) => (
  //     <Tag style={{ backgroundColor: is_active === 1 ? '#17bd6a' : '#ed5564' }}>
  //       {is_active === 1 ? 'Active' : 'In-Active'}
  //     </Tag>
  //   )
  // }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const PharmaciesBranchesTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 200,
    fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;
const keyForTable =()=>{
  return Date.now()* Math.random()
}
  return (
    <Table
      rowKey={keyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      // scroll={{ x: 1300 }}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default PharmaciesBranchesTable;
