import { Upload, Modal, Button, notification, Avatar } from 'antd';
import { useState } from 'react';
import { mediaUpload } from '../../services/Api';
import { getToken } from '../../utils/StorageService';

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

const FileUpload = ({ handleUpload, uploadType, placeholder, mediaLink }: any) => {
  // const [state, setState] = useState<any>(initialState);
  const [fileAttached, setFileUploadResponse] = useState('');
  /* Before upload check validation */
  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: 'Image/file must smaller than 2MB!' });
    } else {
      if (uploadType === 'file') {
        const allowFiles = ['doc', 'docx', 'pdf'];
        if (!allowFiles?.includes[file.type]) {
          notification.error({ message: 'You can only upload pdf/doc/docx file!' });
        } else {
          return true;
        }
      } else {
        const allowImageTypes = ['image/jpeg', 'image/png'];
        if (!allowImageTypes.includes[file.type]) {
          notification.error({ message: 'You can only upload JPG/PNG file!' });
        } else {
          return true;
        }
      }
    }
    return false;
  }
  const attachFile: any = {
    name: 'file',
    action: mediaUpload,
    headers: {
      Authorization: `bearer ` + JSON.parse(getToken())
    },
    method: 'POST',
    data: {
      content_type: uploadType === 'file' ? 'file' : 'image'
    },
    onChange(info: any) {
      const docLink = info?.file?.response?.url;
      if (info?.file?.response?.success === false) {
        notification.error({ message: info?.file?.response?.error[0]?.message });
      } else if (info?.file?.status === 'removed') {
        setFileUploadResponse('');
        handleUpload('');
      } else if (info?.file?.status === 'done') {
        setFileUploadResponse(docLink);
        handleUpload(docLink);
      }
    }
  };
  const renderUpload = (
    <Upload
      // beforeUpload={beforeUpload}
      {...attachFile}
      maxCount={1}
      withCredentials={true}
    >
      <Button type='dashed' style={{ width: '100%' }}>
        {placeholder ? placeholder : 'Upload'}
      </Button>
    </Upload>
  );
  return (
    <>
      {uploadType === 'logo' ? (
        <div className='d-flex align-items-center'>
          <Avatar src={fileAttached ? fileAttached : mediaLink} size={54} className='mr-4' />
          {renderUpload}
        </div>
      ) : (
        renderUpload
      )}
      {mediaLink && uploadType !== 'logo' && (
        <div style={{ margin: '5px 12px 0px 12px' }} className='align-items-center'>
          <a href={mediaLink} target='_blank' rel='noreferrer'>
            Download {mediaLink.slice(-10)}
          </a>
        </div>
      )}
    </>
  );
};

export default FileUpload;
