import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/reviews/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params) => {
  try {
    const url = '/reviews' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/reviews', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/reviews/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/reviews/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
