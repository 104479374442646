import React, { Component } from 'react';
import { Button, Input, Modal, Form, Divider, notification } from 'antd';
import { MapStaticApiKey } from '../../../utils/constants';
// import OmanMap from '../../../assets/img/oman_map.png';
const { Item } = Form;
class GoogleMapPlaces extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 14,
    draggable: true,
    address: this?.props?.address,
    map_link: this?.props?.map_link,
    lat: this?.props?.lat,
    longitude: this?.props?.longitude,
    embedMatchStr: ''
  }
  componentDidMount() {
    if (this.props.map_link) {
      this.setEmbedableLink(this?.props?.map_link)
    }
  }
  /* Set map ,let ,lng */
  setSelectedLocation = () => {
    if (this?.state?.lat?.length && this?.state?.longitude?.length && this?.state?.map_link) {
      let latlngAdress = {
        lat: this.state.lat,
        longitude: this.state.longitude,
        address: this.state.address,
        map_link: this.state.map_link
      }
      this?.props?.setLatLng(latlngAdress)
      this.props.onClose(false)
    } else {
      notification.error({
        message: "Map link is not valid !"
      })
    }
  }
  setEmbedableLink = (url) => {
    let link = url ? url : this.state.map_link;
    let placeSplit = link?.split('/place/')
    placeSplit = placeSplit?.[1]?.split('/')
    let latLngSplit = link?.split('/@')
    latLngSplit = latLngSplit?.[1]?.split(',')
    let lat = latLngSplit?.[0];
    let longitude = latLngSplit?.[1];
    if (placeSplit?.[0] && lat && longitude) {
      let embedKey = `https://www.google.com/maps/embed/v1/place?key=${MapStaticApiKey}&q=${placeSplit?.[0]}`
      this.setState({ embedMatchStr: embedKey, lat, longitude })
    } else {
      this.setState({ lat: null, longitude: null })
      notification.error({
        message: "Map link is not valid"
      })
    }
  }
  handleInputChange = (value) => {
    if (value === '') {
      this.setState({ lat: null, longitude: null })
    }
    this.setState({ map_link: value })
  }
  render() {
    const { embedMatchStr } = this.state
    return (
      <Modal
        width={500}
        open={this.props.visible}
        onCancel={() => this.props.onClose(false)}
        onOk={this.setSelectedLocation}
        destroyOnClose
        footer={null}
        title={this?.props?.title || 'Select Location'}
      >
        {/* <Form layout="vertical"> */}
        <div style={{ height: 400 }}>
          <Input
            defaultValue={this?.props?.map_link}
            value={this.state.map_link}
            onClear={() => this.setState({ lat: null, longitude: null })}
            placeholder='Paste the map place link here'
            onPressEnter={() => this.setEmbedableLink()}
            onChange={(e) => this.handleInputChange(e.target.value)}
            allowClear={true}
            suffix={
              <span
                className='icofont icofont-search'
                style={{ cursor: 'pointer' }}
                onClick={() => this.setEmbedableLink()}
              />
            }
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          {embedMatchStr ? <div style={{ height: 200, width: '100%' }}>
            <iframe width="100%"
              height="254" style={{ border: 0, height: 200, marginTop: 15, borderRadius: 5 }}
              src={embedMatchStr}>
            </iframe>
          </div> : null}
          {/* </div> : <img src={OmanMap} style={{ borderRadius: 5 }} />} */}
          <Divider />
          <div style={{ marginTop: 15 }} className="">
            <div className='map-details'>
              Latitude : <span>{this.state.lat}</span>
            </div>
            <div className='map-details'>
              Longitude : <span>{this.state.longitude}</span>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <Button onClick={() => this.props.onClose()} type='default'>
            Cancel
          </Button>
          <Button block={false} type='primary' onClick={this.setSelectedLocation}>
            Select
          </Button>
        </div>
        {/* </Form> */}
      </Modal >
    )
  }
}

export default GoogleMapPlaces
