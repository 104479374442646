import { Select, Spin, Tag } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
// import api from '../../services/Api';
import { fetchSelectBoxData } from '../services/common';
import { showErrorMessage } from '../utils/AppUtils';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (url, dataObj) => Promise<ValueType[]>;
  fetchUrl?: string;
  dataObj?: string;
  debounceTimeout?: number;
  refType?: String;
}

let selectBoxRef = null;
function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  fetchUrl,
  dataObj,
  refType,
  ...props
}: DebounceSelectProps) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const initialText = 'Type to Search';
  const [indicatorText, setIndicatorText] = useState(initialText);
  const fetchRef = useRef(0);
  let formatedUrl = '';
  if (refType) {
    formatedUrl = `/users?type=${refType}&`;
  } else {
    formatedUrl = fetchUrl;
  }
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      try {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        let url = formatedUrl + 'search=' + value;
        console.log(url)
        if (formatedUrl) {
          
        fetchOptions(url, dataObj).then((newOptions) => {
            console.log("newOptions",newOptions)
            console.log("url",url)
            console.log("dataObj",dataObj)
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            if (newOptions?.length) {
              setOptions(newOptions);
              setIndicatorText(initialText)
            } else {
              setIndicatorText('Did not match any data!')
            }
            setFetching(false);
          });
        } else {
          return;
        }
      } catch (error) {
        showErrorMessage(error);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, backgroundColor: 'transparent', color: 'black' }}
      >
        {label}
      </Tag>
    );
  }
  return (
    <Select
      labelInValue
      filterOption={false}
      mode='multiple'
      maxTagTextLength={20}
      // showArrow
      tagRender={tagRender}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : <p>{indicatorText}</p>}
      {...props}
      ref={(select) => (selectBoxRef = select)}
      options={options}
    />
  );
}
interface UserValue {
  label: string;
  value: string;
}
const AsyncSearchSelect = (props) => {
  const [value, setValue] = useState<any>();

  const handleSelectedValue = (value) => {
  console.log(value,"AsyncSearchSelect");
  
    let selectdValue = value;
    if (props?.mode !== 'multiple') {
      selectdValue = value[value.length - 1];
    }
    setValue(selectdValue);
    props?.selectedValue(selectdValue);
  };
  // console.log("props.flag",props.flag)
  return (
    <DebounceSelect
      // disabled={props?.disabled ? props?.disabled : false}
      value={value}
      defaultValue={props?.defaultValue}
      fetchUrl={props?.url}
      refType={props?.refType}
      dataObj={props?.dataObj ? props?.dataObj : ''}
      placeholder={props?.placeholder ? props?.placeholder : 'Search to Select'}
      fetchOptions={fetchSelectBoxData}
      onChange={(selectedValue) => {
        handleSelectedValue(selectedValue);
        selectBoxRef.blur();
      }}
      style={{ width: '100%' }}
    />
  );
};
export default AsyncSearchSelect;
