import { Tag } from 'antd';

const getTypeColor = (type) => {
  if (type === 'admin') {
    return 'blue';
  } else if (type === 'doctor') {
    return '#17bd6a';
  } else if (type === 'patient') {
    return '#ed5564';
  } else {
    return '#000000';
  }
};
const CustomTag = ({ type = 'pharmacy' }) => {
  return (
    <Tag
      style={{
        backgroundColor: '#fafafa',
        color: getTypeColor(type),
        border: `solid 1px ${getTypeColor(type)}`
      }}
    >
      {type.toUpperCase()}
    </Tag>
  );
};
export default CustomTag;
