import { IRoute } from '../interfaces/routing';

// import SettingsPage from '../pages/settings/Settings';

import PatientsPage from '../pages/patients/PatientsPage';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
import AppointmentsPage from '../pages/appointments/AppointmentsPage';
import AllAppointmentsPage from '../pages/reports/all-appointments/AllAppointmentsPage';
import UpcomingAppointmentsPage from '../pages/reports/upcoming-appointments/UpcomingAppointmentsPage';
import DoctorsPage from '../pages/doctors/DoctorsPage';
import HospitalsPage from '../pages/hospitals/HospitalsPage';
import LaboratoriesPage from '../pages/labs-managment/laboratories/LaboratoriesPage';
import PharmaciesPage from '../pages/pharmacies/pharmacies/PharmaciesPage';
import SpecialitiesPage from '../pages/manage-illness/specialities/SpecialitiesPage';
import ServicesPage from '../pages/manage-illness/services/ServicesPage';
import LabTestPage from '../pages/labs-managment/lab-tests/LabTestsPage';
import StatePage from '../pages/geography/states/StatesPage';
import CountriesPage from '../pages/geography/countries/CountriesPage';
import CitiesPage from '../pages/geography/cities/CitiesPage';
import HealthProgramsPage from '../pages/health-programs/laboratories/HealthProgramsPage';
import InstitutesPage from '../pages/qualifications/institutes/InstitutePage';
import QualificationsPage from '../pages/qualifications/qualifications/QualificationsPage';
import SpecializationsPage from '../pages/qualifications/specializations/SpecializationsPage';
import DoctorAddUpdateForm from '../pages/doctors/DoctorAddUpdateForm';
import requireAuthentication from './AuthencatedComponent';
import HospitalAddUpdateForm from '../pages/hospitals/HospitalAddUpdateForm';
import PharmacyAddUpdateForm from '../pages/pharmacies/pharmacies/PharmacyAddUpdateForm';
import PharmacyBranchAddUpdateForm from '../pages/pharmacies/branches/PharmacyBranchAddUpdateForm';
import LaboratryAddUpdateForm from '../pages/labs-managment/laboratories/LaboratryAddUpdateForm';
import UsersPage from '../pages/users/UsersPage';
import ReviewPage from '../pages/reviews/ReviewPage';
import HealthProgramAddUpdateForm from '../pages/health-programs/laboratories/HealthProgramAddUpdateForm';
import AppointmentViewPage from '../pages/appointments/AppointmentViewPage';
import AllSettingsPage from '../pages/settings/SettingsPage';
import PatientProfilePage from '../pages/patients/PatientProfilePage';
import InsurancesPage from '../pages/insurance/insurances/InsurancesPage';
import InsuranceAddUpdateForm from '../pages/insurance/insurances/InsuranceAddUpdateForm';
import TransactionsPage from '../pages/reports/transactions/TransactionsPage';
import PharmaciesBranchesPage from '../pages/pharmacies/branches/PharmaciesBranchesPage';
import LabsBranchesPage from '../pages/labs-managment/labs-branches/LabsBrancesPage';
import LabBranchAddUpdateForm from '../pages/labs-managment/labs-branches/LabBranchAddUpdateForm';
import RevenuePage from '../pages/reports/revenue/RevenuePage';
import ChatBox from '../pages/chat/ChatBox';
import UpdateViewUser from '../pages/users/UpdateViewUsers';
import UserUpdateProfile from '../pages/users/UserUpdateProfile';

export const defaultRoutes: IRoute[] = [
  {
    path: 'users',
    exact: true,
    component: requireAuthentication(UsersPage, 'users')
  },
  {
    path: 'users/:id',
    exact: true,
    component: requireAuthentication(UpdateViewUser, 'allowed')
  },
  {
    path: 'user-profile/:id',
    exact: true,
    component: requireAuthentication(UserUpdateProfile, 'allowed')
  },
  {
    path: 'settings',
    component: requireAuthentication(AllSettingsPage, 'settings')
  },
  {
    path: 'dashboard',
    component: requireAuthentication(DashboardPage, 'dashboard')
  },
  {
    path: 'patients',
    exact: true,
    component: requireAuthentication(PatientsPage, 'patients')
  },
  {
    path: 'patients/:id',
    component: requireAuthentication(PatientProfilePage, 'patients')
  },
  {
    path: 'appointments',
    exact: true,
    component: requireAuthentication(AppointmentsPage, 'appointments')
  },
  {
    path: 'appointments/:id',
    component: requireAuthentication(AppointmentViewPage, 'appointments')
  },
  {
    path: 'reports-appointments',
    component: requireAuthentication(AllAppointmentsPage, 'appointments')
  },
  {
    path: 'upcoming-appointments',
    component: requireAuthentication(UpcomingAppointmentsPage, 'upcoming-appointments')
  },
  {
    path: 'doctors',
    exact: true,
    component: requireAuthentication(DoctorsPage, 'doctors')
  },
  {
    path: 'doctors/:id',
    component: requireAuthentication(DoctorAddUpdateForm, 'doctors-update')
  },
  {
    path: 'hospitals',
    exact: true,
    component: requireAuthentication(HospitalsPage, 'hospitals')
  },
  {
    path: 'hospitals/:id',
    component: requireAuthentication(HospitalAddUpdateForm, 'hospitals-update')
  },
  {
    path: 'laboratories',
    exact: true,
    component: requireAuthentication(LaboratoriesPage, 'laboratories')
  },
  {
    path: 'laboratories/:id',
    component: requireAuthentication(LaboratryAddUpdateForm, 'laboratories-update')
  },
  {
    path: 'laboratory-branches',
    exact: true,
    component: requireAuthentication(LabsBranchesPage, 'laboratories')
  },
  {
    path: 'laboratory-branches/:id',
    exact: true,
    component: requireAuthentication(LabBranchAddUpdateForm, 'allowed')
  },
  {
    path: 'pharmacies',
    exact: true,
    component: requireAuthentication(PharmaciesPage, 'pharmacies')
  },
  {
    path: 'pharmacies/:id',
    exact: true,
    component: requireAuthentication(PharmacyAddUpdateForm, 'pharmacies-update')
  },
  {
    path: 'pharmacy-branches',
    exact: true,
    component: requireAuthentication(PharmaciesBranchesPage, 'pharmacies')
  },
  {
    path: 'pharmacy-branches/:id',
    exact: true,
    component: requireAuthentication(PharmacyBranchAddUpdateForm, 'pharmacies-update')
  },
  {
    path: 'specialities',
    component: requireAuthentication(SpecialitiesPage, 'specialities')
  },
  {
    path: 'services',
    component: requireAuthentication(ServicesPage, 'services')
  },
  {
    path: 'lab-tests',
    component: requireAuthentication(LabTestPage, 'lab-tests')
  },
  {
    path: 'states',
    component: requireAuthentication(StatePage, 'states')
  },
  {
    path: 'countries',
    component: requireAuthentication(CountriesPage, 'countries')
  },
  {
    path: 'cities',
    component: requireAuthentication(CitiesPage, 'cities')
  },
  {
    path: 'health-programs',
    exact: true,
    component: requireAuthentication(HealthProgramsPage, 'health-programs')
  },
  {
    path: 'health-programs/:id',
    component: requireAuthentication(HealthProgramAddUpdateForm, 'health-programs')
  },
  {
    path: 'insurances',
    exact: true,
    component: requireAuthentication(InsurancesPage, 'insurances')
  },
  {
    path: 'insurances/:id',
    component: requireAuthentication(InsuranceAddUpdateForm, 'insurances')
  },
  {
    path: 'institutes',
    component: requireAuthentication(InstitutesPage, 'institutes')
  },
  {
    path: 'qualifications',
    component: requireAuthentication(QualificationsPage, 'qualifications')
  },
  {
    path: 'specializations',
    component: requireAuthentication(SpecializationsPage, 'specializations')
  },
  {
    path: 'reviews',
    component: requireAuthentication(ReviewPage, 'reviews')
  },
  {
    path: 'transactions',
    component: requireAuthentication(TransactionsPage, 'transactions')
  },
  {
    path: 'revenue-reports',
    component: requireAuthentication(RevenuePage, 'revenue-reports')
  },
  {
    path: 'chat',
    component: requireAuthentication(ChatBox, 'chat')
  }
];
