import React, { useState } from 'react';
import { Row, Col, Input } from 'antd';

type Props = {
  data: any;
  fullWidth?: boolean;
};

const SearchData = ({ data, fullWidth }: Props) => {
  const [text, setText] = useState('');
  /* fetch data on search */
  const handleTextChange = (e) => {
    setText(e.target.value);
    if (e.target.value === '') {
      data.getAllData({
        ...data.pageRef,
        search: ''
      });
    }
  };
  /* fetch data on search */
  const onSearch = () => {
    if (text) {
      data.getAllData({
        ...data.pageRef,
        search: text
      });
      // console.log(
      //   data.getAllData({
      //     ...data.pageRef,
      //     search: text
      //   })
      // );
    }
  };
  return (
    <div className='row'>
      <div className={fullWidth === true ? 'col-12' : 'col-md-4 col-sm-4 col-xs-12'}>
        <Input
          // allowClear
          value={text}
          placeholder='Search'
          onPressEnter={onSearch}
          onChange={(e) => handleTextChange(e)}
          allowClear={true}
          suffix={
            <span
              className='icofont icofont-search'
              style={{ cursor: 'pointer' }}
              onClick={onSearch}
            />
          }
          style={{ marginTop: 16, marginBottom: 16 }}
        />
      </div>
    </div>
  );
};

export default SearchData;
