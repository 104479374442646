import { Tabs } from 'antd';
import { usePageData } from '../../hooks/usePage';
import type { TabsProps } from 'antd';
import { IPageData } from '../../interfaces/page';
import PermissionsGroups from './components/PermissionsList';
import EmailSettings from './components/EmailSetttings';
import PaymentSettings from './components/paymentsettings/PaymentSettings';
import LocalizationSettings from './components/LocalizationSettings';
import ServiceFeeSettings from './components/ServiceFeeSettings';
import ChatAndVideoSettings from './components/ChatAndVideoSettings';

const pageData: IPageData = {
  title: 'Settings',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Settings'
    }
  ]
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Permissions',
    children: <PermissionsGroups/>,
  },
  {
    key: '2',
    label: 'Email Settings',
    children: <EmailSettings/>,
  },
  {
    key: '3',
    label: 'Video/Chat Settings',
    children: <ChatAndVideoSettings/>,
  },
  {
    key: '4',
    label: 'Payment Settings',
    children: <PaymentSettings/>,
  },
  {
    key: '5',
    label: 'Localization',
    children: <LocalizationSettings/>,
  },
  {
    key: '6',
    label: 'Service Fee',
    children: <ServiceFeeSettings/>,
  },
];


const AllSettingsPage = () => {
  usePageData(pageData);
  // const { TabPane } = Tabs;
  const callback = (key) => {
    console.log(key);
  };
  return (
    <Tabs defaultActiveKey='1' items={items} onChange={callback}/>
    // changes in tabs 
    // {/* <TabPane tab='Permissions' key='1'>
    //     <PermissionsGroups />
    //   </TabPane>
    //   <TabPane tab='Email Settings' key='2'>
    //     <EmailSettings />
    //   </TabPane>
    //   <TabPane tab='Video/Chat Settings' key='3'>
    //     <ChatAndVideoSettings />
    //   </TabPane>
    //   <TabPane tab='Payment Settings' key='4'>
    //     <PaymentSettings />
    //   </TabPane>
    //   <TabPane tab='Localization' key='5'>
    //     <LocalizationSettings />
    //   </TabPane>
    //   <TabPane tab='Service Fee' key='6'>
    //     <ServiceFeeSettings />
    //   </TabPane> */}
    //   {/* <TabPane tab='App Management' key='6'>
    //     App home screen widgets management
    //   </TabPane> */}
    // // </Tabs>
// {/* <div>helo</div> */}
    );
};

export default AllSettingsPage;
