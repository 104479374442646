import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/health-programs/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params = {}) => {
  try {
    const url = '/health-programs' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/health-programs', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/health-programs/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/health-programs/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
