import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Divider, Modal } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as insurances from '../../../services/insurances';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import FileUpload from '../../../components/UploadImages/FileUpload';

// import ImageLoader from '../../../layout/components/ImageLoader';

const { Item } = Form;
const { TextArea } = Input;

const pageData: IPageData = {
  title: 'Insurances',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Insurances'
    }
  ]
};
type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};
const InsuranceAddUpdateForm = ({ visible, onClose, values, isViewModal, getAllData }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  // const [values, setValues] = useState<any>({});
  // const history: any = useHistory();
  // const { id } = useParams<any>();
  const isView = localStorage.getItem('isViewInsurance');
  usePageData(pageData);

  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues({ ...clonedValues });
    }
  }, [values]);
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      formValues.logo = customFormValues?.logo;

      setLoading(true);
      if (values?.id) {
        const data = await insurances.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await insurances.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  // return (
  const AddUpdateForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        is_active: 1
      }}
    >
      <div className='form-group'>
        <Item name='logo'>
          <FileUpload
            uploadType='logo'
            mediaLink={form.getFieldValue('logo')}
            placeholder='Upload Image'
            handleUpload={(img) => handleCustomFormChange(img, 'logo')}
          />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='code' rules={[{ required: true, message: 'code is required' }]}>
          <Input placeholder='Code' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='name' rules={[{ required: true, message: 'Name is required' }]}>
          <Input placeholder='Name' type='text' />
        </Item>
      </div>
      <Item name='email' rules={[{ required: true, message: 'Email is required' }]}>
        <Input placeholder='Email' type='email' />
      </Item>
      <div className='form-group'>
        <Item name='phone' rules={[{ required: true, message: 'Phone Number is required' }]}>
          <Input placeholder='Phone Number' />
        </Item>
      </div>

      <div className='form-group'>
        <Item
          name='address'
          rules={[
            {
              required: true,
              message: 'address is required'
            }
          ]}
        >
          <TextArea
            rows={3}
            value={customFormValues?.address}
            onChange={(e) => handleCustomFormChange(e.target.value, 'address')}
            placeholder='Address'
          />
        </Item>
      </div>
      <Divider />

      <div className='form-group'>
        <Item name='status'>
          <Select placeholder='Status'>
            <Select.Option value={'active'}>Active</Select.Option>
            <Select.Option value={'inactive'}>InActive</Select.Option>
          </Select>
        </Item>
      </div>
      {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? 'Update Insurance' : 'Add Insurance'}</h3>}
    >
      {AddUpdateForm}
    </Modal>
  );
};
export default InsuranceAddUpdateForm;
