import React, { useEffect, useState } from 'react';
import { Table, Form, Checkbox, Popconfirm, Typography, Button } from 'antd';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import * as permissions from '../../../services/permissions';
import Spinner from '../../../components/Spinner';

interface Item {
  key: string;
  module: string;
  list: Boolean;
  view: Boolean;
  add: Boolean;
  update: Boolean;
  delete: Boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: Boolean;
  dataIndex: string;
  title: any;
  inputType: Boolean;
  record: any;
  handleCustomFormChange: any;
  index: number;
  children: any;
  // children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  handleCustomFormChange,
  children,
  ...restProps
}) => {
  const inputNode = (
    <Checkbox
      checked={
        record?.permissions?.length && record?.permissions[0]?.[dataIndex] === 1 ? true : false
      }
      disabled={editing ? false : true}
      onChange={(e) => handleCustomFormChange(e.target.checked, dataIndex, record?.slug)}
    />
  );
  return (
    <td {...restProps}>
      {dataIndex === 'title' ? (
        <p>{record?.title}</p>
      ) : dataIndex === 'operation' ? (
        children
      ) : (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      )}
    </td>
  );
};

const PermissionsAddUpdateForm = ({ user }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const initialPermissionValues = {
    list: '0',
    add: '0',
    remove: '0',
    update: '0'
  };
  const [permissionsList, setPermissionsList] = useState<any>(initialPermissionValues);

  const isEditing = (record: any) => {
    if (record?.id === editingKey && record?.id && editingKey) {
      return true;
    } else {
      return false;
    }
  };

  const edit = (record: any & { key: React.Key }) => {
    setEditingKey(record?.id);
    if (record?.permissions?.length) {
      let { list, add, remove, update } = record?.permissions?.[0];
      list = list === 1 ? '1' : '0';
      add = add === 1 ? '1' : '0';
      remove = remove === 1 ? '1' : '0';
      update = update === 1 ? '1' : '0';
      setPermissionsList({ list, add, remove, update, module: record?.slug });
    }
  };

  const cancel = () => {
    setEditingKey('');
  };
  /* Fetch fields data */
  useEffect(() => {
    /* Fetch fields data */
    permissions.getAll(user?.key).then((res: any) => {
      let allPermissions = res?.data?.data;
      if (allPermissions?.length) {
        setData(allPermissions);
      }
    });
  }, []);

  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName, recordModule) => {
    setPermissionsList((prevState) => ({
      ...prevState,
      module: recordModule,
      [formName]: value === true ? '1' : '0'
    }));
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
  };
  const handleSubmit = async (record: any) => {
    try {
      setLoading(true);
      const res = await permissions.update(permissionsList, user?.key);
      setLoading(false);
      if (res?.status === 200) {
        operationsAfterSubmit(res);
        setEditingKey('');
      } else {
        showErrorMessage(res);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  const columns = [
    {
      title: 'Modules',
      dataIndex: 'title',
      width: '20%'
    },
    {
      title: 'Listing',
      dataIndex: 'list',
      width: '15%',
      editable: true
    },
    {
      title: 'Add',
      dataIndex: 'add',
      width: '15%',
      editable: true
    },
    {
      title: 'Update',
      dataIndex: 'update',
      width: '15%',
      editable: true
    },
    {
      title: 'Delete',
      dataIndex: 'remove',
      width: '15%',
      editable: true
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '20%',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              type='link'
              // disabled={loading}
              onClick={() => handleSubmit(record)}
            >
              Save
            </Button>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <Button type='link'>Cancel</Button>
            </Popconfirm>
          </div>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      }
    }
  ];

  const mergedColumns = columns.map((col,index) => {
    return {
      ...col,
      key :index,
      onCell: (record: Item) => ({
        record,
        dataIndex: col.dataIndex,
        handleCustomFormChange: handleCustomFormChange,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  if (!Object.values(data)?.length) {
    return <Spinner />;
  }

  const keyForTable =()=>{
    return Date.now()* Math.random()
  }

  return (
    <Form form={form} scrollToFirstError className='mb-4' onFinish={handleSubmit} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        rowKey={keyForTable}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName='editable-row'
        pagination={false}
        scroll={{ y: 400 }}
      />
    </Form>
  );
};

export default PermissionsAddUpdateForm;
