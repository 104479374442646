import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as users from '../../services/users';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const UserAddForm = ({ visible, onClose, values, getAllData }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const allUserTypes = ['doctor', 'patient', 'laboratory', 'hospital', 'admin', 'pharmacy'];
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    getAllData();
    setTimeout(() => {
      form.resetFields();
      onClose();
    }, 2000);
  };
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const data = await users.add(formValues);
      setLoading(false);
      if (data?.status === 200) {
        operationsAfterSubmit(data);
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  const userForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        is_active: 1
      }}
    >
      <Item name='email' label="Email" rules={[{ required: true, message: 'Email is required' }]}>
        <Input placeholder='Email' type='email' />
      </Item>
      <Item name='name' label="Name" rules={[{ required: true, message: 'name is required' }]}>
        <Input placeholder='Name' type='name' />
      </Item>
      <div className='form-group'>
        <Item name='user_type' label="User Type" rules={[{ required: true, message: 'user type is required' }]}>
          <Select placeholder='User type'>
            {allUserTypes?.map((type: any, i) => (
              <Select.Option key={`type-${type}-${i}`} value={type}>
                {type}{' '}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </div>

      <div className='d-flex justify-content-between'>
        <Button onClick={onClose} type='default'>
          Close
        </Button>
        <Button
          block={false}
          type='primary'
          loading={loading}
          icon={<span className='icofont icofont-plus mr-2' style={{ fontSize: '1.2rem' }} />}
          htmlType='submit'
        >
          Register User
        </Button>
      </div>
    </Form>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>Register User</h3>}
    >
      {userForm}
    </Modal>
  );
};

export default UserAddForm;
