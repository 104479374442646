import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal, ConfigProvider } from 'antd';
// import ImageLoader from './ImageLoader';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as cities from '../../../services/cities';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import api from '../../../services/Api';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const CityAddUpdateForm = ({ visible, onClose, values, isViewModal, getAllData }: Props) => {
  const [form] = useForm();
  const [allStates, setAllStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [customFormValues, setCustomFormValues] = useState<any>({});

  /* Handle state change */
  const getStates = () => {
    api.get(`/states?per_page=100`).then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setAllStates(res?.data?.data?.data);
      }
    });
  }
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  /* Fetch fields data */
  useEffect(() => {
    getStates()
    // api.get('/languages').then((res: any) => {
    //   if (res?.data?.data?.length) {
    //     setLanguages(res?.data?.data);
    //   }
    // });
  }, []);

  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (values?.id) {
        const data = await cities.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await cities.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };

  const AddUpdateForm = (
<>
    <Form
disabled={isViewModal}
form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        language_id: 1
      }}
    >
      {/* <div className='form-group'>
        <Item
          name='language_id'
          rules={[{ required: true, message: 'language is required' }]}
        >
        <Select placeholder='Select Language' onChange={(value) => handleCustomFormChange(value, 'language_id')}>
            {languages?.length > 0 &&
              languages?.map((item: any, i) => (
                <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                  {item?.title}{' '}
                  </Select.Option>
              ))}
          </Select>
        </Item>
      </div> */}
      <div className='form-group'>
        <Item name='name' rules={[{ required: true, message: 'Wilayat Name is required' }]}>
          <Input placeholder='Wilayat Name' type='text' />
        </Item>
      </div>
      <ConfigProvider direction={`rtl`}>
        <div className='form-group'>
          <Item className='arabic' name='name_ar' rules={[{ required: true, message: `مطلوب اسم الولاية` }]}>
            <Input className='arabic' placeholder={`اسم الولاية`} type='text' />
          </Item>
        </div>
      </ConfigProvider>
      <div className='form-group'>
        <Item name='state_id' rules={[{ required: true, message: 'Please select the City' }]}>
          <Select placeholder='Select Wilayat'
            showSearch={true}
            filterOption={(input, option) => {
              const filtered = option?.props?.children?.filter(item => item?.toLowerCase()?.includes(input?.toLowerCase()))
              return filtered.length >= 1 ? true : false
            }
            }
            optionFilterProp="children">
            {allStates?.length &&
              allStates?.map((item: any, i) => (
                <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Item>
      </div>

      {
        isViewModal ? (
       <></>
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' onClick={onClose} loading={loading}>
          //     Ok
          //   </Button>
          // </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' loading={loading} htmlType='submit'>
              Submit
            </Button>
          </div>
        )
      }
    </Form >
    {
        isViewModal ? (
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' onClick={onClose} loading={loading}>
              Ok
            </Button>
          </div>
        ) : (
         <></>
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' loading={loading} htmlType='submit'>
          //     Submit
          //   </Button>
          // </div>
        )
      }
  </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? (isViewModal ? 'View Wilayat' : 'Update Wilayat') : 'Add Wilayat'}</h3>}
    >
      {AddUpdateForm}
    </Modal>
  );
};

export default CityAddUpdateForm;
