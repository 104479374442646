import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal, DatePicker, TimePicker, notification } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as appointment from '../../services/appointments';
import * as doctors from '../../services/doctors';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import moment from 'moment';
import * as patients from '../../services/patients';
import AsyncSearchSelect from '../../components/AsyncSearchSelect';
import dayjs from 'dayjs';

const { Item } = Form;
const { Option, OptGroup } = Select;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const AppointmentAddUpdateForm = ({ visible, onClose, values, getAllData }: Props) => {
  const [form] = useForm();
  const [availableSlots, setAvailableSlots] = useState<any>([]);
  console.log(availableSlots, 'time');
  const formatedDate = 'YYYY-MM-DD';
  const [loading, setLoading] = useState(false);
  // const [current]
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  // const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedDate, setSelectedDate] = useState<any>();
  const formatDate = 'YYYY-MM-DD';
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = { ...values };
      if (clonedValues.date) {
        clonedValues.date = moment(clonedValues.date);
      }
      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues({
        ...clonedValues
      });
      // if (clonedValues?.patient?.id) {
      //   setSelectedPatient(clonedValues?.id);
      // }
      const params = {
        doctor_id: clonedValues?.doctor_id,
        type: clonedValues?.type,
        day_id: moment(clonedValues?.date).day(),
        date: moment(new Date(clonedValues?.date)).format('YYYY-MM-DD')
      };
      setSelectedDate(dayjs(new Date(clonedValues?.date)).format('YYYY-MM-DD'));
      getAndSetDoctorTimeSlots(params);
    }
  }, [values]);
  console.log(values?.date, 'values');
  console.log(values);

  /* Custom form change */
  const handleCustomFormChange = (value, formName) => {
    console.log(value);
    console.log(dayjs(new Date(value)).format('YYYY-MM-DD'));
    console.log(moment(new Date(value)).format('YYYY-MM-DD'));
    console.log(formName);
    if (formName === 'date') {
      form.resetFields(['time']);
      setSelectedDate(dayjs(new Date(value)).format('YYYY-MM-DD'));
      const params = {
        doctor_id: form.getFieldValue('doctor_id'),
        type: form.getFieldValue('type') === 'virtual' ? 'virtual' : 'physical',
        day_id: dayjs(value).day(),
        date: selectedDate
      };
      console.log('params', params);
      getAndSetDoctorTimeSlots(params);
    } else if (formName === 'doctor_id' || formName === 'type') {
      form.resetFields(['date', 'time']);
    }
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  /* Get and set doctor time slots */
  const getAndSetDoctorTimeSlots = (params) => {
    doctors.getDoctorTimeSlots(params).then((res: any) => {
      console.log('danish');
      console.log(res, 'res');

      if (res?.data?.data) {
        setAvailableSlots(res?.data?.data);
      }
    });
  };
  /* performing operations before hitting api */
  const formateFormBeforeSubmit = (formValues: any) => {
    console.log(formValues, 'danish');

    if (formValues.age) {
      formValues.age = parseInt(formValues.age);
    }
    formValues.date = moment(new Date(formValues.date)).format('YYYY-MM-DD');
    formValues.time = moment(formValues.time, 'hh:mm A').format('HH:mm');
    // formValues.userId = customFormValues.userId ? parseInt(customFormValues.userId) : parseInt(customFormValues.user_id);

    return formValues;
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    getAllData();
    setTimeout(() => {
      form.resetFields();
      onClose();
    }, 1000);
  };
  const handleSubmit = async (formValues: any) => {
    const formValuesFormate = formateFormBeforeSubmit(formValues);
    // const date="2023-09-12"

    const temp = { ...formValuesFormate, date: selectedDate };
    try {
      console.log(formValuesFormate);
      // console.log("temp",temp)
      setLoading(true);
      if (values?.id) {
        const data = await appointment.update(temp, values.id);

        setLoading(false);
        if (data?.status === 200) {
          operationsAfterSubmit(data);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await appointment.add(temp);
        setLoading(false);
        if (data?.status === 200) {
          operationsAfterSubmit(data);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      setLoading(false);
      showErrorMessage(e?.data);
    }
  };

  const appointmentForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <div className='form-group'>
        <Item name='doctor_id' rules={[{ required: true, message: 'Please select the doctor' }]}>
          <AsyncSearchSelect
            placeholder='Search Doctor'
            url='/users?type=doctor&'
            dataObj='doctor'
            defaultValue={
              values?.doctor && {
                label: values['doctor']?.fullName,
                value: values['doctor']?.id
              }
            }
            selectedValue={(item) => {
              form.setFieldsValue({ doctor_id: item?.value });
              handleCustomFormChange(item?.value, 'doctor_id');
            }}
          />
        </Item>
      </div>
      {/* <div className='form-group'>
        <Item
          name='booking_for'
          rules={[{ required: true, message: 'Consultation fee currency  is required' }]}
        >
          <Select
            placeholder='Booking For'
            onChange={(value) => handleCustomFormChange(value, 'booking_for')}
          >
            <Select.Option value='self'>Self</Select.Option>
            <Select.Option value='someone_else'>Other</Select.Option>
          </Select>
        </Item>
      </div> */}
      <div className='form-group'>
        <Item name='patient_id'>
          <AsyncSearchSelect
            placeholder='Search Patient'
            url='/patients?'
            dataObj='patient'
            defaultValue={
              values?.patient && {
                label: values['patient']?.name,
                value: values?.patient
              }
            }
            selectedValue={(item) => {
              form.setFieldsValue({ patient_id: item?.value });
              handleCustomFormChange(item?.value, 'patient_id');
            }}
          />
        </Item>
      </div>

      <div className='form-group'>
        <Item
          name='type'
          rules={[{ required: true, message: 'Consultation fee currency  is required' }]}
        >
          <Select placeholder='Type' onChange={(value) => handleCustomFormChange(value, 'type')}>
            <Select.Option value='physical'>Physical</Select.Option>
            <Select.Option value='virtual'>Video</Select.Option>
            <Select.Option value='virtualVisit'>Visit</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item rules={[{ required: true, message: 'Date is required' }]}>
              <DatePicker
                defaultValue={values?.date ? dayjs(values?.date, formatedDate) : dayjs(new Date())}
                disabled={
                  !customFormValues['doctor_id'] || !customFormValues['type'] ? true : false
                }
                disabledDate={(current) => current && current < dayjs().startOf('day')}
                placeholder='Date'
                onChange={(date, dateString) => handleCustomFormChange(date, 'date')}
                size='large'
                style={{ width: '100%', borderRadius: 30 }}
                format='YYYY-MM-DD'
              />
            </Item>

            {/* {customFormValues} */}
          </div>
        </div>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item name='time' rules={[{ required: true, message: 'Time shift is required' }]}>
              <Select
                placeholder='Time shift'
                disabled={
                  !customFormValues['doctor_id'] ||
                  !customFormValues['date'] ||
                  !customFormValues['type']
                    ? true
                    : false
                }
              >
                <OptGroup label='Morning'>
                  {availableSlots?.morning?.length > 0 &&
                    availableSlots?.morning?.map((time: any, i) => (
                      <Option key={`morning-time-${time}-${i}`} value={time}>
                        {time}{' '}
                      </Option>
                    ))}
                </OptGroup>
                <OptGroup label='Evening'>
                  {availableSlots?.evening?.length > 0 &&
                    availableSlots?.evening?.map((time: any, i) => (
                      <Option key={`evening-time-${time}-${i}`} value={time}>
                        {time}{' '}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </Item>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <Button onClick={onClose} type='default'>
          Close
        </Button>
        <Button block={false} type='primary' loading={loading} htmlType='submit'>
          Submit
        </Button>
      </div>
    </Form>
  );

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose
        footer={null}
        maskClosable={false}
        title={<h3 className='title'>{values?.id ? 'Update Appointment' : 'Add Appointment'}</h3>}
      >
        {appointmentForm}
      </Modal>
    </>
  );
};

export default AppointmentAddUpdateForm;
