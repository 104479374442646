import React, { CSSProperties } from 'react';

import './Logo.scss';

type Props = {
  src: string;
  width?: number | string;
  height?: number | string;
  alt?: string;
  style?: CSSProperties;
};

const Logo = ({
  alt = '',
  height = '110px',
  width = '110px',
  src,
  style = { margin: '15px 0px 0px 14%' }
}: Props) => {
  return (
    <div style={style}>
      <img src={src} alt={alt} width={width} height={height} className='logo-img' />
    </div>
  );
};

export default Logo;
