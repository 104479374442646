import { useEffect, useState } from 'react';
import 'stream-chat-react/dist/css/index.css';
import { useSelector } from 'react-redux';
import { Button, notification, Select, Form, Modal } from 'antd';
import { getUser } from '../../utils/StorageService';
import Spinner from '../Spinner';
import AsyncSearchSelect from '../AsyncSearchSelect';
import { useForm } from 'antd/es/form/Form';

const { Item } = Form;

const ChatUsersList = ({ visible, onClose }) => {
    const [form] = useForm();
    const [customFormValues, setCustomFormValues] = useState<any>({ ref_type: "patient" });
    // const allUserTypes = ['admin', 'patients', 'laboratories', 'pharmacies'];
    // const allUserTypes = ['patient', 'laboratory', , 'pharmacy', 'admin'];
    const allUserTypes = ['patient', 'laboratory', 'pharmacy', 'admin'];

    // const [loading, setLoading] = useState(false);
    const [currentChannel, setCurrentChannel] = useState('');
    const currentUser = getUser();
    const client = useSelector((state: any) => state?.chat?.client)

    const intiateChannel = async (endUser) => {
        try {
            // await createUser(endUser)
            const channel = client.channel('messaging', {
                members: [currentUser?.id?.toString(), endUser?.value?.toString()],
            });
            await channel.watch();

            setCurrentChannel(channel)
            onClose()
        } catch (error: any) {
            // if (error?.code === 4) {
            //     notification.warn({
            //         message: `${endUser?.label} have to be signed in at least first time than you can start the chat`,
            //         // description: error?.message
            //     })
            // } else {
            //     notification.error({
            //         message: `Error while creting channel  ${error?.message}`
            //     })
            // }
            notification.error({
                message: `Error while creting channel  ${error?.message}`
            })
            setCustomFormValues({ ...customFormValues, ref_id: null });
        }
    };
    const handleCustomFormChange = (value, formName) => {
        console.log("#value : ", value, "formName : ", formName)
        if (formName === 'ref_type') {
            form.resetFields(['ref_id']);
        }
        else if (formName === 'ref_id') {
            if (value) {
                intiateChannel(value)
            }
        }
        setCustomFormValues({ ...customFormValues, [formName]: value });
    };
    console.log("#customFormValues : ", customFormValues)
    const UserSelectForm = <Form
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
    >
        <div className="row" style={{ marginBottom: 5 }}>
            {/* <div className='col-12'>
                <Item
                    name='ref_type'
                >
                    <Select
                        placeholder='User Type'
                        onChange={(value) => handleCustomFormChange(value, 'ref_type')}
                    >
                        {allUserTypes?.map((type: any, i) => (
                            <Select.Option key={`type-${type}-${i}`} value={type}>
                                {type}
                            </Select.Option>
                        ))}
                    </Select>
                </Item>
            </div> */}
            <div className='col-12'>
                <Item
                    name='ref_id'
                >
                    <AsyncSearchSelect
                        placeholder='Search to chat'
                        url={`/users?`}
                        // dataObj={customFormValues?.ref_type}
                        // refType={customFormValues?.ref_type}
                        defaultValue={
                            customFormValues?.ref_id && {
                                label: customFormValues['ref_id']?.name,
                                value: customFormValues['ref_id']?.id
                            }
                        }
                        selectedValue={(item) => {
                            handleCustomFormChange(item, 'ref_id');
                        }}
                    />
                </Item>
            </div>
        </div>
    </Form>
    return (
        <Modal
            open={visible}
            onCancel={onClose}
            // destroyOnClose
            footer={null}
            // maskClosable={false}
            title={<h3 className='title'>Select New Chat</h3>}
        >
            {UserSelectForm}
        </Modal>
    );
};

export default ChatUsersList;