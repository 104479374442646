import { Avatar } from 'antd';

const AvatarImageDisplay = ({ img, type = 'circle' }) => {
  const isData = img?.startsWith('data:image');
  const isWithPath = img?.startsWith('http');
  if (!img) {
    return (
      <Avatar size={type === 'cover' ? 62 : 40} shape={type === 'cover' ? 'square' : 'circle'} />
    );
  } else if (isData || isWithPath) {
    return (
      <Avatar
        size={type === 'cover' ? 62 : 40}
        shape={type === 'cover' ? 'square' : 'circle'}
        src={img}
      />
    );
  }

  return (
    <Avatar
      size={type === 'cover' ? 62 : 40}
      shape={type === 'cover' ? 'square' : 'circle'}
      src={`${window.location.origin}/${img}`}
    />
  );
};
export default AvatarImageDisplay;
