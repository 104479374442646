import { getAppointmentsFilterParams, getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/appointments/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params = {}, isFiltered?) => {
  try {
    let paramsFormate =
      isFiltered === 'isFiltered'
        ? getAppointmentsFilterParams(params)
        : getPageReferenceParam(params);
    let url = '/appointments' + paramsFormate;
    const res = await api.get(url);
    
    console.log(res , "new data");
    
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const getUpcomingAppointments = async (params = {}, isFiltered?) => {
  try {
    let paramsFormate =
      isFiltered === 'isFiltered'
        ? getAppointmentsFilterParams(params)
        : getPageReferenceParam(params);
    let url = '/upcoming-appointments' + paramsFormate;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const generatePrescriptionPdf = async (appointmentId) => {
  try {
    const url = '/download-prescription/' + appointmentId;
    const res = await api.get(url);
    const blob = new Blob([res?.data], { type: 'text/html' }); //make available for download
    const link: any = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'prescription.html';
    /*link.click();*/
    link.target = '_blank';
    window.open(link);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const add = async (requestBody) => {
  try {
    const res = await api.post('appointments/admin/', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put(`/appointments/${id}`, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete(`/appointments/${id}`);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* addPrescription */
export const addPrescription = async (requestBody) => {
  try {
    const res = await api.post('/prescriptions', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updatePrescription = async (requestBody) => {
  try {
    const res = await api.put(`/prescriptions`, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removePrescription = async (id) => {
  try {
    const res = await api.delete(`/prescriptions/${id}`);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Get all transactions */
export const getAllTransactions = async (params = {}) => {
  try {
    let paramsFormate = getPageReferenceParam(params);
    let url = '/transactions' + paramsFormate;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Get all transactions */
export const getAllRevenueReports = async (params = {}) => {
  try {
    let paramsFormate = getPageReferenceParam(params);
    let url = '/revenue-reports' + paramsFormate;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};