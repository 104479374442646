import React, { useEffect, useState } from 'react';
import { List, Typography, Divider, Avatar } from 'antd';

import { useOnlyPageData, usePageData } from '../../hooks/usePage';
import { useParams } from 'react-router-dom';
import * as patients from '../../services/patients';
import Spinner from '../../components/Spinner';
import * as doctor from '../../services/doctors';
import FileUpload from '../../components/UploadImages/FileUpload';
import AddressIcon from '../../assets/img/address_icon.png';
import { CheckCircleOutlined } from '@ant-design/icons';

const ProfileForm = ({ values }) => {
  /* Formate specialities */
  const formateSpecialities = (spcialities) => {
    let result = '';
    if (spcialities?.length) {
      spcialities?.map((item, index) => {
        if (item?.speciality?.name) {
          result += item?.speciality?.name;
        }
        if (index !== spcialities?.length - 1) {
          result += ',';
        }
      });
    }
    return result;
  };
  /* Formate Qualifications */
  const formateQualifications = (qualifications) => {
    if (qualifications?.length) {
      return (
        <div>
          {qualifications?.map((item, index) => {
            return (
              <li
                key={`doctor-form-qualification-list-${index}`}
                style={{ listStyleType: 'none', marginLeft: 0 }}
              >
                <CheckCircleOutlined style={{ color: '#1C208F' }} />
                <span style={{ marginLeft: 5 }}>{item?.qualification?.title}</span>
              </li>
            );
          })}
        </div>
      );
    }
  };
  /* Formate Qualifications */
  const formateServices = (services) => {
    if (services?.length) {
      return (
        <div>
          {services?.map((item, index) => {
            console.log(item);
            
            return (
              <li
                key={`doctor-form-services-list-${index}`}
                style={{ listStyleType: 'none', marginLeft: 0 }}
              >
                <CheckCircleOutlined style={{ color: '#1C208F' }} />
                <span style={{ marginLeft: 5 }}>{item?.service?.name}</span>
              </li>
            );
          })}
        </div>
      );
    }
  };
  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%' }}>
      <div style={{ backgroundColor: '#1C208F', height: '100px', width: '100%' }}></div>
      <div className='row' style={{ marginTop: '1%' }}>
        <div className='col-md-1 col-sm-2'>
          <Avatar
            style={{
              border: '1px solid whitesmoke',
              backgroundColor: 'whitesmoke',
              padding: 2,
              position: 'absolute',
              left: '6vw',
              top: '-40px'
            }}
            src={values?.image}
            size={62}
            className='mr-4'
          />
        </div>
        <div className='col-md-6 col-sm-10 offset-2 offset-md-0'>
          <p style={{ marginLeft: '5%' }}>
            <strong>Dr. {values?.fullName}</strong>
            <div style={{ color: '#506D85' }}>{formateSpecialities(values?.specialities)}</div>
            <div style={{ margin: '5px 0px', color: '#506D85' }}>
              {values?.years_of_experience} years of experience{' '}
              {values?.hospital ? ',' + values?.hospital[0]?.hospital?.name : ''}
              {/* {console.log(values?.hospital[hospital?.name])}
              {console.log(typeof(values))} */}
            </div>
            {/* <div>{values?.currency?.code} {values?.consultation_fee} Consultation fee</div> */}
            <div style={{ margin: '5px 0px', display: 'flex' }}>
              <img src={AddressIcon} width={50} height={50} /> {values?.address}{' '}
            </div>
          </p>
          <div
            className='custom-card'
            style={{
              marginLeft: '5%',
              marginBottom: '10px',
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #f0f0f0'
            }}
          >
            <strong>About Dr. {values?.fullName}</strong>
            <p>{values?.about}</p>
          </div>
        </div>
        <div className='col-md-4 col-sm-10 offset-2 offset-md-0'>
          {/* {values?.services?.length ?
                        <List
                            header={<div>Services</div>}
                            bordered
                            dataSource={values?.services}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <Typography.Text mark>{item?.service?.name}</Typography.Text> {item}
                                </List.Item>
                            )}
                        /> : null
                    } */}
          <div
            className='custom-card'
            style={{
              marginLeft: '5%',
              marginBottom: '10px',
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #f0f0f0'
            }}
          >
            <strong>Services</strong>
            <p>{formateServices(values?.services)}</p>
          </div>
          <div
            className='custom-card'
            style={{
              marginLeft: '5%',
              marginBottom: '10px',
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #f0f0f0'
            }}
          >
            <strong>Education</strong>
            <p>{formateQualifications(values?.qualifications)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DoctorProfilePage = () => {
  const [doctorDetails, setDoctorDetails] = useState<any>({});

  const { id } = useParams<any>();
  const new_user = localStorage.getItem('new_user');
  useOnlyPageData({});
  /* Fetch data by id and set values */
  const fetchAndSetDoctorsData = async () => {
    const res = await doctor.getById(+id);
    if (res?.data?.data) {
      setDoctorDetails(res?.data?.data);
    }
  };
  /* Fetch details data if new_user !==1 */
  useEffect(() => {
    if (id && id !== 'create' && new_user !== '1') {
      fetchAndSetDoctorsData();
    }
  }, []);
  return doctorDetails ? <ProfileForm values={doctorDetails} /> : <Spinner />;
};

export default DoctorProfilePage;
