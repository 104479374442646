import React, { useEffect } from 'react';
import { Form, Button, Input, Select, DatePicker, Checkbox } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import AsyncSearchSelect from '../../../components/AsyncSearchSelect';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { format } from 'path';
const { Item } = Form;

const AddExperience = ({ values, id, history, isView, ...props }) => {
  const [form] = useForm();
  const [customFormValues, setCustomFormValues] = useState<any>([]);

  // date format
  const dateFormat = 'YYYY-MM-DD';
  // date format
  /* Fetch fields data */
  useEffect(() => {
    if (values && id !== 'create') {
      const clonedValues = [...values];
      const formatedValues = clonedValues?.map((item) => formateExperienceObj(item, 'edit'));
      form.setFieldsValue({
        experiences: formatedValues
      });

      setCustomFormValues([...formatedValues]);
    }
  }, [values]);
  const handleCustomFormChange = (value, formName, key) => {
    if (formName === 'from' || formName === 'to') {
      value = moment(new Date(value)).format('YYYY-MM-DD');
    }
    const formArrayElement = { ...customFormValues[key] };
    formArrayElement[formName] = value;
    customFormValues[key] = { ...formArrayElement };
    setCustomFormValues(customFormValues);
  };
  const handleRemoveFromCustomForm = (key) => {
    const filteredCustomFormValues = customFormValues.filter((item, i) => i !== key);
    setCustomFormValues(filteredCustomFormValues);
  };
  const formateExperienceObj = (experienceObj: any, formate?) => {
    const { title, from, to, hospital_id, is_present, employment_type, id } = experienceObj;
    let finalObj: any = {
      title,
      from: formate === 'edit' ? moment(from) : moment(new Date(from)).format('YYYY-MM-DD'),
      to: formate === 'edit' ? moment(to) : moment(new Date(to)).format('YYYY-MM-DD'),
      hospital_id,
      is_present: is_present,
      employment_type
    };
    if (id) {
      finalObj['id'] = id;
    }
    return finalObj;
  };
  const handleSubmit = (formValues: any) => {
    const formatedValues = customFormValues?.map((item) => formateExperienceObj(item));
    const dataObj = {
      experiences: formatedValues
    };
    props.handleOnSubmitForm(dataObj);
  };
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <Form
      disabled={ViewPageDisable()}
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <Form.List name='experiences'>
        {(fields, { add, remove, ...rest }) => (
          <>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <React.Fragment key={key}>
                    <div className='form-group'>
                      <Item
                        name={[name, 'title']}
                        fieldKey={[fieldKey, 'title']}
                        rules={[{ required: true, message: 'title is required' }]}
                      >
                        <Input
                          onChange={(e) => handleCustomFormChange(e?.target?.value, 'title', key)}
                          placeholder='Title'
                          type='text'
                        />
                      </Item>
                    </div>
                    <div className='form-group'>
                      <Item
                        name={[name, 'hospital_id']}
                        fieldKey={[fieldKey, 'hospital_id']}
                        rules={[
                          {
                            required: !customFormValues[key]?.hospital_id ? true : false,
                            message: 'Please select the hospital'
                          }
                        ]}
                      >
                        <AsyncSearchSelect
                          placeholder='Search Hospital'
                          url='/users?type=hospital&'
                          dataObj='hospital'
                          defaultValue={
                            values[key]?.experience && {
                              label: values[key]?.experience?.name,
                              value: values[key]?.experience?.id
                            }
                          }
                          selectedValue={(item) => {
                            form.setFieldsValue({ hospital_id: item?.value });
                            handleCustomFormChange(item?.value, 'hospital_id', key);
                          }}
                        />
                      </Item>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            // name={[name, 'from']}
                            fieldKey={[fieldKey, 'from']}
                            rules={[{ required: true, message: 'from is required' }]}
                          >
                            <DatePicker
                              defaultValue={dayjs(values[0]?.from)}
                              format={dateFormat}
                              onChange={(date, dateString) =>
                                handleCustomFormChange(date, 'from', key)
                              }
                              disabledDate={(current) =>
                                current > moment(customFormValues[key]?.to).startOf('day') ||
                                (current && current > moment().startOf('day'))
                              }
                              allowClear={false}
                              placeholder='Date From'
                              size='large'
                              style={{ width: '100%' }}
                            />
                          </Item>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            // name={[name, 'to']}
                            fieldKey={[fieldKey, 'to']}
                            rules={[{ required: true, message: 'to is required' }]}
                          >
                            <DatePicker
                              disabled={customFormValues[0]?.is_present ? true : false}
                              defaultValue={dayjs(values[0]?.to)}
                              format={dateFormat}
                              disabledDate={(current) =>
                                (current &&
                                  current < moment(customFormValues[key]?.from).endOf('day')) ||
                                current > moment().endOf('day')
                              }
                              // value={customFormValues[key]?.to}
                              onChange={(date, dateString) =>
                                handleCustomFormChange(date, 'to', key)
                              }
                              allowClear={false}
                              placeholder='Date To'
                              size='large'
                              style={{ width: '100%' }}
                            />
                          </Item>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <Item name={[name, 'is_present']} fieldKey={[fieldKey, 'is_present']}>
                        <Checkbox
                          // checked
                          defaultChecked={customFormValues[key]?.is_present}
                          onChange={(e) => {
                            handleCustomFormChange(e.target.checked, 'is_present', key);
                          }}
                        >
                          Is Present
                        </Checkbox>
                      </Item>
                    </div>
                    <div className='form-group'>
                      <Item
                        name={[name, 'employment_type']}
                        rules={[{ required: true, message: 'employment_type is required' }]}
                      >
                        <Select
                          placeholder='employment_type'
                          onChange={(value) =>
                            handleCustomFormChange(value, 'employment_type', key)
                          }
                        >
                          <Select.Option value='full_time'>Full Time</Select.Option>
                          <Select.Option value='part_time'>Part Time</Select.Option>
                        </Select>
                      </Item>
                    </div>
                    <Button
                      style={{ backgroundColor: 'white', border: 'none' }}
                      type='text'
                      onClick={() => {
                        remove(name);
                        handleRemoveFromCustomForm(name);
                      }}
                      disabled={id ? false : true}
                      icon={<MinusCircleOutlined style={{ color: 'black' }} />}
                    />
                  </React.Fragment>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Add experience
                  </Button>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Form.List>
      {!isView && (
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex justify-content-between'>
              <Button onClick={() => history(-1)} type='default'>
                Cancel
              </Button>
              <Button type='primary' block={false} disabled={props.loading} htmlType='submit'>
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

export default AddExperience;
