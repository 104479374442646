import React from 'react';
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  logoutUser,
  redirectToEntityProfile,
  redirectToUserProfile
} from '../../../utils/StorageService';



// const accountItems = [
//   // { text: 'Edit account', icon: 'icofont-ui-home', route: '/vertical/edit-account' },
//   // { text: 'Calendar', icon: 'icofont-ui-calendar', route: '/vertical/events-calendar' },
//   // { text: 'Settings', icon: 'icofont-ui-settings', route: '/vertical/settings' },
//   { text: 'Manage Profile', icon: 'icofont-ui-user', route: '/vertical/doctors' },
//   { text: 'User profile', icon: 'icofont-ui-user', route: '/vertical/users' },
//   { text: 'Chat', icon: 'icofont-ui-user', route: '/vertical/chat' },
//   { text: 'Log Out', icon: 'icofont-logout', route: '/public/sign-in' }
// ];
const SettingsDropdown = () => {

const items:MenuProps["items"] = [
  {
    key: 'ManageProfile',
    label: (
      <NavLink className='action-item d-flex w-100' to={redirectToEntityProfile()} replace>
        <span className={`icon mr-3 icofont-ui-user`} />
        <span className='text'>{'Manage Profile'}</span>
      </NavLink>
    )
  },
  {
    key: 'UserProfile',
    label: (
      <NavLink className='action-item d-flex w-100' to={redirectToUserProfile()} replace>
        <span className={`icon mr-3 icofont-ui-user`} />
        <span className='text'>{'User Profile'}</span>
      </NavLink>
    )
  },
  {
    key: 'chat',
    label: (
      <NavLink className='action-item d-flex w-100' to={'/vertical/chat'} replace>
        <span className={`icon mr-3 icofont-ui-user`} />
        <span className='text'>{'Chat'}</span>
      </NavLink>
    )
  },
  {
    key: 'logout',
    label: (
      <NavLink className='action-item d-flex w-100' to={'/public/sign-in'} onClick={() => logoutUser()} replace>
        <span className={`icon mr-3 icofont-logout`} />
        <span className='text'>{'Log Out'}</span>
      </NavLink>
    )
  }
];

  // const accountMenu = (
  //   <Menu style={{ minWidth: '180px' }}>
  //     {accountItems.map((item, index) =>
  //       item.text === 'Log Out' ? (
  //         <Menu.Item className='action-item' key={index}>
  //           <NavLink className='d-flex w-100' to={item.route} onClick={() => logoutUser()} replace>
  //             <span className={`icon mr-3 ${item.icon}`} />
  //             <span className='text'>{item.text}</span>
  //           </NavLink>
  //         </Menu.Item>
  //       ) : item.text === 'Manage Profile' ? (
  //         <Menu.Item className='action-item' key={index}>
  //           <NavLink className='d-flex w-100' to={redirectToEntityProfile()} replace>
  //             <span className={`icon mr-3 ${item.icon}`} />
  //             <span className='text'>{item.text}</span>
  //           </NavLink>
  //         </Menu.Item>
  //       ) : item.text === 'User profile' ? (
  //         <Menu.Item className='action-item' key={index}>
  //           <NavLink className='d-flex w-100' to={redirectToUserProfile()} replace>
  //             <span className={`icon mr-3 ${item.icon}`} />
  //             <span className='text'>{item.text}</span>
  //           </NavLink>
  //         </Menu.Item>
  //       ) : (
  //         <Menu.Item className='action-item' key={index}>
  //           <NavLink className='d-flex w-100' to={item.route}>
  //             <span className={`icon mr-3 ${item.icon}`} />
  //             <span className='text'>{item.text}</span>
  //           </NavLink>
  //         </Menu.Item>
  //       )
  //     )}
  //   </Menu>
  // );

  return (
    <Dropdown menu={{items}} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar size={40} className={`icon mr-3 icofont-ui-user`} />
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
