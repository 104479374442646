import React, { useEffect, useState } from 'react';
import { Table, Form, Input, ConfigProvider, Popconfirm, Typography, Button } from 'antd';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import * as permissions from '../../../services/permissions';
import Spinner from '../../../components/Spinner';
import { initialPageRef } from '../../../utils/constants';
import SearchData from '../../../layout/components/search/SearchData';
import ImportExportData from '../../../components/common/ImportExportData';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: Boolean;
  dataIndex: string;
  title: any;
  inputType: Boolean;
  record: any;
  handleCustomFormChange: any;
  index: number;
  children: any;
  // children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  handleCustomFormChange,
  children,
  ...restProps
}) => {
  const inputNode = (
    <ConfigProvider direction={`${dataIndex === 'ar' ? 'rtl' : 'ltr'}`}>
      <Input
        defaultValue={dataIndex === 'ar' ? record?.ar : record?.en}
        disabled={editing ? false : true}
        onChange={(e) => handleCustomFormChange(e.target.value, dataIndex)}
      />
    </ConfigProvider>
  );
  if (record) {
    return (
      <td {...restProps}>
        {dataIndex === 'key' ? (
          <p>{record?.key}</p>
        ) : dataIndex === 'operation' ? (
          children
        ) : (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        )}
      </td>
    );
  }
  return null;
};

const LocalizationSettings = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);
  const [editingKey, setEditingKey] = useState('');
  const initialValues = {
    en: '',
    ar: ''
  };
  const [customFormValues, setCustomFormValues] = useState<any>(initialValues);
  // const [permissionsList, setPermissionsList] = useState<any>(initialValues);

  const isEditing = (record: any) => {
    if (record?.id === editingKey && record?.id && editingKey) {
      return true;
    } else {
      return false;
    }
  };

  const edit = (record: any & { key: React.Key }) => {
    setEditingKey(record?.id);
    if (record) {
      let { en, ar } = record;
      en = en ? en : '';
      ar = ar ? ar : '';
      setCustomFormValues({ en, ar, key: record?.key });
    }
  };

  const cancel = () => {
    setEditingKey('');
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta.current_page ? meta.current_page : pageRef.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setFetching(true);
    const res: any = await permissions.getLocalizations(params);
    setFetching(false);
    setData(res?.data?.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };
  /* Fetch fields data */
  useEffect(() => {
    /* Fetch fields data */
    permissions.getLocalizations().then((res: any) => {
      let localizations = res?.data?.data;
      if (localizations) {
        setFetching(false);
        setData(localizations);
        // if (pageRef?.total === 0 && localizations?.data?.length) {
        // }
        setPagination(localizations?.meta);
        // setCustomFormValues(localizations?.data);
      } else {
        setFetching(false);
        setData([]);
      }
    });
  }, []);

  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    // console.log('value : ', value);
    // console.log('formName : ', formName);
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    setEditingKey('');
  };
  /* Update data submit handler */
  const handleSubmit = async (record: any) => {
    try {
      setLoading(true);
      const res = await permissions.updateLocalizationSettings(customFormValues, editingKey);
      setLoading(false);
      if (res?.status === 200) {
        operationsAfterSubmit(res);
      } else {
        showErrorMessage(res);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  const columns = [
    {
      title: 'Keys',
      dataIndex: 'key',
      width: '25%'
    },
    {
      title: 'English',
      dataIndex: 'en',
      editable: true
    },
    {
      title: 'Arabic',
      dataIndex: 'ar',
      editable: true
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '20%',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              type='link'
              loading={loading}
              onClick={() => handleSubmit(record)}
            >
              Save
            </Button>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <Button type='link'>Cancel</Button>
            </Popconfirm>
          </div>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col,index) => {
    return {
      ...col,
      key:index,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        handleCustomFormChange: handleCustomFormChange,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  if (fetching) {
    return <Spinner />;
  }
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <ImportExportData url='localization' />
      <Form form={form} scrollToFirstError className='mb-4' onFinish={handleSubmit} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered
          pagination={pageRef}
          dataSource={data?.data}
          columns={mergedColumns}
          rowClassName='editable-row'
          onChange={handleTableChange}
          // pagination={false}
          scroll={{ y: 400 }}
        />
      </Form>
    </>
  );
};

export default LocalizationSettings;
