import React, { useEffect, useState } from 'react';

import { Button, Popconfirm } from 'antd';
import LaboratoriesTable from './LaboratoriesTable';
import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IPageData } from '../../../interfaces/page';
import * as laboratries from '../../../services/laboratries';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import { initialPageRef } from '../../../utils/constants';
import SearchData from '../../../layout/components/search/SearchData';
import { useNavigate,useLocation } from 'react-router';    //useHistory replace useNavigate
import TablePageAddAction from '../../../components/common/tables-components/TablePageAddAction';
import TableActions from '../../../components/common/tables-components/TableActions';
import ImportExportData from '../../../components/common/ImportExportData';

const pageData: IPageData = {
  title: 'Laboratories',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Laboratories'
    }
  ]
};

const LaboratoriesPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/laboratories', []);

  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);
  const history = useNavigate();                       //useHistory replace useNavigate
  const history1 = useLocation();                       //useHistory replace useNavigate

  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setLoading(true);
    const res: any = await laboratries.getAll(params);
    setLoading(false);
    setTableData(res.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta.current_page ? meta.current_page : pageRef.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };
  const handleDelete = async (item) => {
    const data = await laboratries.remove(item.id);
    try {
      if (data?.status === 200) {
        showSuccessMessage(data);
        getAllData();
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
    }
  };

  const addUpdatePage = (id, isView?) => {
    const layout = history1.pathname.split('/')[1];           //.location.pathname.split   remove
    history(`/${layout}/laboratories/${id}`);             //.push   remove
    if (isView) {
      localStorage.setItem('isViewLab', isView);
    } else {
      localStorage.removeItem('isViewLab');
    }
  };
  const handleEdit = (row) => {
    addUpdatePage(row?.id);
  };
  const handleView = (row) => {
    const isView = true;
    addUpdatePage(row?.id, isView);
  };
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleView,
      handleDelete: handleDelete,
      handleEdit: handleEdit,
      module: 'laboratories',
      operations: { list: true, update: true, remove: true, add: false }
    };
    return actionObj;
  };
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <ImportExportData url='laboratory' />
      <LaboratoriesTable
        data={tableData?.data}
        actions={(row) => TableActions(getObj(row))}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      {TablePageAddAction({ onAdd: () => addUpdatePage('create'), module: 'laboratories' })}
    </>
  );
};

export default LaboratoriesPage;
