// import { ImportOutlined } from '@ant-design/icons';
import importIcone from "../../assets/img/bro47-01.png"
import { Upload, Button, notification } from 'antd';
import { mediaImport } from '../../services/Api';
import { getToken } from '../../utils/StorageService';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import { useState } from 'react';

const ImportFileUpload = ({ url }: any) => {
  const [importLoading, setImportLoading] = useState(false)
  const attachFile: any = {
    name: 'file',
    action: `${mediaImport}/${url}`,
    headers: {
      Authorization: `bearer ` + JSON.parse(getToken())
    },
    method: 'POST',
    data: {
      content_type: 'file'
    },
    onChange(info: any) {
      setImportLoading(true)
      if (info?.file?.status === 'done' && info?.file?.response?.message) {
        setImportLoading(false)
        // notification.success({
        //   message: info?.file?.response?.message
        // });
        showSuccessMessage(info?.file?.response)
      } else if (info?.file?.status === 'error' && info?.file?.response?.message) {
        setImportLoading(false)
        showErrorMessage(info?.file?.response)
        // notification.error({
        //   message: "Error while importing file"
        // });

      }
    },
    showUploadList: false
  };
  const renderUpload = (
    <Upload
      {...attachFile}
      maxCount={1}
      withCredentials={true}
    >
      <Button loading={importLoading} className='custom-border tranparent color-black' type="primary" icon={<img src={importIcone} height={"12px"} width={"12px"}/>} style={{ width: '100%' }}>
        Import
      </Button>
    </Upload>
  );
  return (
    <>
      {renderUpload}
    </>
  );
};

export default ImportFileUpload;
