import React, { useEffect, useState } from 'react';

import { Button, Collapse, notification } from 'antd';
import AppointmentsTable from './AppointmentsTable';
import { useFetchPageData, usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import AppointmentAddUpdateForm from './AppointmentAddUpdateForm';
import * as appointment from '../../services/appointments';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import { initialPageRef } from '../../utils/constants';
import SearchData from '../../layout/components/search/SearchData';
import { useNavigate, useLocation } from 'react-router-dom'; // useHistory replace useNavigate
import TableActions from '../../components/common/tables-components/TableActions';
import TablePageAddAction from '../../components/common/tables-components/TablePageAddAction';
import FilterQueryForm from './FilterQueryForm';
import { getAppointmentsFilterParams } from '../../utils/QueryParamsString';
import { FilterOutlined } from '@ant-design/icons';
import ImportExportData from '../../components/common/ImportExportData';
import { isDataView } from 'util/types';

const pageData: IPageData = {
  title: 'Appointments',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Appointments'
    }
  ]
};

const AppointmentsPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/appointments', []);

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);
  const [showFilters, setShowFilters] = useState(false);
  const history = useNavigate();
  const history1 = useLocation();

  /* Set pagination after first time data fetch */
  useEffect(() => {
    let unMounted = true;

    if (pageRef?.total === 0 && tableData?.all_appointments?.data?.length && unMounted) {
      setPagination(tableData?.all_appointments?.meta);
    }

    return () => {
      console.log('cleanUp');
      unMounted = false;
    };
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}, filter?) => {
    setLoading(true);
    const res: any = await appointment.getAll(params, filter);
    console.log(res, 'fornt end ');

    setLoading(false);
    setTableData(res.data);
    const meta = res?.data?.data?.all_appointments?.meta;
    setPagination(meta);
    console.log(meta, 'meducre');
  };

  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta?.current_page ? meta?.current_page : pageRef?.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    // console.log(pagination ,"data page ");

    if (pagination.current !== pageRef.current) {
      console.log(pagination, pageRef, 'd');
// setPagination()
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };

  /* Delete table item */
  const handleDelete = async (item) => {
    setSelectedRow(item);
    const data = await appointment.remove(item.id);
    try {
      if (data?.status === 200) {
        showSuccessMessage(data);
        getAllData();
      } else {
        showErrorMessage(data);
      }
    } catch (e) {
      showErrorMessage(data);
    }
  };
  // /////////////////////
  // const addUpdatePage = (id, isView?) => {
  //   const layout = history1.pathname.split('/')[1];            // .location.pathname.split remove
  //   history(`/${layout}/appointments/${id}`);           // .push remove
  //   if (isView) {
  //     localStorage.setItem('isViewAppointment', isView);
  //   } else {
  //     localStorage.removeItem('isViewAppoinment');
  //   }
  // };

  // /////////////////////

  /*Get Filtered data*/
  const getFilteredData = (values: any) => {
    if (!values) {
      setPageRef(initialPageRef);
      getAllData();
    } else {
      getAllData(values, 'isFiltered');
    }
    // setUserParams(values);
  };
  const openAddingModal = () => setModalVisibility(true);

  const closeAddingModal = () => {
    setSelectedRow(null);
    setModalVisibility(false);
  };

  const handleEditModal = (row) => {
    setSelectedRow(row);
    setModalVisibility(true);
  };
  const handleViewPage = (row) => {
    const isView = true;
    // addUpdatePage(row?.id,isView)
    setSelectedRow(row);
    const layout = history1.pathname.split('/')[1];

    history(`/${layout}/appointments/${row?.id}`);
  };

  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleViewPage,
      handleDelete: handleDelete,
      handleEdit: handleEditModal,
      module: 'appointments',
      operations: { list: true, update: true, remove: true, add: false }
    };
    return actionObj;
  };
  return (
    <>
      <div className='d-flex justify-content-between'>
        <SearchData data={{ getAllData, pageRef }} fullWidth={true} />

        <Button
          block={false}
          type='primary'
          onClick={() => setShowFilters(!showFilters)}
          icon={<FilterOutlined />}
        >
          Filter
        </Button>
      </div>
      <div style={{ display: showFilters ? 'block' : 'none' }}>
        <FilterQueryForm setFormValues={getFilteredData} />
      </div>
      <ImportExportData url='appointment' notImport={true} />
      <AppointmentsTable
        data={tableData?.all_appointments?.data}
        actions={(row) => TableActions(getObj(row))}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      {TablePageAddAction({ onAdd: openAddingModal, module: 'appointments' })}
      {/* {console.log(tableData?.data)} */}
      {/* Add update form appointment */}
      {modalVisibility && (
        <AppointmentAddUpdateForm
          onClose={closeAddingModal}
          visible={modalVisibility}
          values={selectedRow ? selectedRow : {}}
          getAllData={getAllData}
        />
      )}
      {/* test font  */}
      {/* <div className='fontChange'>لمّا كان الاعتراف بالكرامة </div> */}
      {/* test font  */}
    </>
  );
};

export default AppointmentsPage;
