// import { history } from '../redux/store';
import CryptoJS from 'crypto-js';
import { encryptKey } from './constants';

// Set Token in localStorage
export const setToken = (token) => {
  localStorage.setItem('token', token);
};

// Get Token from localStorage
export const getToken = () => {
  return localStorage.getItem('token');
};
export const logoutUser = () => {
  return localStorage.clear();
};
/* Redirect from profile */
const redirectUserToScreen = (user) => {
  let route = '';
  if (user?.has_profile === 0) {
    localStorage.setItem('new_user', '1');
  }
  if (user?.user_type === 'doctor') {
    route = `/vertical/doctors/${user?.doctor?.id ? user?.doctor?.id : user?.id}`;
  } else if (user?.user_type === 'hospital') {
    route = `/vertical/hospitals/${user?.hospital?.id ? user?.hospital?.id : user?.id}`;
  } else if (user?.user_type === 'pharmacy') {
    route = `/vertical/pharmacies/${user?.pharmacy?.id ? user?.pharmacy?.id : user?.id}`;
  } else if (user?.user_type === 'laboratory') {
    route = `/vertical/laboratories/${user?.laboratory?.id ? user?.laboratory?.id : user?.id}`;
  } else {
    route = `/vertical/dashboard`;
  }
  return route;
};
/* Redirect to curerent user profile */
export const redirectToUserProfile = () => {
  let user: any = {};
  user = JSON.parse(localStorage.getItem('user'));
  return `/vertical/user-profile/${user?.id}`;
};
/* Redirect to entity e.g hospital,doctor,phar,lab profile */
export const redirectToEntityProfile = () => {
  let user: any = {};
  user = JSON.parse(localStorage.getItem('user'));
  return redirectUserToScreen(user);
};

const checkIfDataExist = (dataObject) => {
  if (dataObject && dataObject != null && dataObject != undefined) {
    // return dataObject;
    return true;
  } else {
    return false;
  }
};

export const isAuthenticated = () => {
  return checkIfDataExist(localStorage.getItem('token')) ? true : false;
};
/* is user authrized for appointments */
export const isAuthrizedForAppointments = () => {
  let user: any = {};
  user = JSON.parse(localStorage.getItem('user'));
  const isShowAppointments = ['admin', 'hospital', 'doctor'].includes(user?.user_type)
    ? true
    : false;
  return isShowAppointments;
};
/* get user type */
export const getUser = () => {
  let user: any = {};
  user = JSON.parse(localStorage.getItem('user'));
  return user;
};
/* get user type */
export const getUserType = () => {
  let user: any = {};
  user = JSON.parse(localStorage.getItem('user'));
  return user?.user_type;
};
/* Update user in local storage in new_user*/
export const updateUserInStorage = (id) => {
  const user: any = JSON.parse(localStorage.getItem('user'));
  user[user?.user_type] = { id };
  user.has_profile = '1';
  localStorage.setItem('user', JSON.stringify(user));
};
/* Persmissions services */
export const setPermissions = (allPermissions) => {
  localStorage.removeItem('permissions');
  let encryptedData = CryptoJS?.AES?.encrypt(
    JSON?.stringify(allPermissions),
    encryptKey
  ).toString();
  localStorage?.setItem('permissions', encryptedData);
};
export const getPermissions = () => {
  let getPermissions = localStorage?.getItem('permissions');
  let decryptedPermissions = '{}';
  if (getPermissions) {
    let decryptedData = CryptoJS?.AES?.decrypt(getPermissions, encryptKey);
    decryptedPermissions = decryptedData?.toString(CryptoJS?.enc?.Utf8);
  }
  return decryptedPermissions;
};
