import axios from 'axios';
import api from './Api';

export const loginService = async (requestBody) => {
  try {
    const res = await api.post('/login', requestBody);
    if (res?.data?.data?.token?.token && res?.data?.data?.user) {
      localStorage.setItem('token', JSON.stringify(res?.data?.data?.token?.token));
      localStorage.setItem('user', JSON.stringify(res?.data?.data?.user));
    }
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const resetPassword = async (data) => {
  try {
    const res = await api.post('/set-new-password', data);
    console.log("#res?.data rp: ", res?.data)
    // if (res?.data?.data?.token?.token && res?.data?.data?.user) {
    // }
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const forgetPassword = async (email) => {
  try {
    const res = await api.post('/forgot-password', email);
    console.log("#res?.data fp: ", res?.data)
    // if (res?.data?.data?.token?.token && res?.data?.data?.user) {
    // }
    return res;
  } catch (err: any) {
    return err.data;
  }
};
