import React, { ReactNode } from 'react';

import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';

const columns: ColumnProps<any>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'name_ar',
    dataIndex: 'name_ar',
    title: 'Arabic Name',
    // width: 150,
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong className='arabic'>{name}</strong>
  },
  {
    key: 'state_id',
    dataIndex: 'state_code',
    title: 'State Code',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (state_code) => <strong>{state_code}</strong>
  },
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const CitiesTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 150,
    fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default CitiesTable;
