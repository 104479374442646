import React, { useEffect, useState } from 'react';

import { Button, Input, notification, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as permissions from '../../../../services/permissions';
import { showErrorMessage, showSuccessMessage } from '../../../../utils/AppUtils';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Item } = Form;

const PaymentStagingSettings = ({ allPaymentKeys }) => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [customFormValues, setCustomFormValues] = useState<any>([]);

  useEffect(() => {
    const {
      PAYMENT_CANCEL,
      PAYMENT_SUCCESS,
      THAWANI_PUBLISHABLE_KEY_STAGING,
      THAWANI_SECRET_KEY_STAGING,
      PAYMENT_CHECKOUT_STAGING,
      THAWANI_URL_STAGING
    } = allPaymentKeys;
    form.setFieldsValue({
      PAYMENT_CANCEL,
      PAYMENT_SUCCESS,
      THAWANI_PUBLISHABLE_KEY_STAGING,
      THAWANI_SECRET_KEY_STAGING,
      PAYMENT_CHECKOUT_STAGING,
      THAWANI_URL_STAGING
    });
    setCustomFormValues({
      PAYMENT_CANCEL,
      PAYMENT_SUCCESS,
      THAWANI_PUBLISHABLE_KEY_STAGING,
      THAWANI_SECRET_KEY_STAGING,
      PAYMENT_CHECKOUT_STAGING,
      THAWANI_URL_STAGING
    });
  }, [allPaymentKeys]);

  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({ ...customFormValues, [formName]: value });
    setIsDisabledSubmit(true);
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
  };
  const testCredentials = async () => {
    setIsLoading(true);
    try {
      const clonedCustomFormValues = { ...customFormValues };
      clonedCustomFormValues.PAYMENT_MODE = 'testing';
      const data = await permissions.testPaymentCred(clonedCustomFormValues);

      if (data?.data?.status === 200) {
        notification.success({
          message: data?.statusText
        });
        setIsDisabledSubmit(false);
        setIsLoading(false);
      } else {
        notification.error({
          message: data?.data?.detail
        });
        // setIsLoading(false);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      // setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const clonedCustomFormValues = { ...customFormValues };
      clonedCustomFormValues.PAYMENT_MODE = 'testing';
      const data = await permissions.updatePaymentSettings(customFormValues);
      if (data?.status === 200) {
        operationsAfterSubmit(data);
        setIsLoading(false);
      } else {
        showErrorMessage(data);
        setIsLoading(false);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setIsLoading(false);
    }
  };
  return (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        is_active: 1
      }}
    >
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <div className='form-group'>
            <Item
              name='PAYMENT_SUCCESS'
              label='PAYMENT SUCCESS'
              rules={[{ required: true, message: 'PAYMENT_SUCCESS is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_SUCCESS')}
                placeholder='PAYMENT SUCCESS'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='PAYMENT_CANCEL'
              label='PAYMENT CANCEL'
              rules={[{ required: true, message: 'PAYMENT_CANCEL is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_CANCEL')}
                placeholder='PAYMENT CANCEL'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='THAWANI_URL_STAGING'
              label='THAWANI URL STAGING'
              rules={[{ required: true, message: 'THAWANI_URL_STAGING is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'THAWANI_URL_STAGING')}
                placeholder='THAWANI URL STAGING'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>

          <div className='form-group'>
            <Item
              name='THAWANI_SECRET_KEY_STAGING'
              label='THAWANI SECRET KEY STAGING'
              rules={[{ required: true, message: 'THAWANI_SECRET_KEY_STAGING is required' }]}
            >
              <Input.Password
                onChange={(e) =>
                  handleCustomFormChange(e.target.value, 'THAWANI_SECRET_KEY_STAGING')
                }
                placeholder='THAWANI SECRET KEY STAGING'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>

          <div className='form-group'>
            <Item
              name='THAWANI_PUBLISHABLE_KEY_STAGING'
              label='THAWANI PUBLISHABLE KEY STAGING'
              rules={[{ required: true, message: 'THAWANI_PUBLISHABLE_KEY_STAGING is required' }]}
            >
              <Input.Password
                onChange={(e) =>
                  handleCustomFormChange(e.target.value, 'THAWANI_PUBLISHABLE_KEY_STAGING')
                }
                placeholder='THAWANI PUBLISHABLE KEY STAGING'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>

          <div className='form-group'>
            <Item
              name='PAYMENT_CHECKOUT_STAGING'
              label='PAYMENT CHECKOUT STAGING'
              rules={[{ required: true, message: 'PAYMENT_CHECKOUT_STAGING is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_CHECKOUT_STAGING')}
                placeholder='PAYMENT CHECKOUT STAGING'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              // name='PAYMENT_MODE'
              label='PAYMENT MODE'
              rules={[{ required: true, message: 'PAYMENT_CHECKOUT_STAGING is required' }]}
            >
              <Input value='testing' readOnly type='text' />
            </Item>
          </div>
          <div className='d-flex justify-content-between'>
            <Button onClick={testCredentials} disabled={isLoading} type='default'>
              Test Credentials
            </Button>
            <Button
              type='primary'
              onClick={handleSubmit}
              disabled={isDisabledSubmit}
              block={false}
              htmlType='submit'
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PaymentStagingSettings;
