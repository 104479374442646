import React, { ReactNode } from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';
import AvatarImageDisplay from '../../../components/common/AvatarImageDisplay';

const columns: ColumnProps<any>[] = [
  {
    key: 'logo',
    title: 'Image',
    dataIndex: 'logo',
    width: 120,
    fixed: 'left',
    render: (img) => <AvatarImageDisplay img={img} />
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
    render: (status) => (
      <Tag style={{ backgroundColor: status === 'active' ? '#17bd6a' : '#ed5564' }}>
        {status === 'active' ? 'Active' : 'In-Active'}
      </Tag>
    )
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const InsurancesTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 150,
    fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;
const keyForTable =()=>{
  return  Date.now()* Math.random()
}
  return (
    <Table
      rowKey={keyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default InsurancesTable;
