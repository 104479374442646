import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/pharmacies/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params = {}) => {
  try {
    const url = '/pharmacies' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/pharmacies', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/pharmacies/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/pharmacies/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Pharmacy branches  */
export async function getPharmacyBranchById(id: number) {
  try {
    const url = '/pharmacy-branches/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAllPharmacyBranches = async (params = {}) => {
  try {
    const url = '/pharmacy-branches' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const addPharmacyBranch = async (requestBody) => {
  
  
  try {
    const res = await api.post('/pharmacy-branches', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updatePharmacyBranch = async (requestBody, id) => {
  try {
    const res = await api.put('/pharmacy-branches/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removePharmacyBranch = async (id) => {
  try {
    const res = await api.delete('/pharmacy-branches/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
