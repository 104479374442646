import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input, Modal, notification } from 'antd';
import { Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as countries from '../../../services/countries';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import api from '../../../services/Api';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const CountryAddUpdateForm = ({ visible, onClose, values, isViewModal, getAllData }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [customFormValues, setCustomFormValues] = useState<any>({});
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    if (formName === "language_id") {
      form.resetFields(["name"])
    }
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  /* Fetch fields data */
  // useEffect(() => {
  //   api.get('/languages').then((res: any) => {
  //     if (res?.data?.data?.length) {
  //       setLanguages(res?.data?.data);
  //     }
  //   });
  // }, []);
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (values?.id) {
        const data = await countries.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data)
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await countries.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data)
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };


  const AddUpdateForm = (
    <>
    <Form
    disabled={isViewModal}
    form={form}
    requiredMark
    layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        language_id: 1
      }}
    >
      {/* <div className='form-group'>
        <Item
          name='language_id'
          rules={[{ required: true, message: 'language is required' }]}
        >
          <Select placeholder='Select Language' onChange={(value) => handleCustomFormChange(value, 'language_id')}>
            {languages?.length > 0 &&
              languages?.map((item: any, i) => (
                <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                  {item?.title}{' '}
                </Select.Option>
              ))}
          </Select>
        </Item>
      </div> */}
      <div className='form-group'>
        <Item name='name' rules={[{ required: true, message: 'Country Name is required' }]}>
          <Input placeholder='Country Name' type='text' />
        </Item>
      </div>
      <ConfigProvider direction={`rtl`}>
        <div className='form-group'>
          <Item name='name_ar' rules={[{ required: true, message: `مطلوب اسم الدولة` }]}>
            <Input placeholder={`اسم الدولة`} type='text' />
          </Item>
        </div>
      </ConfigProvider>
      <div className='form-group'>
        <Item name='iso2'>
          <Input placeholder='ISO2' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='iso3'>
          <Input placeholder='ISO3' type='text' />
        </Item>
      </div>
      {/* <div className='form-group'>
        <Item name='country_id' rules={[{ required: true, message: 'Please select the country' }]}>
          <Select placeholder='Select Country'>
            {allCountries?.length &&
              allCountries?.map((item: any, i) => (
                <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Item>
      </div> */}
      <div className='form-group'>
        <Item name='capital' rules={[{ required: true, message: 'capital is required' }]}>
          <Input placeholder='Capital' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='region' rules={[{ required: true, message: 'region is required' }]}>
          <Input placeholder='region' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='subregion' rules={[{ required: true, message: 'subregion is required' }]}>
          <Input placeholder='subregion' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='phone_code' rules={[{ required: true, message: 'phone_code is required' }]}>
          <Input placeholder='phone_code' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='currency' rules={[{ required: true, message: 'currency is required' }]}>
          <Input placeholder='Currency' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='currency_symbol'
          rules={[{ required: true, message: 'currency_symbol is required' }]}
        >
          <Input placeholder='Currency Symbol' type='text' />
        </Item>
      </div>

      {isViewModal ? (
   <></>
      ) : (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
       {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? (isViewModal ? 'View Country' : 'Update Country') : 'Add Country'}</h3>}
    >
      {AddUpdateForm}
    </Modal>
  );
};

export default CountryAddUpdateForm;
