import { Spin } from 'antd';
import React from 'react';
const Spinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        margin: '20% 0% 0% 0%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spin />
    </div>
  );
};
export default Spinner;
