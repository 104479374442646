import React, { useEffect, useState } from 'react';
import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IPageData } from '../../../interfaces/page';
import * as permissions from '../../../services/permissions';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import { initialPageRef } from '../../../utils/constants';
import SearchData from '../../../layout/components/search/SearchData';
// import TablePageAddAction from '../../../components/common/tables-components/TablePageAddAction';
import TableActions from '../../../components/common/tables-components/TableActions';
import ServiceFeeForm from './servicefees/ServiceFeeForm';
import ServicesFeeTable from './servicefees/ServiceFeeTable';
import TablePageAddAction from '../../../components/common/tables-components/TablePageAddAction';

const pageData: IPageData = {
  title: 'Services',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Services Fee'
    }
  ]
};

const ServicesFeeSettings = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/service-fee', []);
  const [isViewModal, setIsViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);

  /* Set pagination after first time data fetch */
  //   useEffect(() => {
  //     if (pageRef?.total === 0 && tableData?.data?.length) {
  //       setPagination(tableData?.meta);
  //     }
  //   }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setLoading(true);
    const res: any = await permissions.getServiceFeeSettings(params);
    setLoading(false);
    setTableData(res.data);
    // const meta = res?.data?.data?.meta;
    // setPagination(meta);
  };
  /* Set pagination state*/
  //   const setPagination = (meta) => {
  //     setPageRef((pagination) => ({
  //       ...pagination,
  //       current: meta.current_page ? meta.current_page : pageRef.current,
  //       pageSize: meta?.per_page,
  //       total: meta?.total,
  //       sort: ''
  //     }));
  //   };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };
  const handleDelete = async (item) => {
    const data = await permissions.remove(item.id);
    try {
      if (data?.status === 200) {
        showSuccessMessage(data);
        getAllData();
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
    }
  };

  const openAddingModal = () => setModalVisibility(true);

  const closeAddingModal = () => {
    setSelectedRow(null);
    setModalVisibility(false);
    setIsViewModal(false);
  };

  const handleEditModal = (row) => {
    setSelectedRow(row);
    setModalVisibility(true);
  };

  const handleViewModal = (row) => {
    setSelectedRow(row);
    setIsViewModal(true);
    setModalVisibility(true);
  };
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleViewModal,
      handleDelete: handleDelete,
      handleEdit: handleEditModal,
      module: 'settings',
      operations: { list: true, update: true, remove: false, add: false }
    };
    return actionObj;
  };
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <ServicesFeeTable
        data={tableData}
        actions={(row) => TableActions(getObj(row))}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      {TablePageAddAction({ onAdd: openAddingModal, module: 'services' })}

      {/* Add update form appointment */}
      {modalVisibility && (
        <ServiceFeeForm
          onClose={closeAddingModal}
          visible={modalVisibility}
          isViewModal={isViewModal}
          values={selectedRow ? selectedRow : {}}
          getAllData={getAllData}
        />
      )}
    </>
  );
};

export default ServicesFeeSettings;
