export const getPageReferenceParam = (pageRef) => {
  let url = '';
  if (pageRef.current && pageRef.pageSize) {
    url = `?page=${pageRef.current}&per_page=${pageRef.pageSize}`;
  }
  if (pageRef.search) {
    url += `&search_by=search&search=${pageRef.search}`;
  }
  if (pageRef.sort) {
    url += `&sort=${pageRef.sort}`;
  }
  return url;
};
export const getTimeSoltsReferenceParam = (slotsRef) => {
  const url = `?day_id=${slotsRef.day_id}&type=${slotsRef.type}&date=${slotsRef.date}`;
  return url;
};
export const getAppointmentsFilterParams = (filter) => {
  console.log("#filter : ", filter)
  let url = '?search_by=filters';
  if (filter.doctor_id) {
    url += `&doctor_id=${filter.doctor_id}`;
  }
  if (filter.type) {
    url += `&type=${filter.type}`;
  }
  if (filter.payment_status) {
    url += `&payment_status=${filter.payment_status}`;
  }
  if (filter.status) {
    url += `&status=${filter.status}`;
  }
  if (filter.date_search) {
    url += `&date_search=${filter.date_search}`;
  }
  if (filter.speciality_id) {
    url += `&speciality_id=${filter.speciality_id}`;
  }
  if (filter.start_date) {
    url += `&start_date=${filter.start_date}`;
  }
  if (filter.end_date) {
    url += `&end_date=${filter.end_date}`;
  }
  return url;
};
