import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import PublicLayout from '../../layout/public/Public';
import { useForm } from 'antd/es/form/Form';
import { showErrorMessage } from '../../utils/AppUtils';
import { loginService } from '../../services/login';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../utils/StorageService';

const { Item } = Form;
const SignIn = () => {
  const history = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated() !== false) {
      window.location.href = '/';
      // <Redirect exact to='/vertical/dashboard' />;
    }
  }, []);

  const navigateToScreen = async (dataRes: any) => {
    const user = dataRes?.data?.data?.user;
    if (user?.user_type === 'doctor' && user?.has_profile === 0) {
      localStorage.setItem('new_user', '1');
      history(`/vertical/doctors/${user?.id}`);
    } else if (user?.user_type === 'hospital' && user?.has_profile === 0) {
      localStorage.setItem('new_user', '1');
      let route = `/vertical/hospitals/${user?.id}`;
      history(route);
    } else if (user?.user_type === 'pharmacy' && user?.has_profile === 0) {
      localStorage.setItem('new_user', '1');
      history(`/vertical/pharmacies/${user?.id}`);
    } else if (user?.user_type === 'laboratory' && user?.has_profile === 0) {
      localStorage.setItem('new_user', '1');
      history(`/vertical/laboratories/${user?.id}`);
    } else {
      history('/vertical/dashboard');
    }
  };
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      let data = await loginService(values);
      setLoading(false);
      if (data?.status === 200) {
        navigateToScreen(data);
      } else {
        showErrorMessage(data);
        setLoading(false);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
      <h4 className='mt-0 mb-1 center-align'>Login</h4>

      <p className='text-color-200 center-align margin-bottom-small'>
        Login to access your Account
      </p>

      <Form form={form} layout='vertical' className='mb-4' onFinish={handleSubmit}>
        <Item name='email' rules={[{ required: true, message: 'Email is required' }]}>
          <Input placeholder='Email' type='email' />
        </Item>
        <Item
          name='password'
          rules={[
            { required: true, min: 3, max: 500, message: 'Must be between 6 to 20 characters.' }
          ]}
        >
          <Input placeholder='Password' type='password' />
        </Item>
        <Button
          block={false}
          type='primary'
          loading={loading}
          htmlType='submit'
          className='center-align width-100'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Login
        </Button>
      </Form>
      <p className='mb-1'>
        <Link to="forget-password">Forgot password?</Link>
      </p>
      {/* 
      <p>
        Don't have an account? <Link to='sign-up'>Sign up!</Link>
      </p> */}
    </PublicLayout>
  );
};

export default SignIn;
