//new code

import React, { useEffect } from 'react';
import { Form, Button, Input, Select, DatePicker, Checkbox } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import api from '../../../services/Api';
import AsyncSearchSelect from '../../../components/AsyncSearchSelect';
import FileUpload from '../../../components/UploadImages/FileUpload';
import moment from 'moment';
import { getUserType } from '../../../utils/StorageService';
import Spinner from '../../../components/Spinner';
import { valueScaleCorrection } from 'framer-motion/types/render/dom/projection/scale-correction';
import dayjs from 'dayjs';
const { TextArea } = Input;
const { Item } = Form;

const RegisterForm = ({ values, id, history, isView, new_user, ...props }) => {
  const [form] = useForm();
  const [specialities, setSpecialities] = useState([]);
  const [services, setServices] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [commisions, setCommisions] = useState([]);
  const [markups, setMarkups] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  console.log(customFormValues);

  const [allCountries, setAllCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [dateob, setDateob] = useState(values?.dob ? values?.dob : dayjs(new Date()));
  const [cardExpDate, setCardExpDate] = useState(
    values.resident_card_expiry ? values.resident_card_expiry : dayjs(new Date())
  );
  const [cities, setCities] = useState([]);

  const dateFormat = 'YYYY/MM/DD';

  useEffect(() => {
    /* Fetch fields data */
    api.get('/specialities').then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setSpecialities(res?.data?.data?.data);
      }
    });
    api.get('/services').then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setServices(res?.data?.data?.data);
      }
    });
    api.get('/currencies').then((res: any) => {
      if (res?.data?.data?.length) {
        setCurrencies(res?.data?.data);
      }
    });
    api.get('/languages').then((res: any) => {
      if (res?.data?.data?.length) {
        setLanguages(res?.data?.data);
      }
    });
    api.get('/service-fee?search_by=commision').then((res: any) => {
      if (res?.data?.data?.length) {
        setCommisions(res?.data?.data);
      }
    });
    api.get('/service-fee?search_by=markup').then((res: any) => {
      if (res?.data?.data?.length) {
        setMarkups(res?.data?.data);
      }
    });
    api.get('/countries?per_page=1000').then((res: any) => {
      if (res?.data?.data?.countries?.data?.length) {
        console.log(res?.data?.data?.countries?.data);

        setAllCountries(res?.data?.data?.countries?.data);
      }
    });
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetStatesData = (value) => {
    api.get(`/states?country_id=${value}&per_page=1000`).then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setStates(res?.data?.data?.data);
      }
    });
  };
  /* Fetch data by id and set values */
  const fetchAndSetCitiesData = (value) => {
    api.get(`/cities?state_id=${value}&per_page=1000`).then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setCities(res?.data?.data?.data);
      }
    });
  };
  /* Fetch fields data */
  useEffect(() => {
    const { id } = values;
    console.log(values);

    if (values && id) {
      const clonedValues = { ...values };

      if (clonedValues?.dob) {
        setDateob(dayjs(dateob));
        // clonedValues.dob = moment(clonedValues.dob);
      }
      if (clonedValues?.resident_card_expiry) {
        setCardExpDate(clonedValues.resident_card_expiry);
        // clonedValues.resident_card_expiry = moment(clonedValues.resident_card_expiry);
      }
      if (clonedValues?.specialities?.length) {
        const speciality_ids = [];
        clonedValues?.specialities?.map(
          (item) =>
            !speciality_ids.includes(item.speciality_id) && speciality_ids.push(item.speciality_id)
        );
        clonedValues.speciality_ids = speciality_ids;
      }
      if (clonedValues?.services?.length) {
        const services_ids = [];
        clonedValues?.services?.map(
          (item) => !services_ids.includes(item.service_id) && services_ids.push(item.service_id)
        );
        clonedValues.service_ids = services_ids;
      }
      if (clonedValues?.language?.length) {
        const language_ids = [];
        clonedValues?.language?.map(
          (item) => !language_ids.includes(item.language_id) && language_ids.push(item.language_id)
        );
        // clonedValues?.language?.map((item) =>language_ids.push(item.language_id));

        clonedValues.language_ids = language_ids;
      }
      clonedValues.is_physical = clonedValues?.is_physical === 1 ? true : false;
      clonedValues.is_virtual = clonedValues?.is_virtual === 1 ? true : false;
      // clonedValues.is_visit = clonedValues?.is_visit === 1 ? true : false;
      form.setFieldsValue({
        ...clonedValues
      });

      fetchAndSetStatesData(clonedValues?.country_id);
      fetchAndSetCitiesData(clonedValues?.state_id);
      setCustomFormValues({
        ...clonedValues
      });
    }
  }, [values]);

  const servicesSelectProps = {
    mode: 'multiple' as const,
    style: { width: '100%' },
    value: customFormValues['service_ids'],
    services,
    maxTagCount: 'responsive' as const,
    onChange: (newValue: string[]) => {
      handleCustomFormChange(newValue, 'service_ids');
    }
  };
  const specialitiesSelectProps = {
    mode: 'multiple' as const,
    style: { width: '100%' },
    value: customFormValues['speciality_ids'],
    specialities,
    maxTagCount: 'responsive' as const,
    onChange: (newValue: string[]) => {
      handleCustomFormChange(newValue, 'speciality_ids');
    }
  };
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'country_id') {
      form.resetFields(['state_id', 'city_id']);
      fetchAndSetStatesData(value);
    } else if (formName === 'state_id') {
      form.resetFields(['city_id']);
      fetchAndSetCitiesData(value);
    }
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  const handleSubmit = async (formValues: any) => {
    // const temp = moment(new Date(formValues.dob2)).format('YYYY-MM-DD')

    formValues.image = customFormValues?.image;
    formValues.resident_card_proof = customFormValues?.resident_card_proof;
    formValues.moh_licencse_proof = customFormValues?.moh_licencse_proof;
    formValues.is_virtual = customFormValues?.is_virtual === true ? 1 : 0;
    formValues.is_physical = customFormValues?.is_physical === true ? 1 : 0;
    // formValues.is_visit = customFormValues?.is_visit === true ? 1 : 0;
    formValues.user_id = formValues.userId;
    // formValues.resident_card_expiry = dayjs(new Date(formValues.resident_card_expiry)).format(
    //   'YYYY-MM-DD'
    // );
    props.handleOnSubmitForm({
      ...formValues,
      //@ts-ignore
      dob: dayjs(new Date(dateob)).format('YYYY-MM-DD'),
      resident_card_expiry: moment(new Date(cardExpDate)).format('YYYY-MM-DD')
    });
  };

  if (id && id !== 'create' && !Object.values(values).length && Number(new_user) !== 1) {
    return <Spinner />;
  }
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  const disabledDate = (current) => {
    const eighteenYearsAgo = dayjs().subtract(18, 'years');

    return current && (current > dayjs().endOf('day') || current > eighteenYearsAgo);
  };
  function disabledCardDate(current) {
    return current && current < moment().endOf('day');
  }

  return (
    <Form
      disabled={ViewPageDisable()}
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <div className='row'>
        <div className='col-md-8 col-sm-12'>
          <div className='form-group'>
            <Item name='image'>
              <FileUpload
                uploadType='logo'
                mediaLink={form.getFieldValue('image')}
                placeholder='Upload Image'
                handleUpload={(img) => handleCustomFormChange(img, 'image')}
              />
            </Item>
          </div>
          {id === 'create' && (
            <div className='form-group'>
              <Item
                name='userId'
                rules={[{ required: true, message: 'Please select the hospital user' }]}
              >
                <AsyncSearchSelect
                  placeholder='Search Doctor User'
                  url='/users?type=doctor&'
                  selectedValue={(item) => form.setFieldsValue({ userId: item?.value })}
                />
              </Item>
            </div>
          )}
          <div className='form-group'>
            <Item
              name='hospital_id'
              rules={[{ required: true, message: 'Please select the hospital' }]}
            >
              <AsyncSearchSelect
                placeholder='Search Hospital'
                url='/hospitals?'
                dataObj='hospital'
                defaultValue={
                  values?.current_hospital && {
                    label: values['current_hospital']?.name,
                    value: values['current_hospital']?.id
                  }
                }
                selectedValue={(item) => form.setFieldsValue({ hospital_id: item?.value })}
              />
            </Item>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item
                  name='first_name'
                  rules={[{ required: true, message: 'first name is required' }]}
                >
                  <Input placeholder='First Name' type='text' />
                </Item>
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item
                  name='last_name'
                  rules={[{ required: true, message: 'last name is required' }]}
                >
                  <Input placeholder='Last Name' name='last_name' type='text' />
                </Item>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <Item name='address' rules={[{ required: true, message: 'address is required' }]}>
              <TextArea rows={3} placeholder='Address' />
            </Item>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item rules={[{ required: true, message: 'dob is required' }]}>
                  <DatePicker
                    // disabledDate={(current) => current && current > dayjs().subtract(18, 'years')}
                    disabledDate={disabledDate}
                    style={{ width: '100%', borderRadius: 30, height: '40px' }}
                    defaultValue={dayjs(dateob, dateFormat)}
                    format={dateFormat}
                    onChange={(val) => {
                      setDateob(val);
                    }}
                  />
                </Item>
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item name='gender' rules={[{ required: true, message: 'Gender is required' }]}>
                  <Select placeholder='Gender'>
                    <Select.Option value='male'>Male</Select.Option>
                    <Select.Option value='female'>Female</Select.Option>
                  </Select>
                </Item>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item
                  name='resident_card_no'
                  rules={[
                    // { min: 8, max: 8 },
                    {
                      required: true,
                      message: 'Resident card no is required'
                    }
                  ]}
                >
                  <Input placeholder='Resident card no' type='text' />
                </Item>
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item
                  // name='resident_card_expiry'
                  rules={[{ required: true, message: 'Resident card expiry  is required' }]}
                >
                  <DatePicker
                    defaultValue={dayjs(cardExpDate, dateFormat)}
                    disabledDate={disabledCardDate}
                    placeholder='Resident card expiry'
                    size='large'
                    style={{ width: '100%', borderRadius: 30 }}
                    onChange={(val) => {
                      setCardExpDate(val);
                    }}
                  />
                </Item>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <FileUpload
                  uploadType='file'
                  mediaLink={form.getFieldValue('resident_card_proof')}
                  placeholder='Upload resident card file'
                  handleUpload={(img) => handleCustomFormChange(img, 'resident_card_proof')}
                />
              </div>
            </div>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <div className='form-group'>
                  <FileUpload
                    uploadType='file'
                    mediaLink={form.getFieldValue('moh_licencse_proof')}
                    placeholder='Upload moh license file'
                    handleUpload={(img) => handleCustomFormChange(img, 'moh_licencse_proof')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <Item
              name='years_of_experience'
              rules={[{ required: true, message: 'Years of experience is required' }]}
            >
              <Input placeholder='Years of experience' type='text' />
            </Item>
          </div>
          <div className='form-group'>
            <Item name='about' rules={[{ required: true, message: 'about is required' }]}>
              <TextArea rows={3} placeholder='About' />
            </Item>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item
                  name='consultation_fee'
                  rules={[{ required: true, message: 'Consultation Fee is required' }]}
                >
                  <Input placeholder='Consultation Fee' type='number' />
                </Item>
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <Item
                  name='consultation_fee_currency'
                  rules={[{ required: true, message: 'Consultation fee currency  is required' }]}
                >
                  <Select placeholder='Consultation fee currency '>
                    {currencies?.length > 0 &&
                      currencies?.map((item: any, i) => (
                        <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                          {item.name}{' '}
                        </Select.Option>
                      ))}
                  </Select>
                </Item>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='form-group'>
              <Item
                name='language_ids'
                rules={[{ required: true, message: 'languages is required' }]}
              >
                <Select placeholder='Languages' mode='multiple' allowClear>
                  {languages?.length > 0 &&
                    languages?.map((item: any, i) => (
                      <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                        {item?.title}
                      </Select.Option>
                    ))}
                </Select>
              </Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <Item
                  name='country_id'
                  rules={[{ required: true, message: 'Country is required' }]}
                >
                  <Select
                    placeholder='Country'
                    showSearch={true}
                    filterOption={(input, option) => {
                      const filtered = option?.props?.children?.filter((item) => {
                        return item?.toLowerCase()?.includes(input?.toLowerCase());
                      });

                      return filtered.length >= 1 ? true : false;
                    }}
                    optionFilterProp='children'
                    allowClear
                    onChange={(value) => {
                      handleCustomFormChange(value, 'country_id');
                    }}
                  >
                    {allCountries?.length > 0 &&
                      allCountries?.map((item: any, i) => (
                        <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                          {item.name}{' '}
                        </Select.Option>
                      ))}
                  </Select>
                </Item>
              </div>
            </div>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <Item name='state_id' rules={[{ required: true, message: 'State is required' }]}>
                  <Select
                    onChange={(value) => handleCustomFormChange(value, 'state_id')}
                    placeholder='State'
                    showSearch={true}
                    filterOption={(input, option) => {
                      const filtered = option?.props?.children?.filter((item) =>
                        item?.toLowerCase()?.includes(input?.toLowerCase())
                      );
                      return filtered.length >= 1 ? true : false;
                    }}
                    optionFilterProp='children'
                    allowClear
                  >
                    {states?.map((item: any, i) => (
                      <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                        {item.name}{' '}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <Item name='city_id' rules={[{ required: true, message: 'City is required' }]}>
              <Select
                placeholder='City'
                showSearch={true}
                filterOption={(input, option) => {
                  const filtered = option?.props?.children?.filter((item) =>
                    item?.toLowerCase()?.includes(input?.toLowerCase())
                  );
                  return filtered.length >= 1 ? true : false;
                }}
                optionFilterProp='children'
                allowClear
                onChange={(value) => handleCustomFormChange(value, 'city_id')}
              >
                {cities?.length > 0 &&
                  cities?.map((item: any, i) => (
                    <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                      {item.name}{' '}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='speciality_ids'
              rules={[{ required: true, message: 'speciality is required' }]}
            >
              <Select placeholder='Speciality' allowClear {...specialitiesSelectProps}>
                {specialities?.length > 0 &&
                  specialities?.map((item: any, i) => (
                    <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                      {item.name}{' '}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </div>
          <div className='form-group'>
            <Item name='service_ids' rules={[{ required: true, message: 'services is required' }]}>
              <Select placeholder='Services' {...servicesSelectProps} allowClear>
                {services?.length > 0 &&
                  services?.map((item: any, i) => (
                    <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                      {item.name}{' '}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <Item
                  name='commision'
                  rules={[{ required: true, message: 'Commision  is required' }]}
                >
                  <Select placeholder='Commision '>
                    {commisions?.length > 0 &&
                      commisions?.map((item: any, i) => (
                        <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                          {item?.name}{' '}
                        </Select.Option>
                      ))}
                  </Select>
                </Item>
              </div>
            </div>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <Item name='markup' rules={[{ required: true, message: 'Markup  is required' }]}>
                  <Select placeholder='Markup '>
                    {markups?.length > 0 &&
                      markups?.map((item: any, i) => (
                        <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                          {item?.name}{' '}
                        </Select.Option>
                      ))}
                  </Select>
                </Item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-2'>Availability</div>
        <div className='col-md-2 col-sm-12'>
          <div className='form-group'>
            <Item name='is_virtual' required={true}>
              <Checkbox
                checked={customFormValues?.is_virtual}
                onChange={(e) => handleCustomFormChange(e.target.checked, 'is_virtual')}
              >
                Virtual
              </Checkbox>
            </Item>
          </div>
        </div>
        <div className='col-md-2 col-sm-12'>
          <div className='form-group'>
            <Item name='is_visit'>
              <Checkbox
                checked={customFormValues?.is_visit}
                onChange={(e) => handleCustomFormChange(e.target.checked, 'is_visit')}
              >
                Visit
              </Checkbox>
            </Item>
          </div>
        </div>
        <div className='col-md-2 col-sm-12'>
          <div className='form-group'>
            <Item name='is_physical'>
              <Checkbox
                checked={customFormValues?.is_physical}
                onChange={(e) => handleCustomFormChange(e.target.checked, 'is_physical')}
              >
                Physical
              </Checkbox>
            </Item>
          </div>
        </div>
      </div>
      {!isView && (
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex justify-content-between'>
              <Button
                onClick={() =>
                  getUserType() !== 'admin' ? history.push('/vertical/dashboard') : history(-1)
                }
                type='default'
              >
                Cancel
              </Button>
              <Button type='primary' block={false} disabled={props.loading} htmlType='submit'>
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
export default RegisterForm;
