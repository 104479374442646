
export const addChatClient = (data: any) => {
  return ({
    type: "ADD_CHAT_CLIENT",
    payload: data
  })
};

export const chatUserConnected = (data: any) => ({
  type: "CHAT_USER_CONNECT",
  payload: data
});
