
const initialState: any = {
  isUserConnected: false
};

export const chatDataReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "ADD_CHAT_CLIENT":
      return { ...action.payload };
    case "UPDATE_CHAT_DATA":
      return { ...state, ...action.payload };
    case "RESET_CHAT_DATA":
      return { ...initialState.client };
    case "CHAT_USER_CONNECT":
      return { ...action.payload };
    default:
      return { ...state };
  }
};
