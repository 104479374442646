import React, { ReactNode } from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';

const columns: ColumnProps<any>[] = [

  {
    key: 'name',
    dataIndex: 'name',
    title: 'Branch Name',
    width: 200,
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'laboratory',
    dataIndex: 'laboratory',
    title: 'Parent Laboratory',
    width: 200,
    render: (laboratory) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {laboratory?.name}
      </span>
    )
  },
  {
    key: 'address',
    dataIndex: 'address',
    title: 'Address',
    width: 300,
    render: (address) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {address?.slice(0, 45) + '...'}
      </span>
    )
  },
  // {
  //   key: 'is_active',
  //   dataIndex: 'is_active',
  //   title: 'Status',
  //   render: (is_active) => (
  //     <Tag style={{ backgroundColor: is_active === 1 ? '#17bd6a' : '#ed5564' }}>
  //       {is_active === 1 ? 'Active' : 'In-Active'}
  //     </Tag>
  //   )
  // }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const LabsBranchesTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 200,
    // fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      // scroll={{ x: 1300 }}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default LabsBranchesTable;
