export const initialPageRef = {
  current: 1,
  sort: '',
  pageSize: 10,
  total: 0,
  hideOnSinglePage: true,
  showSizeChanger: false
};
export const encryptKey = 'permissions@modules';
export const streamChatKey = 'add6svmyhs5w';
export const MapStaticApiKey = 'AIzaSyDUFJlXGCfMDqqrngS4lJvfxwE-RYhIP8c';
