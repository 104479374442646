import React, { useEffect, useState } from 'react';
// import { withRouter } from 'react-router-dom';
import { IMenuItem } from '../../../interfaces/main-menu';
import { useLocation } from 'react-router-dom';
import classNames from '../../../utils/class-names';

import MenuGroupTitle from './GroupTitle';
import ItemWithSub from './ItemWithSub';
import SimpleItem from './SimpleItem';

import './Menu.scss';
import Spinner from '../../../components/Spinner';

type MenuProps = {
  orientation?: 'vertical' | 'horizontal';
  data?: IMenuItem[];
  children?: any;
  opened?: boolean;
  onCloseSidebar?: () => void;
  className?: string;
};

type RouterProps = {
  location: Location;
};

type Props = (RouterProps & MenuProps) | any;

const haveActive = (sub: any[], route: string) => !!sub.find((item) => item.slug === route);

const Menu = ({
  data,
  orientation,
  location,
  children,
  className,
  onCloseSidebar,
  loading,
  opened
}: Props) => {
  const [menu, setMenu] = useState<IMenuItem[]>([]);

  const history =useLocation()

  useEffect(() => {
    setMenu(data);
  }, [data]);

  useEffect(() => {
    const currentRoute = history.pathname.split('/')[2];
    const updatedMenu = data
      ? data.map((item) => {
          if (item?.children) {
            return { ...item, active: haveActive(item.children, currentRoute) };
          }

          return { ...item, active: item.slug === currentRoute };
        })
      : [];
    setMenu(updatedMenu);
  }, [location, data]);

  useEffect(() => {
    onCloseSidebar && opened && onCloseSidebar();
  }, [location]);

  const handleItemClick = (itemTitle: string) => {
    const updateMenu = [...menu];

    for (let item of updateMenu) {
      if (item.title !== itemTitle) {
        continue;
      }

      item.active = !item.active;
      break;
    }
    setMenu(updateMenu);
  };

  const menuClasses = classNames({
    'main-menu': true,
    horizontal: orientation === 'horizontal'
  });

  const menuItems = menu.map((item: any, i: number) => {
    if (item.group_title) {
      return <MenuGroupTitle key={`menu-item-${i}`} title={item.title} />;
    }

    if (item?.children?.length && item?.permissions?.length) {
      return (
        <ItemWithSub
          key={i}
          layout={orientation}
          sub={item.children}
          title={item.title}
          icon={item.icon}
          location={location}
          opened={item.active}
          is_active={item.is_active}
          onClick={handleItemClick}
        />
      );
    }

    return item.is_active && item.parent === 0 && item?.is_parent === 0 ? (
      <SimpleItem
        key={i}
        icon={item.icon}
        layout={orientation}
        title={item.title}
        route={item.slug}
        is_active={item.is_active}
      />
    ) : null;
  });

  return (
    <div className={`${menuClasses} ${!!className && className}`}>
      {children}
      {!!menuItems.length ? (
        <nav className='main-menu-wrap'>
          <ul className='menu-ul'>{menuItems}</ul>
        </nav>
      ) : loading ? (
        <Spinner />
      ) : null}
    </div>
  );
};

export default Menu; //withRouter(Menu) remove
