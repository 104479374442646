import React, { ReactNode } from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';

const columns: ColumnProps<any>[] = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Title',
    // width: 150,
    sorter: (a, b) => (a.title > b.title ? 1 : -1),
    render: (title) => <strong>{title}</strong>
  },
  {
    key: 'title_ar',
    dataIndex: 'title_ar',
    title: 'Arabic Title',
    // width: 150,
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong className='arabic'>{name}</strong>
  },
  // {
  //   key: 'is_active',
  //   dataIndex: 'is_active',
  //   title: 'Status',
  //   render: (is_active) => (
  //     <Tag style={{ backgroundColor: is_active === 1 ? '#17bd6a' : '#ed5564' }}>
  //       {is_active === 1 ? 'Active' : 'In-Active'}
  //     </Tag>
  //   )
  // },
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const QualificationsTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 150,
    fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default QualificationsTable;
