// import React, { useEffect, useState } from 'react';

// import { Button, ConfigProvider, Input, Modal, Select } from 'antd';
// import { Form } from 'antd';
// import { useForm } from 'antd/es/form/Form';
// import * as specialty from '../../../services/specialities';
// import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
// import FileUpload from '../../../components/UploadImages/FileUpload';
// import api from '../../../services/Api';

// const { Item } = Form;

// type Props = {
//   visible: boolean;
//   values: any;
//   onClose: () => void;
//   getAllData: () => void;
//   isViewModal?: boolean;
// };

// const SpecialityAddUpdateForm = ({ visible, onClose, values, getAllData, isViewModal }: Props) => {
//   const [form] = useForm();
//   const [loading, setLoading] = useState(false);
//   const [customFormValues, setCustomFormValues] = useState<any>([]);

//   /* set form values in edit mode */
//   useEffect(() => {
//     const { id } = values;
//     if (values && id) {
//       const clonedValues = Object.assign({}, values);
//       form.setFieldsValue({
//         ...clonedValues
//       });
//       setCustomFormValues(clonedValues);
//     }
//   }, [values]);
//   const handleCustomFormChange = (value, formName) => {
//     setCustomFormValues({ ...customFormValues, [formName]: value });
//   };
//   /* handle submit form */
//   const handleSubmit = async (formValues: any) => {
//     formValues.icon = customFormValues?.icon;
//     try {
//       setLoading(true);
//       if (values?.id) {
//         const data = await specialty.update(formValues, values.id);
//         setLoading(false);
//         if (data?.status === 200) {
//           showSuccessMessage(data);
//           getAllData();
//           setTimeout(() => {
//             form.resetFields();
//             onClose();
//           }, 1000);
//         } else {
//           showErrorMessage(data);
//         }
//       } else {
//         const data = await specialty.add(formValues);
//         setLoading(false);
//         if (data?.status === 200) {
//           showSuccessMessage(data);
//           getAllData();
//           setTimeout(() => {
//             form.resetFields();
//             onClose()
//           }, 1000);
//         } else {
//           showErrorMessage(data);
//         }
//       }
//     } catch (e: any) {
//       showErrorMessage(e?.data);
//       setLoading(false);
//     }
//   };
//   const appointmentForm = (
//     <Form
//       form={form}
//       requiredMark
//       layout='vertical'
//       scrollToFirstError
//       className='mb-4'
//       onFinish={handleSubmit}
//       initialValues={{
//         language_id: 1
//       }}
//     >
//       <div className='form-group'>
//         <Item name='icon'>
//           <FileUpload
//             uploadType='logo'
//             mediaLink={
//               form.getFieldValue('icon') ? form.getFieldValue('icon') : customFormValues['icon']
//             }
//             placeholder='Upload Image'
//             handleUpload={(img) => handleCustomFormChange(img, 'icon')}
//           />
//         </Item>
//       </div>
//       <div className='form-group'>
//         <Item name='name' rules={[{ required: true, message: 'Speciality Name is required' }]}>
//           <Input placeholder='Speciality Name' type='text' />
//         </Item>
//       </div>
//       <ConfigProvider direction={`rtl`}>
//         <div className='form-group'>
//           <Item className='arabic' name='name_ar' rules={[{ required: true, message: `مطلوب اسم التخصص` }]}>
//             <Input className='arabic' placeholder={`اسم التخصص`} type='text' />
//           </Item>
//         </div>
//       </ConfigProvider>
//       {
//         isViewModal ? (
//           <div className='d-flex justify-content-between'>
//             <Button onClick={onClose} type='default'>
//               Close
//             </Button>
//             <Button block={false} type='primary' onClick={onClose} loading={loading}>
//               Ok
//             </Button>
//           </div>
//         ) : (
//           <div className='d-flex justify-content-between'>
//             <Button onClick={onClose} type='default'>
//               Close
//             </Button>
//             <Button block={false} type='primary' loading={loading} htmlType='submit'>
//               Submit
//             </Button>
//           </div>
//         )
//       }
//     </Form >
//   );
//   return (
//     <Modal
//       open={visible}
//       onCancel={onClose}
//       destroyOnClose
//       footer={null}
//       maskClosable={false}
//       title={<h3 className='title'>{values?.id ? 'Update Specialty' : 'Add Specialty'}</h3>}
//     >
//       {appointmentForm}
//     </Modal>
//   );
// };

// export default SpecialityAddUpdateForm;

import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input, Modal, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as specialty from '../../../services/specialities';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import FileUpload from '../../../components/UploadImages/FileUpload';
import api from '../../../services/Api';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const SpecialityAddUpdateForm = ({ visible, onClose, values, getAllData, isViewModal }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);

  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues(clonedValues);
    }
  }, [values]);
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    formValues.icon = customFormValues?.icon;
    try {
      setLoading(true);
      if (values?.id) {
        const data = await specialty.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await specialty.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };

  // const ViewPadgeDisable = () => (isViewModal === 'true' ? true : false);

  // console.log(isViewModal ,typeof isViewModal ) ;

  const appointmentForm = (
    <>
      <Form
        disabled={isViewModal}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          language_id: 1
        }}
      >
        {isViewModal ? (
          <div style={{ marginBottom: '22px' }}>
            <img src={customFormValues['icon']} alt='logo' height={52} width={52} />
          </div>
        ) : (
          <div className='form-group'>
            <Item name='icon'>
              <FileUpload
                uploadType='logo'
                mediaLink={
                  form.getFieldValue('icon') ? form.getFieldValue('icon') : customFormValues['icon']
                }
                placeholder='Upload Image'
                handleUpload={(img) => handleCustomFormChange(img, 'icon')}
              />
            </Item>
          </div>
        )}
        <div className='form-group'>
          <Item name='name' rules={[{ required: true, message: 'Speciality Name is required' }]}>
            <Input placeholder='Speciality Name' type='text' />
          </Item>
        </div>
        <ConfigProvider direction={`rtl`}>
          <div className='form-group'>
            <Item
              className='arabic'
              name='name_ar'
              rules={[{ required: true, message: `مطلوب اسم التخصص` }]}
            >
              <Input className='arabic' placeholder={`اسم التخصص`} type='text' />
            </Item>
          </div>
        </ConfigProvider>
        {isViewModal ? (
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' onClick={onClose} loading={loading}>
          //     Ok
          //   </Button>
          // </div>
          <></>
          // hello
        ) : (
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' loading={loading} htmlType='submit'>
              Submit
            </Button>
          </div>
        )}
      </Form>
        {isViewModal ? (
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' onClick={onClose} loading={loading}>
              Ok
            </Button>
          </div>
        ) : (
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' loading={loading} htmlType='submit'>
          //     Submit
          //   </Button>
          // </div>
          <></>
        )}
    </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={
        <h3 className='title'>
          {values?.id ? (isViewModal ? 'View Specialty' : 'Update Specialty') : 'Add Specialty'}
        </h3>
      }
    >
      {appointmentForm}
    </Modal>
  );
};

export default SpecialityAddUpdateForm;
