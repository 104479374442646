import { Card } from 'antd';
import React, { useEffect, useState } from 'react';

import * as charts from '../../../../../services/charts'
import ReactEcharts from 'echarts-for-react';
import { pieStyle } from '../chart-pattern-styles';
import Spinner from '../../../../../components/Spinner';
import { showErrorMessage } from '../../../../../utils/AppUtils';


const PatientsPieChart = () => {

    const getPieChart = (data) => {
        let chartOptions: any = {
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br>{c} ({d}%)'
            },
        }
        chartOptions.series = [
            {
                name: 'pie',
                type: 'pie',
                selectedMode: 'single',
                selectedOffset: 30,
                clockwise: true,
                radius: [0, '90%'],
                label: {
                    // normal: {
                        position: 'inner',
                        // textStyle: {
                            fontSize: 14,
                            fontWeight: 700,
                            color: '#000'
                        // }
                    // }
                },
                labelLine: {
                    // normal: {
                        lineStyle: {
                            color: '#14BFFF'
                        // }
                    }
                },
                data: data,
                itemStyle: pieStyle
            }
        ]
        return chartOptions
    };
    const [data, setData] = useState({})
    const [isloading, setIsloading] = useState(false)

    useEffect(() => {
        setIsloading(true)
        charts.getRevnue()?.then((data) => {
            setIsloading(false)
            if (data?.data?.data?.gender_count?.length) {
                setData(getPieChart(data?.data?.data?.gender_count))
            } else {
                setIsloading(false)
            }
        }).catch((error) => {
            showErrorMessage(error)
            setIsloading(false)
        })
    }, [])
    return (
        !isloading ?
            <Card title={'Patients'} >
                <ReactEcharts
                    // className='chart-container container-h-300'
                    option={data}
                />
            </Card> :
            <Spinner />

    );
};

export default PatientsPieChart;
