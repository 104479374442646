import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal, DatePicker, TimePicker, notification, Rate } from 'antd';
// import ImageLoader from './ImageLoader';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as reviews from '../../services/reviews';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import moment from 'moment';
import AsyncSearchSelect from '../../components/AsyncSearchSelect';

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const ReviewAddUpdateForm = ({ visible, onClose, values, getAllData }: Props) => {
  const { Item } = Form;
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([{ rating: 3 }]);
  const allUserTypes = ['doctor', 'laboratory', 'hospital', 'pharmacy'];
  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = { ...values };
      if (clonedValues.date) {
        clonedValues.date = moment(clonedValues.date);
      }
      form.setFieldsValue({
        ...clonedValues
      });
      // setCustomFormValues({
      //   ...clonedValues
      // });
    }
  }, [values]);
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    getAllData();
    setTimeout(() => {
      form.resetFields();
      onClose();
    }, 1000);
  };
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const data = await reviews.add(formValues);
      setLoading(false);
      if (data?.status === 200) {
        operationsAfterSubmit(data);
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      setLoading(false);
      showErrorMessage(e?.data);
    }
  };
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'ref_type') {
      form.resetFields(['ref_id']);
    }
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  const appointmentForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        ['rating']:customFormValues?.rating
      }}
    >
      <div className='form-group'>
        <Item
          name='ref_type'
          label='User type'
          rules={[{ required: true, message: 'User type  is required' }]}
        >
          <Select
            placeholder='User Type'
            onChange={(value) => handleCustomFormChange(value, 'ref_type')}
          >
            {allUserTypes?.map((type: any, i) => (
              <Select.Option key={`type-${type}-${i}`} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='ref_id'
          label='Select User'
          rules={[{ required: true, message: 'Please select the doctor' }]}
        >
          <AsyncSearchSelect
            placeholder='Search'
            url={`/users&`}
            dataObj={customFormValues?.ref_type}
            refType={customFormValues?.ref_type}
            defaultValue={
              values?.doctor && {
                label: values['ref_id']?.name,
                value: values['ref_id']?.id
              }
            }
            selectedValue={(item) => {
              form.setFieldsValue({ ref_id: item?.value });
              handleCustomFormChange(item?.value, 'ref_id');
            }}
          />
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='rating'
          label='Rating'
          rules={[{ required: true, message: 'Rating is required' }]}
        >
          <Rate
            tooltips={desc}
            style={{ fontSize: 26 }}
            allowClear={false}
            // defaultValue={customFormValues?.rating}
            // initialValues={{["value"]:customFormValues?.rating}}
            onChange={(value) => {
              handleCustomFormChange(value, 'rating');
              form.setFieldsValue({ rating: value });
            }}
            value={customFormValues?.rating}
          />
        </Item>
        {customFormValues?.rating ? (
          <span className='ant-rate-text'>{desc[customFormValues?.rating - 1]}</span>
        ) : (
          ''
        )}
      </div>
      <div className='form-group'>
        <Item
          name='comment'
          label='Comment'
          rules={[{ required: true, message: 'Comment is required' }]}
        >
          <Input placeholder='Comment' type='text' />
        </Item>
      </div>

      <div className='d-flex justify-content-between'>
        <Button onClick={onClose} type='default'>
          Close
        </Button>
        <Button block={false} type='primary' loading={loading} htmlType='submit'>
          Submit
        </Button>
      </div>
    </Form>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>Add Review</h3>}
    >
      {appointmentForm}
    </Modal>
  );
};

export default ReviewAddUpdateForm;
