import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Timeline, Card, Divider } from 'antd';

import { usePageData } from '../../hooks/usePage';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; //useHistory replace useNavigate
import * as patients from '../../services/patients';
import Spinner from '../../components/Spinner';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import PatientCaseStudyTable from './components/PatientCaseStudyTable';
import TablePageAddAction from '../../components/common/tables-components/TablePageAddAction';
import PatientCaseStudyForm from './components/PatientCaseStudyForm';
import TableActions from '../../components/common/tables-components/TableActions';

const getPageData = (id) => {
  const pageData: any = {
    title: 'Patient',
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Dashboard',
        route: 'dashboard'
      },
      {
        title: 'Patients',
        route: 'patients'
      },
      {
        title: id ? id : 'profile'
      }
    ]
  };
  return pageData;
};

const { Item } = Form;
const ProfileForm = ({ values }) => {
  const [form] = useForm();
  form.setFieldsValue({
    ...values
  });
  return (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      initialValues={values}
    >
      <div className='form-group'>
        <Item name='name' label='Patient Name'>
          <Input readOnly placeholder='Patient Name' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='age' label='Patient Age'>
          <Input readOnly placeholder='Age' type='number' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='resident_card_id' label='Resident Card Id'>
          <Input readOnly placeholder='Resident Card Number' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='patient_type' label='Patient Type'>
          <Input readOnly placeholder='Patient Type' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='gender' label='Gender'>
          <Select disabled placeholder='Gender'>
            <Select.Option value='male'>Male</Select.Option>
            <Select.Option value='female'>Female</Select.Option>
            <Select.Option value='other'>Other</Select.Option>
          </Select>
        </Item>
      </div>
      {values?.insurance && Object?.values(values?.insurance)?.length && (
        <>
          <Divider orientation='left' className='divider-legend'>
            Insurance Detail
          </Divider>
          <div className='form-group'>
            <Item name='status' label='Insurance Status'>
              <Select
                disabled
                placeholder='Status'
                //  defaultValue={values?.insurance?.status}
              >
                <Select.Option value={'active'}>Active</Select.Option>
                <Select.Option value={'inactive'}>InActive</Select.Option>
              </Select>
            </Item>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <Item name='code' label='Insurance Code'>
                  <Input
                    readOnly
                    // defaultValue={values?.insurance?.code}
                    placeholder='Insurance Code'
                    type='text'
                  />
                </Item>
              </div>
            </div>
            <div className='col-sm-6 col-12'>
              <div className='form-group'>
                <Item name='phone' label='Insurance Phone'>
                  <Input
                    readOnly
                    // defaultValue={values?.insurance?.phone}
                    placeholder='Insurance phone'
                    type='text'
                  />
                </Item>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <Item name='name' label='Insurance Address'>
              <Input
                readOnly
                // defaultValue={values?.insurance?.address}
                placeholder='Insurance address'
                type='text'
              />
            </Item>
          </div>
          {values?.policy_number && (
            <div className='form-group'>
              <Item name='policy_number' label='Policy Number'>
                <Input
                  // defaultValue={values?.policy_number}
                  readOnly
                  placeholder='Insurance policy_number'
                  type='number'
                />
              </Item>
            </div>
          )}
          <Divider />
        </>
      )}
      <div className='form-group'>
        <Item name='is_active' label='Patient Status'>
          <Select disabled placeholder='Status'>
            <Select.Option value={1}>Active</Select.Option>
            <Select.Option value={0}>InActive</Select.Option>
          </Select>
        </Item>
      </div>
    </Form>
  );
};

const PatientProfilePage = () => {
  const [values, setValues] = useState<any>({});
  const [isViewModal, setIsViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);

  const history: any = useNavigate(); //useHistory replace useNavigate

  const { id } = useParams<any>();

  usePageData(getPageData(id));

  /* Fetch fields data */
  useEffect(() => {
    /* Fetch data */

    if (id && id !== 'create') {
      fetchAndSetData();
    }
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    patients.getById(+id).then((res: any) => {
      if (res?.data?.data) {
        setValues(res?.data?.data);
      }
    });
  };
  const handleViewModal = (row) => {
    setSelectedRow(row);
    setIsViewModal(true);
    setModalVisibility(true);
  };
  const closeAddingModal = () => {
    setSelectedRow(null);
    setModalVisibility(false);
    setIsViewModal(false);
  };
  /* Case study table actions*/
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleViewModal,
      // handleDelete: handleDelete,
      // handleEdit: handleEditModal,
      handleAdd: openAddingModal,
      module: 'patients',
      operations: { list: true, update: false, remove: false, add: true }
    };
    return actionObj;
  };
  const openAddingModal = () => setModalVisibility(true);

  return Object?.keys(values)?.length ? (
    <>
      <div className='row mb-4'>
        <div className='col-md-6 col-sm-12'>
          <div className='info stack'>
            <ProfileForm values={values} />
            <div className='d-flex justify-content-between'>
              <Button onClick={() => history(-1)} type='default'>
                Go back
              </Button>
              <Button block={false} type='primary'>
                Ok
              </Button>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-sm-12'>
          <Card>
            <AppointmentTimeline values={values} />
          </Card>
        </div>
      </div>
      {/* Case Study Table */}
      <Card title='Patient Case Studies' className='mb-0'>
        <PatientCaseStudyTable
          data={values?.patient_case_study?.length ? values?.patient_case_study : []}
          pagination={false}
          // loading={loading}
          actions={(row) => TableActions(getObj(row))}
        />
      </Card>
      {/* {TablePageAddAction({ onAdd: openAddingModal, module: 'patients' })} */}
      {modalVisibility && (
        <PatientCaseStudyForm
          patient_id={values?.id}
          onClose={closeAddingModal}
          isViewModal={isViewModal}
          values={selectedRow ? selectedRow : {}}
          visible={modalVisibility}
        />
      )}
    </>
  ) : (
    <Spinner />
  );
};

const AppointmentTimeline = ({ values }) => {
  const history = useNavigate(); //useHistory replace useNAvigate
  const history1: any = useLocation(); //useHistory replace useNavigate
  let appointmentHistory = values?.appointments;

  const handlePrescription = (item) => {
    const layout = history1.pathname.split('/')[1]; //.location.pathname.split remove
    history(`/${layout}/appointments/${item?.id}`); //.push remove
  };
  return (
    <>
      <Timeline
        mode='left'
        items={[
          {
            children:
              appointmentHistory?.length &&
              appointmentHistory?.reverse()?.map((item, index) => {
                let status = item?.status?.toString();
                if (status === 'consulted') {
                  return (
                    // <Timeline.Item color='#17bd6a' key={`petient-profile-timeline-${index}`}>
                    <div className='d-flex flex-column '  key={`petient-profile-timeline-${index}`}>
                      <h4
                        className='m-0'
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => handlePrescription(item)}
                      >
                        Consulted
                      </h4>
                      <span className='text-base text-color-100'>
                        {moment(new Date(item?.created_at)).format('DD MMM YYYY')}
                      </span>
                      <span className='text-base'>
                        Dr. {item?.doctor?.fullName} on {item?.type}
                      </span>
                    </div>
                    // </Timeline.Item>
                  );
                }

                return (
                  // <Timeline.Item color='#ed5564'>
                  <div className='d-flex flex-column' key={`petient-profile-timeline-${index++}`}>
                    <h4 className='m-0'>
                      {status[0]?.toUpperCase() + status?.slice(1)?.toLowerCase()}
                    </h4>
                    <span className='text-base text-color-100'>
                      {moment(new Date(item?.created_at)).format('DD MMM YYYY')}
                    </span>
                    <span className='text-base'>
                      Dr. {item?.doctor?.fullName} on {item?.type}
                    </span>
                  </div>
                  // </Timeline.Item>
                );
              })
          }
        ]}
      />
      {/* </Timeline> */}
    </>
  );
};
export default PatientProfilePage;
