import React, { useEffect, useState } from 'react';

import { Button, Input, Modal } from 'antd';
// import ImageLoader from './ImageLoader';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as patient from '../../../services/patients';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  isViewModal?: boolean;
  patient_id: any;
};

const PatientCaseStudyForm = ({ visible, onClose, values, isViewModal, patient_id }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    setTimeout(() => {
      form.resetFields();
    }, 2000);
  };
  const handleSubmit = async (formValues: any) => {
    formValues.patient_id = patient_id;
    try {
      setLoading(true);
      const data = await patient.addPatientCaseStudy(formValues);
      setLoading(false);
      if (data?.status === 200) {
        operationsAfterSubmit(data);
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      setLoading(false);
      showErrorMessage(e?.data);
    }
  };
  const myForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='food_allergies'
              label='Food Allergies'
              rules={[{ required: true, message: 'food_allergies is required' }]}
            >
              <Input placeholder='food_allergies' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='tendency_bleed'
              label='Tendency Bleed'
              rules={[{ required: true, message: 'tendency_bleed is required' }]}
            >
              <Input placeholder='Tendency Bleed' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='heart_disease'
              label='Heart Disease'
              rules={[{ required: true, message: 'heart_disease is required' }]}
            >
              <Input placeholder='Heart Disease' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='high_blood_pressure'
              label='High Blood Pressure'
              rules={[{ required: true, message: 'high_blood_pressure is required' }]}
            >
              <Input placeholder='High Blood Pressure' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='diabetic'
              label='Diabetic'
              rules={[{ required: true, message: 'diabetic is required' }]}
            >
              <Input placeholder='Diabetic' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='surgery'
              label='Surgery'
              rules={[{ required: true, message: 'surgery is required' }]}
            >
              <Input placeholder='Surgery' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='accident'
              label='Accident'
              rules={[{ required: true, message: 'accident is required' }]}
            >
              <Input placeholder='Accident' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='family_medical_history'
              label='Family Medical History'
              rules={[{ required: true, message: 'family_medical_history is required' }]}
            >
              <Input placeholder='Family Medical History' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='current_medication'
              label='Current Medication'
              rules={[{ required: true, message: 'current_medication is required' }]}
            >
              <Input placeholder='Current Medication' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='female_pregnancy'
              label='Female Pregnancy'
              rules={[{ required: true, message: 'female_pregnancy is required' }]}
            >
              <Input placeholder='Female Pregnancy' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='breast_feeding'
              label='Breast Feeding'
              rules={[{ required: true, message: 'breast_feeding is required' }]}
            >
              <Input placeholder='Breast Feeding' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='health_insurance'
              label='Health Insurance'
              rules={[{ required: true, message: 'health_insurance is required' }]}
            >
              <Input placeholder='Health Insurance' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='low_income'
              label='Low Income'
              rules={[{ required: true, message: 'low_income is required' }]}
            >
              <Input placeholder='Low Income' type='text' />
            </Item>
          </div>
        </div>

        <div className='col-sm-6 col-12'>
          <div className='form-group'>
            <Item
              name='reference'
              label='Reference'
              rules={[{ required: true, message: 'reference is required' }]}
            >
              <Input placeholder='Reference' type='text' />
            </Item>
          </div>
        </div>
      </div>
      <div className='form-group'>
        <Item
          name='others'
          label='Others'
          rules={[{ required: true, message: 'others is required' }]}
        >
          <Input placeholder='Others' type='text' />
        </Item>
      </div>
      {Object?.values(values)?.length ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={() => onClose()} type='default'>
            Go back
          </Button>
          <Button onClick={() => onClose()} type='primary'>
            Ok
          </Button>
        </div>
      ) : (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
  );

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose
        footer={null}
        maskClosable={false}
        title={<h3 className='title'>Add Patient Case Study</h3>}
      >
        {myForm}
      </Modal>
    </>
  );
};

export default PatientCaseStudyForm;
