import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';
import AvatarImageDisplay from '../../../components/common/AvatarImageDisplay';
import CustomTag from '../../../components/common/CustomTag';

const columns: ColumnProps<any>[] = [
  {
    key: 'logo',
    title: 'Image',
    dataIndex: 'logo',
    width: 120,
    fixed: 'left',
    render: (img) => <AvatarImageDisplay img={img} />
  },
  {
    key: 'name',
    dataIndex: 'name',
    width: 150,
    title: 'Name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'hospital',
    dataIndex: 'hospital',
    title: 'hospital',
    sorter: (a, b) => (a.hospital > b.hospital ? 1 : -1),
    render: (hospital) => <strong>{hospital?.name}</strong>
  },
  {
    key: 'city',
    dataIndex: 'city',
    title: 'City',
    sorter: (a, b) => (a.city > b.city ? 1 : -1),
    render: (city) => <strong>{city?.name}</strong>
  },
  {
    key: 'labTest',
    dataIndex: 'labTest',
    title: 'Lab Tests',
    render: (labTest) => {
      let formateText = '';
      labTest?.length &&
        labTest?.map((test, index) => {
          if (test?.test?.name) {
            formateText = formateText + test?.test?.name + ","
          }
        })
      return (
        <strong >
          {formateText}
        </strong >
      );
    }
    // labTest?.length &&
    // labTest?.map((test, index) => {
    //   return (
    //     <div style={{ marginBottom: 5 }}>
    //       <CustomTag type={test?.test?.name} />
    //     </div>
    //   );
    // })
  },
  // {
  //   key: 'address',
  //   dataIndex: 'address',
  //   title: 'Address',
  //   width: 300,
  //   render: (address) => (
  //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
  //       {address?.slice(0, 45)+"..."}
  //     </span>
  //   )
  // },
  {
    key: 'is_active',
    dataIndex: 'is_active',
    title: 'Status',
    render: (is_active) => (
      <Tag style={{ backgroundColor: is_active === 1 ? '#17bd6a' : '#ed5564' }}>
        {is_active === 1 ? 'Active' : 'In-Active'}
      </Tag>
    )
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const LaboratoriesTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 200,
    // fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      // scroll={{ x: 1500 }}
      onChange={handleTableChange}
    />
  );
};

export default LaboratoriesTable;
