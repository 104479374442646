import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export const getAll = async (params = {}) => {
  try {
    const url = '/users' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export async function getById(id: number) {
  try {
    const url = '/users/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const add = async (requestBody) => {
  try {
    const res = await api.post('/register', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put(`/users/${id}`, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updatePassword = async (requestBody) => {
  try {
    const res = await api.post('/change-password', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete(`/users/${id}`);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
