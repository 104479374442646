import { Upload, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { mediaUpload } from '../../services/Api';
import { getToken } from '../../utils/StorageService';

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const UploadGallery = ({ handleUpload, placeholder, imagesList }: any) => {
  const initialState = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: []
  };
  useEffect(() => {
    if (imagesList?.length) {
      setState({ fileList: imagesList });
    }
  }, [imagesList]);
  const [state, setState] = useState<any>(initialState);

  const handleCancel = () => setState((prevState) => ({ ...prevState, previewVisible: false }));

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState((prevState) => ({
      ...prevState,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    }));
  };

  const { previewVisible, previewImage, fileList, previewTitle } = state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{placeholder ? placeholder : 'Upload'}</div>
    </div>
  );
  /* Formate image object */
  const formateImageResponse = (file) => {
    let formatedImage: any = {};
    if (file?.status === 'error') {
      if (!state?.fileList?.includes(file?.uid)) {
        notification.error({ message: file?.response?.error[0]?.message });
      }
      return '';
    } else if (file?.status === 'removed') {
      return '';
    } else if (file?.status === 'done') {
      formatedImage.url = file?.response?.url;
    } else if (file?.url) {
      formatedImage.url = file?.url;
    }
    formatedImage.name = file?.name;
    formatedImage.uid = file?.uid;
    return formatedImage;
  };
  /* All images list */
  const formateMediaListResponse = (fileList) => {
    const uploadedImages = [];
    fileList.map((item) => {
      const image = formateImageResponse(item);
      if (image !== '') {
        uploadedImages.push(image);
      }
    });
    return [...uploadedImages];
  };
  const attachFile: any = {
    name: 'file',
    action: mediaUpload,
    headers: {
      Authorization: `bearer ` + JSON.parse(getToken())
    },
    method: 'POST',
    data: {
      content_type: 'image'
    },
    onChange(info: any) {
      const imageListResponse = formateMediaListResponse(info?.fileList);
      setState({ fileList: imageListResponse });
      handleUpload(imageListResponse);
    }
  };
  return (
    <>
      <Upload
        listType='picture-card'
        multiple={true}
        fileList={fileList}
        onPreview={handlePreview}
        {...attachFile}
        // onChange={handleChange}
        withCredentials={true}
      >
        {fileList?.length >= 4 ? null : uploadButton}
      </Upload>
      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadGallery;
