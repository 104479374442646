import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input, Modal, notification } from 'antd';
// import ImageLoader from './ImageLoader';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as labTest from '../../../services/labTests';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const LabTestAddUpdateForm = ({ visible, onClose, isViewModal, values, getAllData }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (values?.id) {
        const data = await labTest.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 2000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await labTest.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  const customForm = (
    <>
      <Form
        disabled={isViewModal}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          is_active: 1
        }}
      >
        <div className='form-group'>
          <Item name='name' rules={[{ required: true, message: 'Institute Name is required' }]}>
            <Input placeholder='Institute Name' type='text' />
          </Item>
        </div>
        <ConfigProvider direction={`rtl`}>
          <div className='form-group'>
            <Item
              className='arabic'
              name='name_ar'
              rules={[{ required: true, message: `مطلوب اسم ` }]}
            >
              <Input className='arabic' placeholder={`اسم`} type='text' />
            </Item>
          </div>
        </ConfigProvider>
        {isViewModal ? (
          <></>
        ) : (
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' onClick={onClose} loading={loading}>
          //     Ok
          //   </Button>
          // </div>
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' loading={loading} htmlType='submit'>
              Submit
            </Button>
          </div>
        )}
      </Form>
      {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <></>
        // <div className='d-flex justify-content-between'>
        //   <Button onClick={onClose} type='default'>
        //     Close
        //   </Button>
        //   <Button block={false} type='primary' loading={loading} htmlType='submit'>
        //     Submit
        //   </Button>
        // </div>
      )}
    </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={
        <h3 className='title'>
          {values?.id ? (isViewModal ? 'View Test' : 'Update Test') : 'Add Test'}
        </h3>
      }
    >
      {customForm}
    </Modal>
  );
};

export default LabTestAddUpdateForm;
