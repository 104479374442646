import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { setPageData, updatePageData } from '../redux/page-data/actions';

import { IPageData } from '../interfaces/page';
import api from '../services/Api';
import { showErrorMessage } from '../utils/AppUtils';

export function usePageData(pageData: IPageData) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageData({ ...pageData, loaded: true }));
  }, [pageData, dispatch]);
}
export function useOnlyPageData(pageData: IPageData) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageData({ ...pageData, loaded: true,fulFilled: true }));
  }, [pageData, dispatch]);
}

export function useFetchPageData<T>(
  url: string,
  initialState: T = null,
  callback?: (T) => any
): [T, (data: T) => void] {
  const [data, setData] = useState<any>(initialState);
  const dispatch = useDispatch();

  async function getData() {
    const result = await api.get(url);
    return result.data;
  }

  useEffect(() => {
    getData()
      .then((data) => {
        if (callback) {
          callback(data);
        }

        setTimeout(() => dispatch(updatePageData({ fulFilled: true, loaded: true })), 300);

        setData(data);
      })
      .catch((error) => {
        showErrorMessage(error?.data);
        setTimeout(() => dispatch(updatePageData({ fulFilled: true, loaded: true })), 300);
      });
  }, [url, dispatch]);
  return [data?.data, setData];
}
export function useFetchDetailsData<T>(
  url: string,
  initialState: T = null,
  callback?: (T) => any
): [T, (data: T) => void] {
  const [data, setData] = useState<any>(initialState);
  const dispatch = useDispatch();

  async function getData() {
    const result = await api.get(url);
    return result.data;
  }

  useEffect(() => {
    getData()
      .then((data) => {
        if (callback) {
          callback(data);
        }

        setTimeout(() => dispatch(updatePageData({ fulFilled: true, loaded: true })), 300);

        setData(data);
      })
      .catch((error) => {
        showErrorMessage(error?.data);
        setTimeout(() => dispatch(updatePageData({ fulFilled: true, loaded: true })), 300);
      });
  }, [url, dispatch]);
  return [data?.data, setData];
}
