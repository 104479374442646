import React, { useEffect, useState } from 'react';

import { Button, Select, Form, Input, Modal, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as permissions from '../../../../services/permissions';
import { showErrorMessage, showSuccessMessage } from '../../../../utils/AppUtils';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const ServiceFeeForm = ({ visible, onClose, values, getAllData, isViewModal }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  const handleCustomFormChange = (value, formName) => {

    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (values?.id) {
        const data: any = await permissions.updateServiceFeeSettings(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();

          onClose();
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await permissions.addServiceFeeSettings(formValues);
        setLoading(false);
        if (data?.status === 200) {
          notification.success({
            message: 'Service added successfully !'
          });
          getAllData();
          setTimeout(() => {
            form.resetFields();
            // onClose();
          }, 2000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  const myForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        is_active: 1
      }}
    >
      {/* <div className='row'>
        <div className='col-md-6 col-sm-12'> */}
      <div className='form-group'>
        <Item name='name' label='Name' rules={[{ required: true, message: 'name is required' }]}>
          <Input placeholder='Name' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item name='category' label='Category'>
          <Select placeholder='Category'>
            <Select.Option value='commision'>Commision</Select.Option>
            <Select.Option value='markup'>Markup</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='form-group'>
        <Item name='type' label='Type'>
          <Select placeholder='type' onChange={(value) => handleCustomFormChange(value, 'type')}>
            <Select.Option value='percentage'>Percentage</Select.Option>
            <Select.Option value='fixed'>Fixed Amount</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='amount'
          label={customFormValues?.type === "percentage" ? "Percentage Value" : "Amount (OMR)"}
          rules={[{ required: true, message: 'amount is required' }]}
        >
          <Input placeholder={customFormValues?.type === "percentage" ? "Percentage Value" : "Amount (OMR)"} type='number' />
        </Item>
      </div>

      {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? 'Update Service Fee' : 'Add Service Fee'}</h3>}
    >
      {myForm}
    </Modal>
  );
};

export default ServiceFeeForm;
