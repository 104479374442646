import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export const getAll = async (params = {}) => {
  try {
    const url = '/services' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/services', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/services/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/services/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
