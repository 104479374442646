import React, { useEffect, useState } from 'react';

import { Form, Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import * as permissions from '../../../services/permissions';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';

const { Item } = Form;

const ChatAndVideoSettings = () => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [customFormValues, setCustomFormValues] = useState<any>([]);

    /* Fetch fields data */
    useEffect(() => {
        fetchAndSetData();
    }, []);
    /* Fetch data by id and set values */
    const fetchAndSetData = async () => {
        const agoraData = await permissions.getAgoraSettings();
        const chatData = await permissions.getChatSettings();

        setCustomFormValues({
            ...customFormValues, AGORA_ACCESS_KEY: agoraData?.data?.data?.[0]?.value,
            STREAM_CHAT_ACCESS_KEY: chatData?.data?.data?.[0]?.value
        });
        form.setFieldsValue({
            AGORA_ACCESS_KEY: agoraData?.data?.data?.[0]?.value,
            STREAM_CHAT_ACCESS_KEY: chatData?.data?.data?.[0]?.value
        });
    };
    const handleCustomFormChange = (value, formName) => {
        setCustomFormValues({ ...customFormValues, [formName]: value });
    };
    /* performing operations after success */
    const operationsAfterSubmit = (res) => {
        showSuccessMessage(res);
    };
    const handleSubmit = async (formValues: any) => {
        console.log("#formValues : ", formValues)
        try {
            setLoading(true);
            const data = await permissions.updateChatSettings(customFormValues);
            console.log("#data: ", data)
            if (data?.status === 200) {
                setLoading(false);
                operationsAfterSubmit(data);
            } else if (data && data?.status !== 200) {
                showErrorMessage(data);
                setLoading(false);
            }
        } catch (e: any) {
            showErrorMessage(e.data);
        }
    };

    return (
        <Form
            form={form}
            requiredMark
            layout='vertical'
            scrollToFirstError
            className='mb-4'
            onFinish={handleSubmit}
            initialValues={{
                is_active: 1
            }}
        >
            <div className='row'>
                <div className='col-md-6 col-sm-12'>

                    <div className='form-group'>
                        <Item
                            name='AGORA_ACCESS_KEY'
                            label='AGORA ACCESS KEY (Video)'
                            rules={[{ required: true, message: 'AGORA_ACCESS_KEY is required' }]}
                        >
                            <Input.Password
                                onChange={(e) => handleCustomFormChange(e.target.value, 'AGORA_ACCESS_KEY')}
                                placeholder='AGORA ACCESS KEY'
                                type='text'
                                // defaultValue={customFormValues?.AGORA_ACCESS_KEY?.replace(/\d(?=\d{4})/g, '*')}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Item>
                    </div>
                    <div className='form-group'>
                        <Item
                            name='STREAM_CHAT_ACCESS_KEY'
                            label='STREAM CHAT ACCESS KEY (Chat)'
                            rules={[{ required: true, message: 'STREAM_CHAT_ACCESS_KEY is required' }]}
                        >
                            <Input.Password
                                onChange={(e) => handleCustomFormChange(e.target.value, 'STREAM_CHAT_ACCESS_KEY')}
                                placeholder='STREAM CHAT ACCESS KEY'
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                // defaultValue={customFormValues?.STREAM_CHAT_ACCESS_KEY?.replace(/\d(?=\d{4})/g, '*')}
                                type='text'
                            />
                        </Item>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Button
                            type='primary'
                            onClick={handleSubmit}
                            disabled={loading}
                            block={false}
                            htmlType='submit'
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default ChatAndVideoSettings;
