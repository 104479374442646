import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal, Divider, notification } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as users from '../../services/users';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { getUserType } from '../../utils/StorageService';
import { forgetPassword } from '../../services/login';
import { useParams, useNavigate } from 'react-router-dom';
import { IPageData } from '../../interfaces/page';
import { usePageData } from '../../hooks/usePage';

const { Item } = Form;

const pageData: IPageData = {
    title: 'User',
    fulFilled: true,
    breadcrumbs: [
        {
            title: 'Dashboard',
            route: 'dashboard'
        },
        {
            title: 'User'
        }
    ]
};
const UserUpdateProfile = (props) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [passwordForm, setPasswordForm] = useState(false);
    const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
    const [customFormValues, setCustomFormValues] = useState<any>({ status: "active" });
    const allUserTypes = ['doctor', 'patient', 'laboratory', 'hospital', 'admin', 'pharmacy'];
    const userType = getUserType()
    usePageData(pageData);
    const { id } = useParams<any>();
    const history: any = useNavigate();

    /* set form values in edit mode */
    /* Fetch data by id and set values */
    const fetchAndSetData = () => {
        users.getById(+id).then((res: any) => {
            if (res?.data?.data) {
                form.setFieldsValue({
                    ...res?.data?.data
                });
                setCustomFormValues(res?.data?.data);
            }
        });
    };
    /* Fetch fields data */
    useEffect(() => {
        /* Fetch fields data */
        let isMount =true

        if (id && id !== 'create' && isMount) {
            fetchAndSetData();
        }
        return ()=>{isMount= false}
    }, []);
    /* handle reset password */
    const handleResetPassword = () => {
        setPasswordForm(true)
    };
    /* performing operations after success */
    const handleUpdatePassword = async () => {
        let formatedObj: any = {
            userId: customFormValues?.id,
            user_id: customFormValues?.id,
            password: form.getFieldValue("password"),
            new_password: form.getFieldValue("new_password"),
            confirmed_password: form.getFieldValue("confirmed_password")
        }
        try {
            if (formatedObj?.password?.length > 6 && formatedObj?.new_password?.length > 6
                && formatedObj?.confirmed_password?.length > 6) {

                setUpdatePasswordLoading(true);
                let data = await users.updatePassword(formatedObj);
                setUpdatePasswordLoading(false);
                if (data?.status === 200) {
                    showSuccessMessage(data)
                } else {
                    showErrorMessage(data);
                    setUpdatePasswordLoading(false);
                }
            } else {
                notification.warning({
                    message: "Please fill the password fields !"
                })
            }
        } catch (e: any) {
            showErrorMessage(e.data);
            setUpdatePasswordLoading(false);
        }
    };
    const handleCustomFormChange = (value, formName) => {
        setCustomFormValues({ ...customFormValues, [formName]: value });
    };
    /* performing operations after success */
    const formateFormBeforeSubmit = (formValues) => {
        let formatedObj: any = {
            userId: customFormValues?.id,
            user_id: customFormValues?.id,
        }
        formatedObj.status = customFormValues.status;
        formatedObj.name = formValues.name;
        formatedObj.phone = formValues.phone;
        console.log("#formatedObj : ", formatedObj)
        return formatedObj
    };
    /* performing operations after success */
    const operationsAfterSubmit = (res) => {
        showSuccessMessage(res);
    };
    /* handle submit update user */
    const handleSubmit = async (formValues: any) => {
        try {
            setLoading(true);
            const formValuesFormate = formateFormBeforeSubmit(formValues);

            if (id && id !== "create") {
                const data = await users.update(formValuesFormate, id);
                setLoading(false);
                if (data?.status === 200) {
                    operationsAfterSubmit(data);
                } else {
                    showErrorMessage(data);
                }
            }
        } catch (e: any) {
            showErrorMessage(e?.data);
            setLoading(false);
        }
    };
    const PasswordChangeForm = (
        <>
            <Form.Item
                name="password"
                label="Current Password"
                rules={[
                    {
                        pattern: /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
                        message: 'Password must be between 8 to 20 characters, contain at least one special char and capital letter.'
                    }
                ]}
                hasFeedback
            >
                <Input.Password placeholder="Password" />
            </Form.Item>
            <div className='row'>
                <div className='col-md-6 col-sm-12'>
                    <Form.Item
                        name="new_password"
                        label="New Password"
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'Please input your password!',
                            // },
                            {
                                pattern: /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
                                message: 'Password must be between 8 to 20 characters, contain at least one special char and capital letter.'
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                </div>
                <div className='col-md-6 col-sm-12'>

                    <Form.Item
                        name="confirmed_password"
                        label="Confirm Password"
                        dependencies={['new_password']}
                        hasFeedback
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'Please confirm your password!',
                            // },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                </div>
            </div>

            <div className='d-flex justify-content-end'>
                <Button
                    block={false}
                    type='link'
                    loading={updatePasswordLoading}
                    style={{ color: "#14BFFF" }}
                    className='custom-border-primary color-primary tranparent'
                    onClick={handleUpdatePassword}
                >
                    Update Password
                </Button>
            </div>
        </>
    )
    const userForm = (
      <Form
            form={form}
            requiredMark
            layout='vertical'
            scrollToFirstError
            className='mb-4'
            onFinish={handleSubmit}
            initialValues={{
                status: "active"
            }}
        >
            <div className='row'>
                <div className='col-md-6 col-sm-12'>

                    <Item name='email' label="Email" rules={[{ required: true, message: 'Email is required' }]}>
                        <Input placeholder='Email' type='email' readOnly />
                    </Item>
                    <div className='form-group'>
                        <Item name='user_type' label="User Type" rules={[{ required: true, message: 'User type is required' }]}>
                            <Select placeholder='User type' open={false}>
                                {allUserTypes?.map((type: any, i) => (
                                    <Select.Option key={`type-${type}-${i}`} value={type}>
                                        {type}{' '}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Item>
                    </div>
                    <Item name='name' label="Name" rules={[{ required: true, message: 'name is required' }]}>
                        <Input placeholder='Name' type='name' />
                    </Item>
                    <div className='form-group'>
                        <Item name='phone'>
                            <PhoneInputWithCountrySelect
                                defaultCountry='OM'
                                placeholder='Phone Number'
                                international
                                style={{ borderRadius: 20, height: 40, padding: 5 }}
                                value={customFormValues?.phone}
                                onChange={(value) => handleCustomFormChange(value, 'phone')}
                            />
                        </Item>
                    </div>
                    <div className='form-group'>
                        <Item name='status'>
                            <Select value={customFormValues?.status}
                                disabled placeholder='Status' onChange={(value) => handleCustomFormChange(value, 'status')} >
                                <Select.Option value="active">Approved</Select.Option>
                                <Select.Option value="inactive">Blocked</Select.Option>
                            </Select>
                        </Item>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Button
                            block={false}
                            type='link'
                            style={{ color: "#14BFFF" }}
                            className='custom-border-primary color-primary tranparent'
                            onClick={handleResetPassword}
                        >
                            Reset Password
                        </Button>
                    </div>
                    {passwordForm ? PasswordChangeForm : null}
                    <Divider />
                    <div className='d-flex justify-content-between'>
                        <Button onClick={() => history('/vertical/dashboard')} type='default'>
                            Close
                        </Button>
                        <Button
                            block={false}
                            type='primary'
                            loading={loading}
                            icon={<span className={`icofont icofont-edit mr-1`} style={{ fontSize: '1.2rem' }} />}
                            htmlType='submit'
                        >
                            Update User
                        </Button>
                    </div>
                </div>
            </div>

        </Form>
    );
    return userForm
};

export default UserUpdateProfile;
