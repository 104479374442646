// Alert

import { notification } from 'antd';

export const getErrorMessage = (res: any) => {
  if (res?.error?.length) {
    return res?.error[0]?.message;
  } else {
    return res?.message;
  }
};

const formateErrorsArray = (errors: any) => {
  let formatedMessage = '';
  if (errors?.length) {
    errors?.map((item) => {
      if (item?.message) {
        formatedMessage += `\n ${item?.field}, ${item?.message} ;`;
      }
      return formatedMessage;
    });
  }
  return formatedMessage;
};
export const showErrorMessage = (data: any) => {
  if (data?.error?.length) {
    notification.error({
      message: 'Error while processing the request !',
      description: formateErrorsArray(data?.error),
      placement: "topRight"
    });
  } else {
    notification.error({
      message: 'Error while processing the request !',
      description: data?.message,
      placement: "topRight"
    });
  }
};
export const showSuccessMessage = (data: any) => {
  if (data?.data?.message) {
    notification.success({
      message: data?.data?.message,
      placement: "topRight"
    });
  } else if (data?.message) {
    notification.success({
      message: data?.message,
      placement: "topRight"
    });
  } else {
    notification.success({
      message: "Success !",
      placement: "topRight"
    });
  }
};
