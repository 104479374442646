
import React, { useEffect, useState } from 'react';

import { Button, DatePicker, Input, InputNumber, Modal, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as appointments from '../../../services/appointments';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  // getAllData: () => void;
  isEditModal?: boolean;
  doctor_id: number;
  appointment_id: number;
};

const PrescriptionForm = ({ visible, onClose, values, isEditModal, doctor_id, appointment_id }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  // const { id: appointment_id } = useParams<any>();

  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && isEditModal) {
      const formatedValues = values?.map((item) => formateObj(item, 'edit'));

      let formatedPrescriptions = {
        prescriptions: formatedValues
      }
      const clonedValues = Object.assign({}, formatedPrescriptions);
      console.log("#formatedPrescriptions : ", formatedPrescriptions)
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  const handleCustomFormChange = (value, formName, key?) => {
    if (key) {
      if (formName === 'starts' || formName === 'ends') {
        value = moment(new Date(value)).format('YYYY-MM-DD');
      }
      const formArrayElement = { ...customFormValues[key] };
      formArrayElement[formName] = value;
      customFormValues[key] = { ...formArrayElement };
      setCustomFormValues(customFormValues);
    }
  };
  /* Formate form object */
  const formateObj = (prescription: any, formate?) => {
    const { medicine_name, starts, ends, dosage, quantity, dosage_unit, repeats, when, message } = prescription;
    let finalObj: any = {
      medicine_name,
      starts: formate === 'edit' ? moment(starts) : moment(new Date(starts)).format('YYYY-MM-DD'),
      ends: formate === 'edit' ? moment(ends) : moment(new Date(ends)).format('YYYY-MM-DD'),
      dosage,
      quantity,
      dosage_unit,
      repeats,
      when,
      message
    };
    if (prescription?.id) {
      finalObj.id = prescription?.id;
    }
    return finalObj;
  };
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    const formatedValues = formValues?.prescriptions?.map((item) => formateObj(item));
    const dataObj = {
      prescriptions: formatedValues,
      doctor_id: doctor_id,
      appointment_id: Number(appointment_id)
    };
    try {
      setLoading(true);
      if (isEditModal) {
        const data = await appointments.updatePrescription(dataObj);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data)
          setTimeout(() => {
            form.resetFields();
            // onClose();
          }, 2000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await appointments.addPrescription(dataObj);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data)
          setTimeout(() => {
            form.resetFields();
            // onClose();
          }, 2000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  const AddUpdateForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <>
        <Form.List name='prescriptions'>
          {(fields, { add, remove, ...rest }) => (
            <>

              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key}>

                  <div className='form-group'>
                    <Item
                      name={[name, 'medicine_name']} label="Medicine Name" rules={[{ required: true, message: 'medicine_name is required' }]}>
                      <Input
                        // defaultValue={values?.patient?.medicine_name}
                        placeholder='Medicine Name'
                        type='text'
                        onChange={(e) => handleCustomFormChange(e?.target?.value, 'medicine_name', key)}
                      />
                    </Item>
                  </div>
                  <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                      <div className='form-group'>
                        {/* <Item name={[name, 'dosage']} label="Dosage" rules={[{ required: true, message: 'dosage is required' }]}>
                          <Input
                            // defaultValue={values?.patient?.dosage}
                            placeholder='Dosage e.g 500'
                            type='text'
                            onChange={(e) => handleCustomFormChange(e?.target?.value, 'dosage', key)}
                          />
                        </Item> */}
                        <Item name={[name, 'dosage']} label="Dosage" rules={[{ required: true, message: 'dosage is required' }]}>
                          <InputNumber style={{ width: "100%" }} placeholder="e.g 500" min={1} onChange={(value) => handleCustomFormChange(value, 'dosage', key)} />
                        </Item>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                      <div className='form-group'>
                        <Item name={[name, 'quantity']} label="Quantity" rules={[{ required: true, message: 'quantity is required' }]}>
                          <InputNumber min={1} style={{ width: "100%" }} onChange={(value) => handleCustomFormChange(value, 'quantity', key)} />
                        </Item>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                      <div className='form-group'>
                        <Item name={[name, 'dosage_unit']} label="Dosage Unit" rules={[{ required: true, message: 'dosage_unit is required' }]}>
                          <Select
                            placeholder='Dosage Unit'
                            onChange={(value) => handleCustomFormChange(value, 'dosage_unit', key)}
                          >
                            <Select.Option value='mg'>mg</Select.Option>
                            <Select.Option value='mL'>mL</Select.Option>
                            <Select.Option value='tablespoon'>tablespoon</Select.Option>
                          </Select>
                        </Item>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <Item name={[name, 'repeats']} label="Repeats" rules={[{ required: true, message: 'repeats is required' }]}>
                          <Select
                            placeholder='repeats'
                            onChange={(value) => handleCustomFormChange(value, 'repeats', key)}
                          >
                            <Select.Option value='once_day'>once_day</Select.Option>
                            <Select.Option value='multiple_times'>multiple_times</Select.Option>
                          </Select>
                        </Item>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <Item name={[name, 'when']} label="When" rules={[{ required: true, message: 'when is required' }]}>
                          <Select
                            placeholder='when'
                            onChange={(value) => handleCustomFormChange(value, 'when', key)}
                          >
                            <Select.Option value='before_food'>before_food</Select.Option>
                            <Select.Option value='after_food'>after_food</Select.Option>
                          </Select>
                        </Item>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <Item name={[name, 'starts']} label="starts" rules={[{ required: true, message: 'starts is required' }]}>
                          <DatePicker
                            // disabled={
                            //   !customFormValues['starts'] || !customFormValues['type'] ? true : false
                            // }
                            disabledDate={(current) => current && current < moment().startOf('day')}
                            placeholder='start'
                            onChange={(date, dateString) => handleCustomFormChange(date, 'starts', key)}
                            size='large'
                            style={{ width: '100%', borderRadius: 30 }}
                          />
                        </Item>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <Item name={[name, 'ends']} label="ends" rules={[{ required: true, message: 'ends is required' }]}>
                          <DatePicker
                            // disabled={
                            //   !customFormValues['ends'] || !customFormValues['type'] ? true : false
                            // }
                            disabledDate={(current) => current && current < moment().startOf('day')}
                            placeholder='ends'
                            onChange={(date, dateString) => handleCustomFormChange(date, 'ends', key)}
                            size='large'
                            style={{ width: '100%', borderRadius: 30 }}
                          />
                        </Item>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <Item
                      name={[name, 'message']} label="Message" rules={[{ required: true, message: 'message is required' }]}>
                      <TextArea rows={3} placeholder='Message /Comments' />
                    </Item>
                  </div>
                  <Button
                    type='text'
                    onClick={() => remove(name)}
                    // disabled={id === 'create' ? false : true}
                    icon={<MinusCircleOutlined />}
                  />
                  {/* <MinusCircleOutlined
                      onClick={() => remove(name)}
                      disabled={id === 'create' ? false : true}
                    /> */}
                </div>
              ))}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Medicine
                </Button>
              </Form.Item>

            </>
          )}
        </Form.List>
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      </>
    </Form >
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? 'Update Prescription' : 'Add Prescription'}</h3>}
    >
      {AddUpdateForm}
    </Modal>
  );
};

export default PrescriptionForm;
