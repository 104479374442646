import React, { useEffect } from 'react';
import { Form, Button, DatePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import AsyncSearchSelect from '../../../components/AsyncSearchSelect';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getUserType } from '../../../utils/StorageService';
import dayjs from 'dayjs';
const { Item } = Form;

const AddQualification = ({ values, id, history, isView, ...props }) => {
  const [form] = useForm();
  const [customFormValues, setCustomFormValues] = useState<any>([]);

  /* Fetch fields data */
  useEffect(() => {
    if (values && id !== 'create') {
      const clonedValues = [...values];
      const formatedValues = clonedValues?.map((item) => formateQualificationObj(item, 'edit'));
      form.setFieldsValue({
        qualifications: formatedValues
      });
      const formatedValuesToSet = clonedValues?.map((item) => formateQualificationObj(item));

      setCustomFormValues([...formatedValuesToSet]);
    }
  }, [values]);

  const handleCustomFormChange = (value, formName, key) => {
    if (formName === 'year') {
      value = moment(new Date(value)).format('YYYY');
    }
    const formArrayElement = { ...customFormValues[key] };
    formArrayElement[formName] = value;
    customFormValues[key] = { ...formArrayElement };
    setCustomFormValues(customFormValues);
  };
  // const dateFormat1 = 'YYYY';
  const formateQualificationObj = (qualificationObj: any, formate?) => {
    const { city_id, institute_id, qualification_id, specialization_id, year, id } =
      qualificationObj;
    let finalObj: any = {
      city_id,
      institute_id,
      qualification_id,
      specialization_id,
      year: formate === 'edit' ? moment(year, 'YYYY-MM-DD') : year
    };
    if (id) {
      finalObj['id'] = id;
    }
    return finalObj;
  };
  const handleSubmit = (formValues: any) => {
    const formatedValues = customFormValues?.map((item) => formateQualificationObj(item));
    const dataObj = {
      qualifications: formatedValues
    };
    props.handleOnSubmitForm(dataObj);
  };
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <Form
      disabled={ViewPageDisable()}
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <Form.List name='qualifications'>
        {(fields, { add, remove, ...rest }) => (
          <>
            {/* {form.setFieldsValue({ hospital_id: 2 })} */}
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <React.Fragment key={key}>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'qualification_id']}
                            fieldKey={[fieldKey, 'qualification_id']}
                            rules={[
                              {
                                required: !customFormValues[key]?.qualification_id ? true : false,
                                message: 'Please select the qualification'
                              }
                            ]}
                          >
                            <AsyncSearchSelect
                              placeholder='Search to select qualification'
                              defaultValue={
                                values[key]?.qualification && {
                                  label: values[key]?.qualification?.title,
                                  value: values[key]?.qualification?.id
                                }
                              }
                              url={`/qualifications?`}
                              selectedValue={(item) => {
                                form.setFieldsValue({ qualification_id: item?.value });
                                handleCustomFormChange(item?.value, 'qualification_id', key);
                              }}
                            />
                          </Item>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'city_id']}
                            fieldKey={[fieldKey, 'city_id']}
                            rules={[
                              {
                                required: !customFormValues[key]?.city_id ? true : false,
                                message: 'Please select the city'
                              }
                            ]}
                          >
                            <AsyncSearchSelect
                              placeholq
                              r='Search City'
                              defaultValue={
                                values[key]?.city && {
                                  label: values[key]?.city?.name,
                                  value: values[key]?.city?.id
                                }
                              }
                              url={`/cities?`}
                              selectedValue={(item) => {
                                form.setFieldsValue({ city_id: item?.value });
                                handleCustomFormChange(item?.value, 'city_id', key);
                              }}
                            />
                          </Item>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'specialization_id']}
                            fieldKey={[fieldKey, 'specialization_id']}
                            rules={[
                              {
                                required: !customFormValues[key]?.specialization_id ? true : false,
                                message: 'Please select the specialization'
                              }
                            ]}
                          >
                            <AsyncSearchSelect
                              placeholder='Search specialization'
                              defaultValue={
                                values[key]?.specialization && {
                                  label: values[key]?.specialization?.title,
                                  value: values[key]?.specialization?.id
                                }
                              }
                              url={`/specializations?`}
                              selectedValue={(item) => {
                                form.setFieldsValue({ specialization_id: item?.value });
                                handleCustomFormChange(item?.value, 'specialization_id', key);
                              }}
                            />
                          </Item>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'specialization']}
                            fieldKey={[fieldKey, 'institute_id']}
                            rules={[
                              {
                                required: !customFormValues[key]?.institute_id ? true : false,
                                message: 'Please select the institute'
                              }
                            ]}
                          >
                            <AsyncSearchSelect
                              placeholder='Search institute'
                              defaultValue={
                                values[key]?.institute && {
                                  label: values[key]?.institute?.name,
                                  value: values[key]?.institute?.id
                                }
                              }
                              url={`/institutes?`}
                              selectedValue={(item) => {
                                form.setFieldsValue({ institute_id: item?.value });
                                handleCustomFormChange(item?.value, 'institute_id', key);
                              }}
                            />
                          </Item>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <Item
                        fieldKey={[fieldKey, 'year']}
                        rules={[
                          {
                            required: !customFormValues[key]?.year ? true : false,
                            message: 'Please select the year'
                          }
                        ]}
                      >
                        {/* defaultValue={dayjs((values[0]?.year).toString()  , 'YYYY')} */}
                        {/* {JSON.stringify(dayjs("2000","YYYY"))}
                        {JSON.stringify(dayjs("2000"))}
                        {typeof(values[0]?.year)} */}
                        <DatePicker
                          //@ts-ignore
                          defaultValue={
                            values[0]?.year
                              ? dayjs((values[0]?.year).toString())
                              : dayjs(new Date())
                          }
                          // format={'YYYY'}
                          style={{ width: '100%' }}
                          onChange={(date, dateString) => handleCustomFormChange(date, 'year', key)}
                          picker='year'
                        />
                      </Item>
                    </div>
                    <Button
                      style={{ backgroundColor: 'white', border: 'none' }}
                      type='text'
                      onClick={() => remove(name)}
                      disabled={id ? false : true}
                      icon={<MinusCircleOutlined style={{ color: 'black' }} />}
                    />
                    {/* <MinusCircleOutlined
                      onClick={() => remove(name)}
                      disabled={id === 'create' ? false : true}
                    /> */}
                  </React.Fragment>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Add qualification
                  </Button>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Form.List>
      {!isView && (
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex justify-content-between'>
              <Button
                onClick={() =>
                  getUserType() !== 'admin' ? history.push('/vertical/dashboard') : history(-1)
                }
                type='default'
              >
                Cancel
              </Button>
              <Button type='primary' block={false} disabled={props.loading} htmlType='submit'>
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

export default AddQualification;
