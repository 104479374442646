import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Divider } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as pharmacy from '../../../services/pharmacies';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import moment from 'moment';
import AsyncSearchSelect from '../../../components/AsyncSearchSelect';
import Spinner from '../../../components/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import { getUserType, updateUserInStorage } from '../../../utils/StorageService';

const { Item } = Form;
const { TextArea } = Input;

const pageData: IPageData = {
  title: 'Pharmacy Branch',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Pharmacy Branch'
    }
  ]
};
const PharmacyBranchAddUpdateForm = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const history: any = useNavigate();
  const { id } = useParams<any>();
  const isView = localStorage.getItem('isViewPharmacyBranch');
  /* getting new_user property to check doctor user signin first time with no profile */
  // const new_user = localStorage.getItem('new_user');
  usePageData(pageData);
  /* Fetch fields data */
  useEffect(() => {
    /* Fetch fields data */

    if (id && id !== 'create') {
      fetchAndSetData();
    }
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    console.log(pharmacy,"ppp======");
    
    pharmacy.getPharmacyBranchById(+id).then((res: any) => {
      console.log(res?.data?.data,"==========0===");
      
      if (res?.data?.data) {
        setValues(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    let isMount = true;
    // if(isMount){

    if (Object?.keys(values)?.length && id !== 'create' && isMount) {
      const clonedValues = Object.assign({}, values);

      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues(clonedValues);
      // }
    }
    return () => {
      isMount = false;
      console.log('hello mount');
    };
  }, [values]);
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'country_id') {
      form.resetFields(['state_id', 'city_id']);
    } else if (formName === 'state_id') {
      form.resetFields(['city_id']);
    }
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  /* performing operations before hitting api */
  const formateFormBeforeSubmit = (formValues: any) => {
    if (values.user_id) {
      formValues.userId = values.user_id;
      formValues.user_id = values.user_id;
    }
    formValues.lat = formValues?.lat?.toString();
    formValues.longitude = formValues?.longitude?.toString();
    formValues.logo = customFormValues?.logo;

    return formValues;
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    setTimeout(() => {
      if (getUserType() !== 'admin') {
        history.replace('/vertical/dashboard');
      } else {
        history(-1);
      }
    }, 1000);
  };
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const formValuesFormate = formateFormBeforeSubmit(formValues);
      if (id && id !== 'create') {
        const res = await pharmacy.updatePharmacyBranch(formValuesFormate, id);
        console.log(res, 'res');

        setLoading(false);
        if (res?.status === 200) {
          operationsAfterSubmit(res);
        } else {
          showErrorMessage(res);
        }
      } else {
        const res = await pharmacy.addPharmacyBranch(formValuesFormate);
        setLoading(false);
        if (res?.status === 200) {
          operationsAfterSubmit(res);
        } else {
          showErrorMessage(res);
        }
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  if (id && id !== 'create' && !Object?.values(values)?.length) {
    return <Spinner />;
  }
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <>
      <Form
        disabled={ViewPageDisable()}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          is_active: 1
        }}
      >
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <Item
                name='pharmacy_id'
                label='Pharmacy'
                rules={[{ required: true, message: 'Please select the pharmacy' }]}
              >
                <AsyncSearchSelect
                  placeholder='Search Pharmacy'
                  url='/pharmacies?'
                  // dataObj='pharmacy'
                  defaultValue={
                    values?.user && {
                      label: values['pharmacy']?.name,
                      value: values['pharmacy']?.id
                    }
                  }
                  selectedValue={(item) => form.setFieldsValue({ pharmacy_id: item?.value })}
                />
              </Item>
            </div>
            <div className='form-group'>
              <Item
                name='name'
                label='Branch Name'
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input placeholder='Branch Name' type='text' />
              </Item>
            </div>
            <div className='form-group'>
              <Item
                name='code'
                label='Branch Code'
                rules={[{ required: true, message: 'code is required' }]}
              >
                <Input placeholder='Branch Code' type='text' />
              </Item>
            </div>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Item
                    name='country_id'
                    label='Country'
                    rules={[{ required: true, message: 'Country is required' }]}
                  >
                    <AsyncSearchSelect
                      placeholder='Search country'
                      url={`/countries?`}
                      defaultValue={
                        values['country'] && {
                          label: values['country']?.name,
                          value: values['country']?.id
                        }
                      }
                      selectedValue={(item) => {
                        form.setFieldsValue({ country_id: item?.value });
                        handleCustomFormChange(item?.value, 'country_id');
                      }}
                    />
                  </Item>
                </div>
              </div>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Item
                    name='state_id'
                    label='State'
                    rules={[{ required: true, message: 'State is required' }]}
                  >
                    <AsyncSearchSelect
                      value={form.getFieldValue('state_id')}
                      placeholder='Search state'
                      defaultValue={
                        values['state'] && {
                          label: values['state']?.name,
                          value: values['state']?.id
                        }
                      }
                      url={`/states?country_id=${customFormValues['country_id']}&`}
                      selectedValue={(item) => {
                        form.setFieldsValue({ state_id: item?.value });
                        handleCustomFormChange(item?.value, 'state_id');
                      }}
                    />
                  </Item>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <Item
                name='city_id'
                label='City'
                rules={[{ required: true, message: 'City is required' }]}
              >
                <AsyncSearchSelect
                  placeholder='Search City'
                  defaultValue={
                    values['city'] && {
                      label: values['city']?.name,
                      value: values['city']?.id
                    }
                  }
                  url={`/cities?state_id=${customFormValues['state_id']}&`}
                  selectedValue={(item) => form.setFieldsValue({ city_id: item?.value })}
                />
              </Item>
            </div>
            <div className='form-group'>
              <Item
                name='address'
                label='Address'
                rules={[
                  {
                    required: true,
                    message: 'address is required'
                  }
                ]}
              >
                <TextArea
                  rows={3}
                  value={customFormValues?.address}
                  onChange={(e) => handleCustomFormChange(e.target.value, 'address')}
                  placeholder='Address'
                />
              </Item>
            </div>
            {/* <div className='form-group'>
            <Item name='is_active' label="Status">
              <Select placeholder='Status'>
                <Select.Option value={1}>Active</Select.Option>
                <Select.Option value={0}>InActive</Select.Option>
              </Select>
            </Item>
          </div> */}
            {isView ? (
              <></>
            ) : (
              // <div className='d-flex justify-content-between'>
              //   <Button onClick={() => history(-1)} type='default'>
              //     Go back
              //   </Button>
              //   <Button block={false} onClick={() => history(-1)} type='primary'>
              //     Ok
              //   </Button>
              // </div>
              <div className='d-flex justify-content-between'>
                <Button
                  onClick={() =>
                    getUserType() !== 'admin' ? history('/vertical/dashboard') : history(-1)
                  }
                  type='default'
                >
                  Cancel
                </Button>
                <Button
                  block={false}
                  type='primary'
                  disabled={loading}
                  // loading={loading}
                  htmlType='submit'
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
      <div style={{ width: '530px' }}>
        {isView ? (
          <div className='d-flex justify-content-between'>
            <Button onClick={() => history(-1)} type='default'>
              Go back
            </Button>
            <Button block={false} onClick={() => history(-1)} type='primary'>
              Ok
            </Button>
          </div>
        ) : (
          <></>
          // <div className='d-flex justify-content-between'>
          //   <Button
          //     onClick={() =>
          //       getUserType() !== 'admin' ? history('/vertical/dashboard') : history(-1)
          //     }
          //     type='default'
          //   >
          //     Cancel
          //   </Button>
          //   <Button
          //     block={false}
          //     type='primary'
          //     disabled={loading}
          //     // loading={loading}
          //     htmlType='submit'
          //   >
          //     Submit
          //   </Button>
          // </div>
        )}
      </div>
    </>
  );
};
export default PharmacyBranchAddUpdateForm;
