import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal, notification, ConfigProvider } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as states from '../../../services/states';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import api from '../../../services/Api';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const StateAddUpdateForm = ({ visible, onClose, values, isViewModal, getAllData }: Props) => {
  const [form] = useForm();
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>({});

  /* Fetch fields data */
  useEffect(() => {
    getCountries();
  }, []);
  /* Handle country change */
  const getCountries = () => {
    api.get(`/countries?per_page=100`).then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setAllCountries(res?.data?.data?.data);
      }
    });
  };
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (values?.id) {
        const data = await states.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await states.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  const AddUpdateForm = (
    <>
      <Form
        disabled={isViewModal}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          language_id: 1
        }}
      >
        <div className='form-group'>
          <Item name='name' rules={[{ required: true, message: 'Governates Name is required' }]}>
            <Input placeholder='Governates Name' type='text' />
          </Item>
        </div>
        <ConfigProvider direction={`rtl`}>
          <div className='form-group'>
            <Item
              className='arabic'
              name='name_ar'
              rules={[{ required: true, message: `مطلوب اسم المحافظاتم` }]}
            >
              <Input className='arabic' placeholder={`اسم المحافظاتم`} type='text' />
            </Item>
          </div>
        </ConfigProvider>
        <div className='form-group'>
          <Item
            name='country_id'
            rules={[{ required: true, message: 'Please select the country' }]}
          >
            <Select placeholder='Select Country'>
              {allCountries?.length &&
                allCountries?.map((item: any, i) => (
                  <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
        </div>
        <div className='form-group'>
          <Item name='state_code' rules={[{ required: true, message: 'state_code is required' }]}>
            <Input placeholder='State Code' type='text' />
          </Item>
        </div>

        {isViewModal ? (
          <></>
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' onClick={onClose} loading={loading}>
          //     Ok
          //   </Button>
          // </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' loading={loading} htmlType='submit'>
              Submit
            </Button>
          </div>
        )}
      </Form>
      {isViewModal ? (
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' onClick={onClose} loading={loading}>
              Ok
            </Button>
          </div>
        ) : (
          <></>
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' loading={loading} htmlType='submit'>
          //     Submit
          //   </Button>
          // </div>
        )}
    </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={
        <h3 className='title'>
          {values?.id ? (isViewModal ? 'View Governate' : 'Update Governate') : 'Add Governate'}
        </h3>
      }
    >
      {AddUpdateForm}
    </Modal>
  );
};

export default StateAddUpdateForm;
