import { getPageReferenceParam, getTimeSoltsReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/doctors/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params) => {
  try {
    const url = '/doctors' + getPageReferenceParam(params);
    const res = await api.get(url);
    // console.log(res);
    
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/doctors', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/doctors/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/users/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Get available doctor time slots  */
export const getDoctorTimeSlots = async ({ doctor_id, ...params }) => {
  try {
    const url = `doctors/time-slots/${doctor_id}${getTimeSoltsReferenceParam(params)}`;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Doctor/remove qualification  */
export const addQualification = async (requestBody) => {
  try {
    const res = await api.post('/doctors/qualification', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateQualification = async (requestBody, id) => {
  try {
    const res = await api.put('/doctors/qualification/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removeQualification = async (id) => {
  try {
    const res = await api.delete('/doctors/qualification', id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Doctor/remove experience  */
export const addExperience = async (requestBody) => {
  try {
    const res = await api.post('/doctors/experience', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateExperience = async (requestBody, id) => {
  try {
    const res = await api.put('/doctors/experience/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removeExperience = async (id) => {
  try {
    const res = await api.delete('/doctors/experience', id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Doctor/remove awards  */
export const addAward = async (requestBody) => {
  try {
    const res = await api.post('doctors/award', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateAward = async (requestBody, id) => {
  try {
    const res = await api.put('doctors/award/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removeAward = async (id) => {
  try {
    const res = await api.delete('doctors/award', id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Doctor/remove physical shifts  */
export const addPhysicalShifts = async (requestBody) => {
  try {
    const res = await api.post('doctors/shifts/physical', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updatePhysicalShifts = async (requestBody, id) => {
  try {
    const res = await api.put('doctors/shifts/physical/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removePhysicalShifts = async (id) => {
  try {
    const res = await api.delete('doctors/shifts/physical', id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Doctor/remove virtual shifts  */
export const addVirtualShifts = async (requestBody) => {
  try {
    const res = await api.post('doctors/shifts/virtual', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateVirtualShifts = async (requestBody, id) => {
  try {
    const res = await api.put('doctors/shifts/virtual/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removeVirtualShifts = async (id) => {
  try {
    const res = await api.delete('doctors/shifts/virtual', id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};


// /* Doctor/remove visit shifts  */
export const addVisitShifts = async (requestBody) => {
  try {
    const res = await api.post('doctors/shifts/visit', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateVisitShifts = async (requestBody, id) => {
  try {
    const res = await api.put('doctors/shifts/visit/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
// export const removeVisitShifts = async (id) => {
//   try {
//     const res = await api.delete('doctors/shifts/visit', id);
//     return res;
//   } catch (err: any) {
//     return err.data;
//   }
// };
