import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { getPermissions, isAuthenticated } from '../utils/StorageService';

export default function requireAuthentication(Component, path?) {
  const AuthenticatedComponent = () => {
    const userAuthenticated = isAuthenticated();
    const allowedPaths = [
      'dashboard',
      'doctors-update',
      'hospitals-update',
      'laboratories-update',
      'pharmacies-update',
      'allowed',
    ];
    let isAuthrized = false;
    if (allowedPaths.includes(path)) {
      isAuthrized = true;
    } else if (path) {
      isAuthrized = checkIsAuthrized(path);
    }
    useEffect(() => {
      checkAuth(userAuthenticated);
    }, [userAuthenticated]);

    const checkAuth = (userAuthenticated) => {
      if (!userAuthenticated) {
        localStorage.clear();
        window.location.href = '/';
      }
    };
    if (userAuthenticated && isAuthrized) {
      return <Component />;
    } else if (userAuthenticated && !isAuthrized) {
      return <Navigate to='/public/404' />;
    } else {
      return <Navigate to='/public/sign-in' />;
    }
  };

  return AuthenticatedComponent;
}
const checkIsAuthrized = (path) => {
  const permissions = JSON.parse(getPermissions());
  const modulePermissions = permissions?.[path] || {};

  return modulePermissions?.list ? true : false;
};
