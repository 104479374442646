import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input, Modal, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as institutes from '../../../services/institutes';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import AsyncSearchSelect from '../../../components/AsyncSearchSelect';
import api from '../../../services/Api';

const { Item } = Form;
type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const InstituteAddUpdateForm = ({ visible, onClose, values, isViewModal, getAllData }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [customFormValues, setCustomFormValues] = useState<any>({});

  /* Fetch fields data */
  useEffect(() => {
    let isMount = true;

    api.get('/languages').then((res: any) => {
      if (res?.data?.data?.length && isMount) {
        setLanguages(res?.data?.data);
      }
    });
    return () => {
      isMount = false;
    };
  }, []);
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'language_id') {
      form.resetFields(['name']);
    }
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  /* Fetch fields data */
  useEffect(() => {
    let isMount = true;
    const { id } = values;
    if (values && id && isMount) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
    return () => {
      isMount = false;
    };
  }, [values]);
  const handleSubmit = async (formValues: any) => {
    formValues.userId = formValues.user_id;
    try {
      setLoading(true);
      if (values?.id) {
        const data = await institutes.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await institutes.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          getAllData();
          showSuccessMessage(data);
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  const AddUpdateForm = (
    <>
      <Form
        disabled={isViewModal}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          language_id: 1
        }}
      >
        <div className='form-group'>
          <Item name='name' rules={[{ required: true, message: 'Institute Name is required' }]}>
            <Input placeholder='Institute Name' type='text' />
          </Item>
        </div>
        <ConfigProvider direction={`rtl`}>
          <div className='form-group'>
            <Item
              className='arabic'
              name='name_ar'
              rules={[{ required: true, message: `مطلوب اسم ` }]}
            >
              <Input className='arabic' placeholder={`اسم`} type='text' />
            </Item>
          </div>
        </ConfigProvider>
        <div className='form-group'>
          <Item name='city_id' rules={[{ required: true, message: 'Please select the patient' }]}>
            <AsyncSearchSelect
              placeholder='Search City'
              initialValues={
                values['city'] && {
                  label: values['city']?.name,
                  value: values['city']?.id
                }
              }
              url={`/cities?`}
              selectedValue={(item) => form.setFieldsValue({ city_id: item?.value })}
            />
          </Item>
        </div>
        {isViewModal ? (
          <></>
        ) : (
          // <div className='d-flex justify-content-between'>
          //   <Button onClick={onClose} type='default'>
          //     Close
          //   </Button>
          //   <Button block={false} type='primary' onClick={onClose} loading={loading}>
          //     Ok
          //   </Button>
          // </div>
          <div className='d-flex justify-content-between'>
            <Button onClick={onClose} type='default'>
              Close
            </Button>
            <Button block={false} type='primary' loading={loading} htmlType='submit'>
              Submit
            </Button>
          </div>
        )}
      </Form>
      {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={
        <h3 className='title'>
          {values?.id ? (isViewModal ? 'View Institute' : 'Update Institute') : 'Add Institute'}
        </h3>
      }
    >
      {AddUpdateForm}
    </Modal>
  );
};

export default InstituteAddUpdateForm;
