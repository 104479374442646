import React, { useEffect, useState } from 'react';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import * as permissions from '../../../../services/permissions';
import PaymentStagingSettings from './PaymentStagingSettings';

import PaymentProductionSettings from './PaymentProductionSettings';

const PaymentSettings = () => {
  const [allPaymentKeys, setAllPaymentKeys] = useState<any>('');

  /* Fetch fields data */
  useEffect(() => {
    fetchAndSetData();
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    permissions.getPaymentSettings().then((res: any) => {
      let formatedObj = {};
      if (res?.data?.data.length) {
        res?.data?.data?.map((item) => (formatedObj[item.key] = item.value));
        setAllPaymentKeys(formatedObj);
      }
    });
  };
  // const { TabPane } = Tabs;
  const callback = (key) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Payment Staging Testing',
      children: <PaymentStagingSettings allPaymentKeys={allPaymentKeys} />
    },
    {
      key: '2',
      label: 'Payment Production Settings',
      children: <PaymentProductionSettings allPaymentKeys={allPaymentKeys} />
    }
  ];
  return (
    <Tabs defaultActiveKey='1' items={items} onChange={callback} />

    //  {/* <TabPane tab='Payment Staging Testing' key='1'>
    //  <PaymentStagingSettings allPaymentKeys={allPaymentKeys} />
    // </TabPane>
    // <TabPane tab='Payment Production Settings' key='2'>
    // <PaymentProductionSettings allPaymentKeys={allPaymentKeys} />
    // </TabPane>
    // </Tabs> */}
  );
};

export default PaymentSettings;

// import React, { useEffect, useState } from 'react';

// import { Button, Input, Select } from 'antd';
// import { Form } from 'antd';
// import { useForm } from 'antd/es/form/Form';
// import * as permissions from '../../../services/permissions';
// import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';

// const { Item } = Form;

// const PaymentSettings = () => {
//   const [form] = useForm();
//   const [loading, setLoading] = useState(false);
//   const [customFormValues, setCustomFormValues] = useState<any>([]);

//   /* Fetch fields data */
//   useEffect(() => {
//     fetchAndSetData();
//   }, []);
//   /* Fetch data by id and set values */
//   const fetchAndSetData = () => {
//     permissions.getPaymentSettings().then((res: any) => {
//       let formatedObj = {};
//       if (res?.data?.data.length) {
//         res?.data?.data?.map((item) => (formatedObj[item.key] = item.value));
//         form.setFieldsValue({
//           ...formatedObj
//         });
//       }
//     });
//   };
//   const handleCustomFormChange = (value, formName) => {
//     setCustomFormValues({ ...customFormValues, [formName]: value });
//   };
//   /* performing operations after success */
//   const operationsAfterSubmit = (res) => {
//     showSuccessMessage(res);
//   };
//   const handleSubmit = async (formValues: any) => {
//     try {
//       setLoading(true);
//       const data = await permissions.updatePaymentSettings(formValues);
//       setLoading(false);
//       if (data?.status === 200) {
//         operationsAfterSubmit(data);
//       } else {
//         showErrorMessage(data);
//       }
//     } catch (e: any) {
//       showErrorMessage(e.data);
//       setLoading(false);
//     }
//   };
//   return (
//     <Form
//       form={form}
//       requiredMark
//       layout='vertical'
//       scrollToFirstError
//       className='mb-4'
//       onFinish={handleSubmit}
//       initialValues={{
//         is_active: 1
//       }}
//     >
//       <div className='row'>
//         <div className='col-md-6 col-sm-12'>
//           <div className='form-group'>
//             <Item
//               name='PAYMENT_SUCCESS'
//               label='PAYMENT SUCCESS'
//               rules={[{ required: true, message: 'PAYMENT_SUCCESS is required' }]}
//             >
//               <Input
//                 onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_SUCCESS')}
//                 placeholder='PAYMENT SUCCESS'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='PAYMENT_CANCEL'
//               label='PAYMENT CANCEL'
//               rules={[{ required: true, message: 'PAYMENT_CANCEL is required' }]}
//             >
//               <Input
//                 onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_CANCEL')}
//                 placeholder='PAYMENT CANCEL'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='THAWANI_URL_STAGING'
//               label='THAWANI URL STAGING'
//               rules={[{ required: true, message: 'THAWANI_URL_STAGING is required' }]}
//             >
//               <Input
//                 onChange={(e) => handleCustomFormChange(e.target.value, 'THAWANI_URL_STAGING')}
//                 placeholder='THAWANI URL STAGING'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='THAWANI_URL_PRODUCTION'
//               label='THAWANI URL PRODUCTION'
//               rules={[{ required: true, message: 'THAWANI_URL_PRODUCTION is required' }]}
//             >
//               <Input
//                 onChange={(e) => handleCustomFormChange(e.target.value, 'THAWANI_URL_PRODUCTION')}
//                 placeholder='THAWANI URL PRODUCTION'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='THAWANI_SECRET_KEY_STAGING'
//               label='THAWANI SECRET KEY STAGING'
//               rules={[{ required: true, message: 'THAWANI_SECRET_KEY_STAGING is required' }]}
//             >
//               <Input
//                 onChange={(e) =>
//                   handleCustomFormChange(e.target.value, 'THAWANI_SECRET_KEY_STAGING')
//                 }
//                 placeholder='THAWANI SECRET KEY STAGING'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='THAWANI_SECRET_KEY_PRODUCTION'
//               label='THAWANI SECRET KEY PRODUCTION'
//               rules={[{ required: true, message: 'THAWANI_SECRET_KEY_PRODUCTION is required' }]}
//             >
//               <Input
//                 onChange={(e) =>
//                   handleCustomFormChange(e.target.value, 'THAWANI_SECRET_KEY_PRODUCTION')
//                 }
//                 placeholder='THAWANI SECRET KEY PRODUCTION'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='THAWANI_PUBLISHABLE_KEY_STAGING'
//               label='THAWANI PUBLISHABLE KEY STAGING'
//               rules={[{ required: true, message: 'THAWANI_PUBLISHABLE_KEY_STAGING is required' }]}
//             >
//               <Input
//                 onChange={(e) =>
//                   handleCustomFormChange(e.target.value, 'THAWANI_PUBLISHABLE_KEY_STAGING')
//                 }
//                 placeholder='THAWANI PUBLISHABLE KEY STAGING'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='THAWANI_PUBLISHABLE_KEY_PRODUCTION'
//               label='THAWANI PUBLISHABLE KEY PRODUCTION'
//               rules={[
//                 { required: true, message: 'THAWANI_PUBLISHABLE_KEY_PRODUCTION is required' }
//               ]}
//             >
//               <Input
//                 onChange={(e) =>
//                   handleCustomFormChange(e.target.value, 'THAWANI_PUBLISHABLE_KEY_PRODUCTION')
//                 }
//                 placeholder='THAWANI PUBLISHABLE KEY PRODUCTION'
//                 type='text'
//               />
//             </Item>
//           </div>

//           <div className='form-group'>
//             <Item
//               name='PAYMENT_CHECKOUT_STAGING'
//               label='PAYMENT CHECKOUT STAGING'
//               rules={[{ required: true, message: 'PAYMENT_CHECKOUT_STAGING is required' }]}
//             >
//               <Input
//                 onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_CHECKOUT_STAGING')}
//                 placeholder='PAYMENT CHECKOUT STAGING'
//                 type='text'
//               />
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='PAYMENT_MODE'
//               label='PAYMENT MODE'
//               rules={[{ required: true, message: 'PAYMENT_MODE is required' }]}
//             >
//               <Select
//                 onChange={(value) => handleCustomFormChange(value, 'PAYMENT_MODE')}
//                 placeholder='PAYMENT MODE'
//               >
//                 <Select.Option value='testing'>Testing</Select.Option>
//                 <Select.Option value='live'>Live</Select.Option>
//               </Select>
//             </Item>
//           </div>
//           <div className='form-group'>
//             <Item
//               name='PAYMENT_CHECKOUT_PRODUCTION'
//               label='PAYMENT CHECKOUT PRODUCTION'
//               rules={[{ required: true, message: 'PAYMENT_CHECKOUT_PRODUCTION is required' }]}
//             >
//               <Input
//                 onChange={(e) =>
//                   handleCustomFormChange(e.target.value, 'PAYMENT_CHECKOUT_PRODUCTION')
//                 }
//                 placeholder='PAYMENT CHECKOUT PRODUCTION'
//                 type='text'
//               />
//             </Item>
//           </div>
//           {/* <div className='form-group'>
//             <Item name='language_id' rules={[{ required: true, message: 'language is required' }]}>
//               <Select placeholder='language fee currency '>
//                 <Select.Option value='testing'>Testing</Select.Option>
//                 <Select.Option value='live'>Live</Select.Option>
//               </Select>
//             </Item>
//           </div> */}
//           <div className='d-flex justify-content-start'>
//             <Button
//               block={false}
//               type='primary'
//               disabled={loading}
//               // loading={loading}
//               htmlType='submit'
//             >
//               Submit
//             </Button>
//           </div>
//         </div>
//       </div>
//     </Form>
//   );
// };

// export default PaymentSettings;
