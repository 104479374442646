// location use ki hai kyun ky path name nai catch ho raha tha  

import React from 'react';
import { NavLink,useLocation } from 'react-router-dom';

// import { Location } from 'history';
import { IMenuItemIcon, IMenuItemSub } from '../../../interfaces/main-menu';

import { motion } from 'framer-motion';

import className from '../../../utils/class-names';

const isHorizontal = (layout: string) => window.innerWidth >= 992 && layout === 'horizontal';

type Props = {
  sub: IMenuItemSub[];
  title: string;
  icon: IMenuItemIcon;
  onClick?: (slug: string) => void;
  location?: Location;
  layout?: string;
  opened?: boolean;
  is_active?: number;
};

const container = {
  hidden: { opacity: 0, height: 0 },
  show: {
    opacity: 1,
    height: 'auto'
  }
};
const ItemWithSub = ({ location, is_active, title, icon, layout, sub, opened, onClick }: Props) => {
  const history= useLocation()
  const subItemClass = (slug: string) =>
    className({
      'menu-item': true,
      active: slug === history.pathname.split('/')[2]
    });

  const itemSub = sub.map((item: any, i: number) =>
    item.is_active ? (
      <li className={subItemClass(item.slug)} key={`item-sub${i}`}>
        <NavLink
          to={`/${item.layout || layout}/${item.slug}`}
          className='item-link'
         // activeClassName='active'
          replace
        >
          {item?.icon && (
            <span
              className={`link-icon ${item?.icon}`}
            // style={{ backgroundColor: item?.icon?.bg, color: item?.icon?.color }}
            />
          )}
          <span className='link-text' style={{ paddingLeft: 15 }}>
            {item.title}
          </span>
        </NavLink>
      </li>
    ) : null
  );

  const handleOnClick = () => {
    onClick(title);
  };

  return is_active === 1 ? (
    <li className={`menu-item has-sub ${opened ? 'active' : ''}`} onClick={handleOnClick}>
      <span className='item-link'>
        {icon && (
          <span
            className={`link-icon ${icon}`}
          // style={{ backgroundColor: icon.bg, color: icon.color }}
          />
        )}
        <span className='link-text'>{title}</span>
        <span className='link-caret icofont icofont-thin-right' />
      </span>
      {isHorizontal(layout) ? (
        <ul className='sub' onClick={(e) => e.stopPropagation()}>
          {itemSub}
        </ul>
      ) : (
        <motion.div
          initial='hidden'
          variants={container}
          animate={opened ? 'show' : 'hidden'}
          transition={{ type: 'tween', duration: 0.3 }}
          onClick={(e) => e.stopPropagation()}
        >
          <ul className='sub'>{itemSub}</ul>
        </motion.div>
      )}
    </li>
  ) : null;
};

export default ItemWithSub;
