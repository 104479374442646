import { Button, Popconfirm } from 'antd';
import { getPermissions } from '../../../utils/StorageService';

const TableActions = ({
  row,
  handleView,
  handleEdit,
  handleDelete,
  module,
  operations,
  handleAdd
}: any) => {
  const permissions = JSON.parse(getPermissions());
  const modulePermissions = permissions?.[module] || {};
  return (
    <div className='buttons-list nowrap'>
      {modulePermissions?.['list'] && operations['list'] ? (
        <Button onClick={() => handleView(row)} size="small" shape='circle' className='custom-border tranparent'>
          <span className='icofont icofont-eye-alt color-black' />
        </Button>
      ) : null}
      {modulePermissions?.['update'] && operations['update'] ? (
        <Button onClick={() => handleEdit(row)} shape='circle'size="small" type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      ) : null}
      {modulePermissions?.['remove'] && operations['remove'] ? (
        <Popconfirm
          title='Are you sure remove?'
          onConfirm={() => handleDelete(row)}
          key={`popup${row?.id}`}
        >
          <Button shape='circle' size="small" danger>
            <span className='icofont icofont-ui-delete' />
          </Button>
        </Popconfirm>
      ) : null}
      {modulePermissions?.['add'] && operations['add'] ? (
        <Button onClick={() => handleAdd(row)} shape='circle' type='primary'>
          <span className='icofont icofont-plus' />
        </Button>
      ) : null}
    </div>
  );
};
export default TableActions;
