import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import PageAction from '../../layout/components/page-action/PageAction';
import { useFetchPageData, usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import * as patient from '../../services/patients';
import PatientAddUpdateForm from './patientAddUpdateForm';
import PatientsTable from './PatientsTable';
import { initialPageRef } from '../../utils/constants';
import SearchData from '../../layout/components/search/SearchData';
import { useNavigate ,useLocation} from 'react-router';           //useHistory replace useNavigate
import TableActions from '../../components/common/tables-components/TableActions';
import TablePageAddAction from '../../components/common/tables-components/TablePageAddAction';

const pageData: IPageData = {
  title: 'Patients',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Patients'
    }
  ]
};

const PatientsPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/patients', []);

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);
  const history = useNavigate();          //useHistory replace useNavigate
  const history1 = useLocation();          //useLocation for pathname

  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setLoading(true);
    const res: any = await patient.getAll(params);
    setLoading(false);
    setTableData(res.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };

  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta.current_page ? meta.current_page : pageRef.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };

  const openAddingModal = () => setModalVisibility(true);

  const closeAddingModal = () => {
    setSelectedRow(null);
    setModalVisibility(false);
  };

  const handleEditModal = (row) => {
    setSelectedRow(row);
    setModalVisibility(true);
  };
  const handleViewPage = (row) => {
    const layout = history1.pathname.split('/')[1];   //.location.pathname.split    remove 
    history(`/${layout}/patients/${row?.id}`);          // .push  remove
  };

  const actions = (row) => (
    <div className='buttons-list nowrap '>
      <Button
        onClick={handleViewPage.bind({}, row)}
        shape='circle'
        type='primary'
        className='custom-border tranparent'
      >
        <span className='icofont icofont-eye-alt color-black' />
      </Button>
      <Button onClick={handleEditModal.bind({}, row)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
    </div>
  );
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleViewPage,
      handleDelete: null,
      handleEdit: handleEditModal,
      module: 'patients',
      operations: { list: true, update: true, remove: false }
    };
    return actionObj;
  };
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <PatientsTable
        data={tableData?.data}
        actions={(row) => TableActions(getObj(row))}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      <PageAction onClick={openAddingModal} icon='icofont-plus' type={'primary'} />
      {TablePageAddAction({ onAdd: openAddingModal, module: 'patients' })}

      {/* Add update form appointment */}
      {modalVisibility && (
        <PatientAddUpdateForm
          onClose={closeAddingModal}
          visible={modalVisibility}
          values={selectedRow ? selectedRow : {}}
          getAllData={getAllData}
        />
      )}
    </>
  );
};

export default PatientsPage;
