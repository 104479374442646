import React, { useEffect } from 'react';
import { Form, Button, Select, TimePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import api from '../../../services/Api';

const { Item } = Form;

const AddVisitTimings = ({ values, id, history, isView, ...props }) => {
  const [form] = useForm();
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  const [daysList, setDaysList] = useState([]);
  const [shifts, setShifts] = useState([]);
  const morningShift = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const eveningShift = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  useEffect(() => {
    /* Fetch fields data */
    api.get('/days').then((res: any) => {
      if (res?.data?.data?.length) {
        console.log(res, "days");
        
        setDaysList(res?.data?.data);
      }
    });
    api.get('/shifts').then((res: any) => {
      console.log(res, "shifts");
      if (res?.data?.data?.length) {
        setShifts(res?.data?.data);
      }
    });
  }, []);
  /* Fetch fields data in edit mode*/
  useEffect(() => {
    if (values && id !== 'create') {
      const clonedValues = [...values];
      const formatedValuesForm = clonedValues?.map((item) =>
        formateShiftObjToPopolateData(item, 'formateTime')
      );
      form.setFieldsValue({
        shifts: formatedValuesForm.flat()
      });
      /* Setting for form submission values */
      const formatedValuesToSet = clonedValues?.map((item) =>
        formateShiftObjToPopolateData(item, 'notFormate')
      );
      setCustomFormValues([...formatedValuesToSet.flat()]);
    }
  }, [values]);

  const formateShiftObjToPopolateData = (shiftObj: any, formate?) => {
    console.log(shiftObj,"shiftObject");
    
    let mainArr = [];
    const { day_id } = shiftObj;
    shiftObj?.shifts.map((shift) => {
      let shiftObj: any = {};
      shiftObj.day_id = day_id;
      shiftObj.shift_id = shift?.shift_id;
      shiftObj.start_time =
        formate === 'formateTime'
          ? moment(shift.start_time, 'HH:mm')
          : shift.start_time.slice(0, 5);
      shiftObj.end_time =
        formate === 'formateTime' ? moment(shift.end_time, 'HH:mm') : shift.end_time.slice(0, 5);
      if (shift?.id) {
        shiftObj['id'] = shift?.id;
      }
      mainArr.push(shiftObj);
    });
    console.log(mainArr,"mainArr");
    
    return mainArr;
  };
  const formateShiftObjToSubmitData = (shiftObj: any, formate?) => {
    const { day_id, shift_id, start_time, end_time, id } = shiftObj;
    let finalObj: any = {
      day_id,
      shift_id,
      start_time,
      end_time
    };
    if (id) {
      finalObj['id'] = id;
    }
    return finalObj;
  };
  const handleCustomFormChange = (value, formName, key) => {
    const formArrayElement = { ...customFormValues[key] };
    formArrayElement[formName] = value;
    customFormValues[key] = { ...formArrayElement };
    setCustomFormValues(customFormValues);
  };
  const handleRemoveFromCustomForm = (key) => {
    const filteredCustomFormValues = customFormValues.filter((item, i) => i !== key);
    setCustomFormValues(filteredCustomFormValues);
  };
  const handleSubmit = (formValues: any) => {
    console.log(formValues);
    
    const formatedValues = customFormValues?.map((item) => formateShiftObjToSubmitData(item));
    const dataObj = {
      shifts: formatedValues
    };
    console.log(dataObj ,"dnaish=<<");
    
    props.handleOnSubmitForm(dataObj);
  };

  function range(time, type, shiftId) {
    const result = [];
    if (type === 'start') {
      if (shiftId !== 1) {
        for (let i = 0; i < 12; i++) {
          result.push(i);
        }
      }
      for (let i = Number(time); i <= 23; i++) {
        result.push(i);
      }
    } else {
      if (shiftId !== 1) {
        for (let i = 0; i < 12; i++) {
          result.push(i);
        }
      }
      for (let i = 0; i < time; i++) {
        result.push(i);
      }
    }
    return result;
  }
  function disabledStartTime(key) {
    let disablesTime = [];
    let time = customFormValues[key]?.end_time;
    if (customFormValues[key]?.shift_id === 1) {
      disablesTime = time
        ? [...range(time.slice(0, 2), 'start', customFormValues[key]?.shift_id), ...eveningShift]
        : eveningShift;
    } else {
      disablesTime = time
        ? [morningShift, ...range(time.slice(0, 2), 'start', customFormValues[key]?.shift_id)]
        : morningShift;
    }
    return disablesTime;
  }
  function disabledEndTime(key) {
    let disablesTime = [];
    let time = customFormValues[key]?.start_time;
    if (customFormValues[key]?.shift_id === 1) {
      disablesTime = time
        ? [...range(time.slice(0, 2), 'end', customFormValues[key]?.shift_id), ...eveningShift]
        : eveningShift;
    } else {
      disablesTime = time
        ? [morningShift, ...range(time.slice(0, 2), 'end', customFormValues[key]?.shift_id)]
        : morningShift;
    }
    return disablesTime;
  }
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <Form
      disabled={ViewPageDisable()}
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <Form.List name='shifts'>
        {(fields, { add, remove, ...rest }) => (
          <>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <React.Fragment key={key}>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'shift_id']}
                            rules={[{ required: true, message: 'shift  is required' }]}
                          >
                            <Select
                              placeholder='Select shift'
                              onChange={(value) => handleCustomFormChange(value, 'shift_id', key)}
                            >
                              {shifts?.length > 0 &&
                                shifts?.map((item: any, i) => (
                                  <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                                    {item.title}{' '}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Item>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'day_id']}
                            rules={[{ required: true, message: 'day  is required' }]}
                          >
                            <Select
                              placeholder='Select Day'
                              onChange={(value) => handleCustomFormChange(value, 'day_id', key)}
                            >
                              {daysList?.length > 0 &&
                                daysList?.map((item: any, i) => (
                                  <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                                    {item.name}{' '}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Item>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'start_time']}
                            fieldKey={[fieldKey, 'start_time']}
                            rules={[{ required: true, message: 'start_time is required' }]}
                          >
                            <TimePicker
                              placeholder='Start time'
                              format='HH:mm'
                              // disabled={customFormValues[key]?.shift_id ? false : true}
                              hideDisabledOptions
                              showNow={false}
                              disabledTime={(current) => ({
                                disabledHours: () => disabledEndTime(key),
                                disabledMinutes: () => [],
                                disabledSeconds: () => []
                              })}
                              // disabledHours={() => disabledStartTime(key)}
                              minuteStep={15}
                              onChange={(time, timeString) =>
                                handleCustomFormChange(timeString, 'start_time', key)
                              }
                              size='large'
                              style={{ width: '100%', borderRadius: 30 }}
                            />
                          </Item>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <Item
                            name={[name, 'end_time']}
                            fieldKey={[fieldKey, 'end_time']}
                            rules={[{ required: true, message: 'end_time is required' }]}
                          >
                            <TimePicker
                              placeholder='End time'
                              format='HH:mm'
                              // disabled={customFormValues[key]?.shift_id ? false : true}
                              showNow={false}
                              onChange={(time, timeString) =>
                                handleCustomFormChange(timeString, 'end_time', key)
                              }
                              minuteStep={15}
                              hideDisabledOptions
                              disabledTime={(current) => ({
                                disabledHours: () => disabledEndTime(key),
                                disabledMinutes: () => [],
                                disabledSeconds: () => []
                              })}
                              // disabledHours={() => disabledEndTime(key)}
                              // disabledHours={() =>
                              //   customFormValues[key]?.shift_id === 1 ? eveningShift : morningShift
                              // }
                              size='large'
                              style={{ width: '100%', borderRadius: 30 }}
                            />
                          </Item>
                        </div>
                      </div>
                    </div>
                    <Button
                      style={{ backgroundColor: 'white', border: 'none' }}
                      type='text'
                      onClick={() => {
                        remove(name);
                        handleRemoveFromCustomForm(name);
                      }}
                      disabled={id === 'create' ? false : true}
                      icon={<MinusCircleOutlined style={{color:"black"}}/>}
                    />
                  </React.Fragment>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Add timing
                  </Button>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Form.List>
      {!isView && (
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex justify-content-between'>
              <Button onClick={() => history(-1)} type='default'>
                Cancel
              </Button>
              <Button type='primary' block={false} disabled={props.loading} htmlType='submit'>
                Done
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

export default AddVisitTimings;