/* eslint-disable radix */
import React, { useState } from 'react';
import type { Dayjs } from 'dayjs';
import { Form, Card, Select, DatePicker,TimePicker, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import AsyncSearchSelect from '../../components/AsyncSearchSelect';
import moment from 'moment';
import { FilterOutlined } from '@ant-design/icons';

export type FormValueType = Partial<any>;
export type UpdateFormProps = {
  setFormValues: (values: any) => void;
};
const FilterQueryForm: React.FC<UpdateFormProps> = (props: any) => {
  const [form] = useForm();
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  const { RangePicker}  = DatePicker;
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({ ...customFormValues, [formName]: value });
  };
  /* date range handler */
  function handleDateRangeChange(dates) {
    const start_date = moment(new Date(dates[0])).format('YYYY-MM-DD');
    const end_date = moment(new Date(dates[1])).format('YYYY-MM-DD');
    console.log('start_date: ', start_date, ', end_date: ', end_date);
    setCustomFormValues({ ...customFormValues, start_date, end_date });
  }
  /* Handle custom form onchange */
  const handleFormReset = () => {
    form.resetFields();
    setCustomFormValues('');
    props.setFormValues('');
  };
  /**
   * @param values
   */
  const filterParams = async (values: any) => {
    let clonedValues = { ...customFormValues };
    if (clonedValues?.start_date) {
      clonedValues.start_date = moment(new Date(clonedValues.start_date)).format('YYYY-MM-DD');
    }
    if (clonedValues?.end_date) {
      clonedValues.end_date = moment(new Date(clonedValues.end_date)).format('YYYY-MM-DD');
    }
    props.setFormValues(clonedValues);
  };

  const { Item } = Form;

  return (
    <div
      style={{ marginBottom: 10 }}
    // style={{ marginBottom: 10, padding: 10, borderRadius: '10px', border: '1px solid #f0f0f0' }}
    >
      <Form
        form={form}
        onFinish={filterParams}
        onReset={() => props.setFormValues('')}
        layout='vertical'
        // labelAlign='left'
        colon={false}
      >
        {/* <Space style={{ marginBottom: 16 }}> */}
        <div className='row d-flex justify-content-between'>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <Item 
            // name='doctor_id'
             label='Doctor'>
              <AsyncSearchSelect
                placeholder='Search Doctor'
                url='/doctors?'
                dataObj='doctor'
                selectedValue={(item) => {
                  form.setFieldsValue({ doctor_id: item?.value });
                  handleCustomFormChange(item?.value, 'doctor_id');
                }}
              />
            </Item>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='form-group'>
              <Item 
              // name='type' 
              label='Appointment Type'>
                <Select
                  placeholder='Type'
                  onChange={(value) => handleCustomFormChange(value, 'type')}
                >
                  <Select.Option value='physical'>Physical</Select.Option>
                  <Select.Option value='video'>Virtual</Select.Option>
                </Select>
              </Item>
            </div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='form-group'>
              <Item 
              // name='payment_status'
               label='Payment Status'>
                <Select
                  placeholder='Payment Status'
                  onChange={(value) => handleCustomFormChange(value, 'payment_status')}
                >
                  <Select.Option value='paid'>Paid</Select.Option>
                  <Select.Option value='unpaid'>Un Paid</Select.Option>
                </Select>
              </Item>
            </div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='form-group'>
              <Item 
              // name='status'
               label='Status'>
                <Select
                  placeholder='Status'
                  onChange={(value) => handleCustomFormChange(value, 'status')}
                >
                  <Select.Option value='consulted'>Consulted</Select.Option>
                  <Select.Option value='scheduled'>Scheduled</Select.Option>
                  <Select.Option value='reschedlued'>Reschedlued</Select.Option>
                </Select>
              </Item>
            </div>
          </div>
        </div>
        <div className='row d-flex justify-content-between'>
          <div className='col-md-3 col-sm-4 col-xs-12'>
            <div className='form-group'>
              <Item 
              // name='date_search'
               label='Search by date' >
                <RangePicker
                  // value ={{
                  //   'Today' : [moment(), moment()],
                  //   'This Week': [moment().startOf('week'), moment().endOf('week')],
                  //   'This Month': [moment().startOf('month'), moment().endOf('month')],
                  // }}
                  size="large"
                  onChange={handleDateRangeChange}
                />
              </Item>
            </div>
          </div>


          <div className='d-flex justify-content-end' style={{ alignSelf: "center" }}>
            <Button onClick={() => handleFormReset()} type='default' style={{ marginRight: 10 }}>
              Reset
            </Button>
            <Button block={false} type='primary' htmlType='submit' icon={<FilterOutlined />}>
              Search
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FilterQueryForm;
