import React, { useEffect, useState } from 'react';

import { Card } from 'antd';
import ReactEcharts from 'echarts-for-react';
import * as appointment from '../../../services/appointments';

// import ReactEcharts from 'echarts-for-react';

import AppointmentsTable from '../../appointments/AppointmentsTable';
// import hospitalOptions from './charts/hospital-options';

// import { incomeInWeek, incomeInMonth } from './charts/income-options';

// import {
//   patientsGenderOptions,
//   departmentsOptions,
//   patientsAgeOptions
// } from './charts/patients-options';

import { useFetchDetailsData, useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IPageData } from '../../../interfaces/page';
import { initialPageRef } from '../../../utils/constants';
import { isAuthrizedForAppointments } from '../../../utils/StorageService';
import api from '../../../services/Api';
import RevenueBarChart from './charts/revenue/RevenueBarCharts';
import PatientsPieChart from './charts/patients/PatientsPieChart';
import RevenuePieChart from './charts/revenue/RevenuePieChart';

const pageData: IPageData = {
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    }
  ]
};

const DashboardPage = () => {
  // let keyForTable = 1;
  const [totalCounts, setTotalCounts] = useFetchDetailsData<any>('/dashboard/widget-counts', []);
  // const [tableData, setTableData] = useFetchPageData<any>('/appointments', []);
  const [tableData, setTableData] = useState<any>([]);
  usePageData(pageData);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);
  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (isAuthrizedForAppointments()) {
      getAllData();
    }
  }, []);
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setLoading(true);
    const res: any = await appointment.getAll(params);
    setLoading(false);
    setTableData(res?.data?.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta?.current_page ? meta?.current_page : pageRef?.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };
  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: '' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-first-aid-alt'
                  style={{ fontSize: 48, color: '#1C208F' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Appointments</h6>
                <div className='count' style={{ fontSize: 20, color: '#14BFFF', lineHeight: 1.4 }}>
                  {totalCounts?.appointments_count}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: '' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-wheelchair'
                  style={{ fontSize: 48, color: '#1C208F' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>New patients</h6>
                <div className='count' style={{ fontSize: 20, color: '#14BFFF', lineHeight: 1.4 }}>
                  {totalCounts?.patients_count}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: '' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-doctor'
                  style={{ fontSize: 48, color: '#1C208F' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Doctors</h6>
                <div className='count' style={{ fontSize: 20, color: '#14BFFF', lineHeight: 1.4 }}>
                  {totalCounts?.doctors_count}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: '' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-blood'
                  style={{ fontSize: 48, color: '#1C208F' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Hospitals</h6>
                <div className='count' style={{ fontSize: 20, color: '#14BFFF', lineHeight: 1.4 }}>
                  {totalCounts?.hospitals_count}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Card title='Monthly Revenue'>
        <RevenueBarChart />
      </Card>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <RevenuePieChart />
        </div>

        <div className='col-12 col-md-6'>
          <PatientsPieChart />
        </div>
      </div>
      {/* <Card title='Hospital survey'>
        <ReactEcharts className='chart-container container-h-400' option={hospitalOptions} />
      </Card>

      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <Card>
            <h4 className='mt-0 mb-1'>$25038</h4>
            <p className='mb-0' style={{ color: '#9d9d9d' }}>
              Income in current month
            </p>

            <ReactEcharts className='chart-container' option={incomeInMonth} />
          </Card>
        </div>

        <div className='col-sm-12 col-md-6'>
          <Card>
            <h4 className='mt-0 mb-1'>$2195</h4>
            <p className='mb-0' style={{ color: '#9d9d9d' }}>
              Income in current week
            </p>

            <ReactEcharts className='chart-container' option={incomeInWeek} />
          </Card>
        </div>
      </div> */}

      {isAuthrizedForAppointments() && (
        <Card title='Last appointments' className='mb-0'>
          <AppointmentsTable
            data={tableData?.past_appointments}
            pagination={pageRef}
            handleTableChange={handleTableChange}
            loading={loading}
          />
        </Card>
      )}
    </>
  );
};

export default DashboardPage;
