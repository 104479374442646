import { IRoute } from '../interfaces/routing';
import DoctorProfilePage from '../pages/doctors/DoctorProfilePage';

import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import ForgetPassword from '../pages/sessions/ForgetPassword';
import ResetPassword from '../pages/sessions/ResetPassword';
import SignIn from '../pages/sessions/Sign-in';
import SignUp from '../pages/sessions/Sign-up';

export const sessionRoutes: IRoute[] = [
  {
    path: 'page-404',
    component: NotFound
  },
  {
    path: 'page-500',
    component: InternalError
  },
  {
    path: 'sign-in',
    component: SignIn
  },
  {
    path: 'forget-password',
    exact: true,
    component: ForgetPassword
  },
  {
    path: 'confirm/:id',
    exact: true,
    component: ResetPassword
  },
  {
    path: 'sign-up',
    component: SignUp
  },
  {
    path: 'doctors-profile/:id',
    component: DoctorProfilePage
  },
];
