import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Modal, notification, InputNumber } from 'antd';
// import ImageLoader from './ImageLoader';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as patient from '../../services/patients';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import AsyncSearchSelect from '../../components/AsyncSearchSelect';
import PhoneInput from 'react-phone-number-input';
import * as patients from '../../services/patients';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const PatientAddUpdateForm = ({ visible, onClose, values, getAllData }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>({ patient_type: 'self' });

  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues(clonedValues);
    }
  }, [values]);
  /* Fetch data by id and set values */
  const getAndSetPatientBySelectedPatientId = (id) => {
    console.log('#id : ', id);
    if (id) {
      patients.getById(id).then((res: any) => {
        console.log('#res?.data?.data : ', res?.data?.data);
        if (res?.data?.data) {
          setCustomFormValues({ ...customFormValues, userId: res?.data?.data?.user_id });
        }
      });
    }
  };
  /* custom form change */
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'patientId') {
      /* Fething and setting userId of patient */
      getAndSetPatientBySelectedPatientId(value);
    } else {
      setCustomFormValues({ ...customFormValues, [formName]: value });
    }
  };
  /* performing operations before hitting api */
  const formateFormBeforeSubmit = (formValues: any) => {
    formValues.age = parseInt(formValues.age);
    formValues.phone = formValues?.phone?.toString();
    formValues.resident_card_id = formValues.resident_card_id.toString();
    if (customFormValues?.patient_type === 'someone_else') {
      formValues.userId = customFormValues.userId;
    }
    // if (values?.user_id && customFormValues?.patient_type === "someone_else") {
    //   formValues.userId = values?.user_id;
    // }
    return formValues;
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    getAllData();
    setTimeout(() => {
      form.resetFields();
      onClose();
    }, 1000);
  };
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    const formValuesFormate = formateFormBeforeSubmit(formValues);
    try {
      setLoading(true);
      if (values?.id) {
        const data = await patient.update(formValuesFormate, values.id);
        setLoading(false);
        if (data?.status === 200) {
          operationsAfterSubmit(data);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await patient.add(formValuesFormate);
        setLoading(false);
        if (data?.status === 200) {
          operationsAfterSubmit(data);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };

  const appointmentForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        patient_type: 'self'
      }}
    >
      <div className='form-group'>
        <Item
          name='name'
          label='Patient Name'
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input placeholder='Patient Name' type='text' />
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='patient_type'
          label='Patient Type'
          rules={[{ required: true, message: 'patient type is required' }]}
        >
          <Select
            placeholder='Patient Type'
            disabled={values?.id ? false : true}
            // disabled={values?.id ? true : false}
            onChange={(value) => handleCustomFormChange(value, 'patient_type')}
          >
            <Select.Option value='self'>Self</Select.Option>
            <Select.Option value='someone_else'>Other</Select.Option>
          </Select>
        </Item>
      </div>
      {!values?.id && customFormValues?.patient_type === 'self' && (
        <div className='form-group'>
          <Item
            name='userId'
            label='Patient User'
            rules={[{ required: true, message: 'Please select the patient user' }]}
          >
            <AsyncSearchSelect
              placeholder='Search Patient User'
              url='/users?type=patient&'
              // defaultValue={
              //   values?.user && {
              //     label: values['user']?.name,
              //     value: values['user']?.id
              //   }
              // }
              selectedValue={(item) => {
                form.setFieldsValue({ userId: item?.value });
                handleCustomFormChange(item?.value, 'userId');
              }}
            />
          </Item>
        </div>
      )}
      {customFormValues?.patient_type === 'someone_else' && !values?.id && (
        <div className='form-group'>
          <Item name='userId' label='Parent Patient'>
            <AsyncSearchSelect
              placeholder='Search Parent Patient'
              url='/patients?patient_type=self&'
              dataObj='patient'
              // defaultValue={
              //   values?.patient && {
              //     label: values['patient']?.name,
              //     value: values?.patient
              //   }
              // }
              selectedValue={(item) => {
                // form.setFieldsValue({ userId: item?.value });
                handleCustomFormChange(item?.value, 'patientId');
              }}
            />
          </Item>
        </div>
      )}
      <div className='form-group'>
        <Item
          name='age'
          label='Age'
          rules={[
            {
              required: true,
              message: 'age is required'
              // type: 'regexp',
              // pattern: new RegExp(`^[1-9][0-9]*$`)
            }
          ]}
        >
          <Input placeholder='Age' type='number' />
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='resident_card_id'
          label='Resident Card Id'
          rules={[{ required: true, message: 'Resident Card Number is required' }]}
        >
          <Input placeholder='Resident Card Number' />
        </Item>
      </div>
      <div className='form-group'>
        <div className='form-group'>
          <Item
            name='phone'
            label='Phone'
            rules={[{ required: true, message: 'Phone Number is required' }]}
          >
            <PhoneInput
              defaultCountry='OM'
              placeholder='Phone Number'
              international
              style={{ borderRadius: 20, height: 40, padding: 5 }}
              value={customFormValues?.phone}
              onChange={(value) => handleCustomFormChange(value, 'phone')}
            />
          </Item>
        </div>
      </div>
      <div className='form-group'>
        <Item
          name='gender'
          label='Gender'
          rules={[{ required: true, message: 'Gender is required' }]}
        >
          <Select placeholder='Gender'>
            <Select.Option value='male'>Male</Select.Option>
            <Select.Option value='female'>Female</Select.Option>
            <Select.Option value='other'>Other</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='form-group'>
        <Item
          name='is_active'
          label='Patient Status'
          rules={[{ required: true, message: 'status is required' }]}
        >
          <Select placeholder='Status'>
            <Select.Option value={1}>Active</Select.Option>
            <Select.Option value={0}>InActive</Select.Option>
          </Select>
        </Item>
      </div>

      <div className='d-flex justify-content-between'>
        <Button onClick={onClose} type='default'>
          Close
        </Button>
        <Button block={false} type='primary' loading={loading} htmlType='submit'>
          Submit
        </Button>
      </div>
    </Form>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? 'Update Patient' : 'Add Patient'}</h3>}
    >
      {appointmentForm}
    </Modal>
  );
};

export default PatientAddUpdateForm;
