import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/laboratories/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params = {}) => {
  try {
    const url = '/laboratories' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/laboratories', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/laboratories/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/laboratories/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

/* Pharmacy branches  */
export async function getLaboratoryBranchById(id: number) {
  try {
    const url = '/laboratory-branches/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAllLaboratoryBranches = async (params = {}) => {
  try {
    const url = '/laboratory-branches' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const addLaboratoryBranch = async (requestBody) => {
  try {
    const res = await api.post('/laboratory-branches', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateLaboratoryBranch = async (requestBody, id) => {
  try {
    const res = await api.put('/laboratory-branches/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const removeLaboratoryBranch = async (id) => {
  try {
    const res = await api.delete('/laboratory-branches/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

