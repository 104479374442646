import React from 'react';

import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
const columns: ColumnProps<any>[] = [
  {
    key: 'food_allergies',
    dataIndex: 'food_allergies',
    title: 'Food Allergies',
    fixed: 'left',
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (food_allergies) => <span>{food_allergies}</span>
  },
  //   {
  //     key: 'tendency_bleed',
  //     dataIndex: 'tendency_bleed',
  //     title: 'Tendency Bleed',
  //     // sorter: (a, b) => (a.tendency_bleed > b.tendency_bleed ? 1 : -1),
  //     render: (tendency_bleed) => <span className='nowrap'>{tendency_bleed}</span>
  //   },
  {
    key: 'heart_disease',
    title: 'Heart Disease',
    dataIndex: 'heart_disease',
    render: (heart_disease) => <span>{heart_disease}</span>
  },
  {
    key: 'high_blood_pressure',
    title: 'High Blood Pressure',
    dataIndex: 'high_blood_pressure',
    render: (high_blood_pressure) => <span>{high_blood_pressure}</span>
  },
  //   {
  //     key: 'diabetic',
  //     title: 'Diabetic',
  //     dataIndex: 'diabetic',
  //     render: (diabetic) => <span>{diabetic}</span>
  //   },
  {
    key: 'surgery',
    title: 'Surgery',
    dataIndex: 'surgery',
    render: (surgery) => <span>{surgery}</span>
  },
  {
    key: 'current_medication',
    title: 'Current Medication',
    dataIndex: 'current_medication',
    render: (current_medication) => <span>{current_medication}</span>
  }
  //   {
  //     key: 'accident',
  //     title: 'Accident',
  //     dataIndex: 'accident',
  //     render: (accident) => <span>{accident}</span>
  //   }
  //   {
  //     key: 'family_medical_history',
  //     title: 'Family Medical History',
  //     dataIndex: 'family_medical_history',
  //     render: (family_medical_history) => <span>{family_medical_history}</span>
  //   },
  //   {
  //     key: 'female_pregnancy',
  //     title: 'Female Pregnancy',
  //     dataIndex: 'female_pregnancy',
  //     render: (female_pregnancy) => <span>{female_pregnancy}</span>
  //   },
  //   {
  //     key: 'breast_feeding',
  //     title: 'Breast Feeding',
  //     dataIndex: 'breast_feeding',
  //     render: (breast_feeding) => <span>{breast_feeding}</span>
  //   },
  //   {
  //     key: 'health_insurance',
  //     title: 'Health Insurance',
  //     dataIndex: 'health_insurance',
  //     render: (health_insurance) => <span>{health_insurance}</span>
  //   },
  //   {
  //     key: 'low_income',
  //     title: 'Low Income',
  //     dataIndex: 'low_income',
  //     render: (low_income) => <span>{low_income}</span>
  //   },
  //   {
  //     key: 'reference',
  //     title: 'Reference',
  //     dataIndex: 'reference',
  //     render: (reference) => <span>{reference}</span>
  //   },
  //   {
  //     key: 'others',
  //     title: 'Others',
  //     fixed: 'right',
  //     dataIndex: 'others',
  //     render: (others) => <span>{others}</span>
  //   }
];

type Props = {
  data: [];
  pagination: {};
  isViewModal?: boolean;
  actions?: any;
};

const PatientCaseStudyTable = ({ data, pagination, actions }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    // fixed: 'right',
    render: actions
  };
  const displayedColumns = actions ? [...columns, actionColumn] : columns;
  const keyForTable = () => {
    return Math.random() * Date.now();
  };

  return (
    <>
      <Table
        rowKey={keyForTable}
        dataSource={data?.length ? data : []}
        columns={displayedColumns}
        pagination={pagination}
        // scroll={{ x: 1500 }}
      />
    </>
  );
};

export default PatientCaseStudyTable;
