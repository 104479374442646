import React from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';
import moment from 'moment';

const columns: ColumnProps<any>[] = [
  {
    key: 'name',
    dataIndex: 'patient',
    title: 'Patient Name',
    fixed: 'left',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (patient) => <strong>{patient?.name}</strong>
  },
  {
    key: 'date',
    dataIndex: 'date',
    title: 'Date',
    sorter: (a, b) => (a.date > b.date ? 1 : -1),
    render: (date) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {moment(new Date(date)).format('DD MMM YYYY')}
      </span>
    )
  },
  {
    key: 'id',
    dataIndex: 'id',
    sorter: (a, b) => (a.id > b.id ? 1 : -1),
    title: 'Appointment Id',
    // width:20,
    render: (id) => <span style={{ minWidth: 80, display: 'block' }}>{id}</span>
  },
  {
    key: 'doctor',
    title: 'Doctor',
    dataIndex: 'doctor',
    render: (doctor) => <strong>{doctor?.first_name}</strong>
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status) => <Tag>{status}</Tag>
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const AppointmentsTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    // fixed: 'right',
    width: 130,
    render: actions
  };
  const displayedColumns = actions ? [...columns, actionColumn] : columns;
  //  random key
const keyForTable =()=>{
  return Date.now()*Math.random()
}
// console.log(pagination, "danish");


// console.log(data?.length ? data : []);


  return (
    <Table
      rowKey={keyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default AppointmentsTable;
