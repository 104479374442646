import React, { useEffect } from 'react';
import { Form, Button, Input, DatePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
const { Item } = Form;

const AddAwards = ({ values, id, history, isView, ...props }) => {
  const [form] = useForm();
  const [customFormValues, setCustomFormValues] = useState<any>([]);
  /* Fetch fields data */
  useEffect(() => {
    if (values && id !== 'create') {
      const clonedValues = [...values];
      const formatedValues = clonedValues?.map((item) => formateAwardObj(item, 'edit'));
      form.setFieldsValue({
        awards: formatedValues
      });
      const formatedValuesToSet = clonedValues?.map((item) => formateAwardObj(item));

      setCustomFormValues([...formatedValuesToSet]);
    }
  }, [values]);

  const handleCustomFormChange = (value, formName, key) => {
    if (formName === 'year') {
      value = moment(new Date(value)).format('YYYY');
    }
    const formArrayElement = { ...customFormValues[key] };
    formArrayElement[formName] = value;
    customFormValues[key] = { ...formArrayElement };
    setCustomFormValues(customFormValues);
  };
  const handleRemoveFromCustomForm = (key) => {
    const filteredCustomFormValues = customFormValues.filter((item, i) => i !== key);
    setCustomFormValues(filteredCustomFormValues);
  };

  const formateAwardObj = (awardObj: any, formate?) => {
    const { title, year, id } = awardObj;
    let finalObj: any = {
      title,
      year: formate === 'edit' ? moment(year, 'YYYY-MM-DD') : year
    };
    if (id) {
      finalObj['id'] = id;
    }
    return finalObj;
  };
  const handleSubmit = (formValues: any) => {
    const formatedValues = customFormValues?.map((item) => formateAwardObj(item));
    const dataObj = {
      awards: formatedValues
    };
    props.handleOnSubmitForm(dataObj);
  };
  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <Form
      disabled={ViewPageDisable()}
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <Form.List name='awards'>
        {(fields, { add, remove, ...rest }) => (
          <>
            {/* {form.setFieldsValue({ hospital_id: 2 })} */}
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <React.Fragment key={key}>
                    <div className='form-group'>
                      <Item
                        name={[name, 'title']}
                        fieldKey={[fieldKey, 'title']}
                        rules={[{ required: true, message: 'title is required' }]}
                      >
                        <Input
                          onChange={(e) => handleCustomFormChange(e?.target?.value, 'title', key)}
                          placeholder='Title'
                          type='text'
                        />
                      </Item>
                    </div>
                    <div className='form-group'>
                      <Item
                        // name={[name, 'year']}
                        fieldKey={[fieldKey, 'year']}
                        rules={[
                          {
                            required: !customFormValues[key]?.year ? true : false,
                            message: 'Please select the year'
                          }
                        ]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          defaultValue={dayjs(values[0]?.year.toString())}
                          format={'YYYY'}
                          onChange={(date, dateString) => handleCustomFormChange(date, 'year', key)}
                          picker='year'
                        />
                      </Item>
                    </div>
                    <Button
                      style={{ backgroundColor: 'white', border: 'none' }}
                      type='text'
                      onClick={() => {
                        remove(name);
                        handleRemoveFromCustomForm(name);
                        console.log('hello world');
                      }}
                      disabled={id === 'create' ? true : false}
                      icon={<MinusCircleOutlined style={{ color: 'black' }} />}
                    />
                  </React.Fragment>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Add award
                  </Button>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Form.List>
      {!isView && (
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex justify-content-between'>
              <Button onClick={() => history(-1)} type='default'>
                Cancel
              </Button>
              <Button type='primary' block={false} disabled={props.loading} htmlType='submit'>
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

export default AddAwards;
