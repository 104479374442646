import { ChannelList, ChannelListMessengerProps, ChatDownProps, CustomClasses } from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';
import { useSelector } from 'react-redux';
import { getUser } from '../../utils/StorageService';
import Spinner from '../Spinner';
import { PropsWithChildren } from 'react';

const AllChannelList = () => {
    const client = useSelector((state: any) => state?.chat?.client)
    const currentUser = getUser();

    // const sort = { last_message_at: -1 };
    const filters = { type: 'messaging', members: { $in: [currentUser?.id?.toString()] } };
    // const CustomList = (props: PropsWithChildren<ChannelListMessengerProps>) => {
    //     const { children, error, loading, LoadingErrorIndicator, LoadingIndicator } = props;
    //     console.log("#props : ", props)
    //     if (error) {
    //         return <LoadingErrorIndicator type={'connection'} />;
    //     }

    //     if (loading) {
    //         return <LoadingIndicator />;
    //     }

    //     return <div style={{ borderRadius: 150 }}>{children}</div>;
    // };

    // const CustomErrorIndicator = (props: ChatDownProps) => {
    //     const { text } = props;

    //     return <div>{text}</div>;
    // };
    // const CustomLoadingIndicator = () => {
    //     return <div>Loading, loading, loading...</div>;
    // };
    // const customOnMessageNew = async (setChannels, event) => {
    //     const eventChannel = event.channel;

    //     // If the channel isn't frozen, then don't add it to the list.
    //     if (!eventChannel?.id || !eventChannel.frozen) return;

    //     try {
    //         const newChannel = client.channel(eventChannel.type, eventChannel.id);
    //         await newChannel.watch();
    //         setChannels((channels) => [newChannel, ...channels]);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    // const CustomListContainer = (props: PropsWithChildren<ChannelListMessengerProps>) => {
    //   // render custom list container here
    // };

    // const CustomListItem = (props) => {
    //   // render custom list item here
    // };
    if (!client) {
        return <Spinner />;
    }
    return (
        <ChannelList filters={filters} />
        // <ChannelList
        //     List={CustomList}
        //     filters={filters}
        //     LoadingErrorIndicator={() => (
        //         <CustomErrorIndicator text={'Loading Error - check your connection.'} type={'connection'} />
        //     )}
        //     LoadingIndicator={CustomLoadingIndicator}
        // />
    )
};

export default AllChannelList;