import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input, Modal, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as illnessService from '../../../services/illnessServices';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import api from '../../../services/Api';

const { Item } = Form;

type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const ServiceAddUpdateForm = ({ visible, onClose, values, getAllData, isViewModal }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [customFormValues, setCustomFormValues] = useState<any>({});
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    if (formName === "language_id") {
      form.resetFields(["name"])
    }
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  /* Fetch fields data */
  useEffect(() => {
    api.get('/languages').then((res: any) => {
      if (res?.data?.data?.length) {
        setLanguages(res?.data?.data);
      }
    });
  }, []);
  /* set form values in edit mode */
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  /* handle submit form */
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (values?.id) {
        const data = await illnessService.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data)
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await illnessService.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data)
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
      setLoading(false);
    }
  };
  const appointmentForm = (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        language_id: 1
      }}
    >
      <div className='form-group'>
        <Item name='name' rules={[{ required: true, message: 'Service Name is required' }]}>
          <Input placeholder='Service Name' type='text' />
        </Item>
      </div>
      <ConfigProvider direction={`rtl`}>
        <div className='form-group'>
          <Item name='name_ar' rules={[{ required: true, message: `مطلوب اسم الخدمة` }]}>
            <Input className='arabic' placeholder={`اسم الخدمة`} type='text' />
          </Item>
        </div>
      </ConfigProvider>
      {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={<h3 className='title'>{values?.id ? (isViewModal ? 'View Service' : 'Update Service') : 'Add Service'}</h3>}
    >
      {appointmentForm}
    </Modal>
  );
};

export default ServiceAddUpdateForm;
