import React, { ReactNode } from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';
import CustomTag from '../../components/common/CustomTag';
import { Link } from 'react-router-dom';

const addUpdatePage = (id, isView?) => {
  console.log("#id : ", id)
  // const history: any = useHistory();
  // const layout = window.location.pathname.split('/')[1];

  // history.pushState("", "", `/${layout}/users/${id}`);
  // if (isView) {
  //   localStorage.setItem('isViewUser', isView);
  // } else {
  //   localStorage.removeItem('isViewUser');
  // }
};
const handleEdit = (row) => {
  addUpdatePage(row?.id);
};
const columns: ColumnProps<any>[] = [
  // {
  //   key: 'img',
  //   title: 'Photo',
  //   dataIndex: 'img',
  //   render: (img) => <PatientImg img={img} />
  // },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name, record) => <Link to={`/vertical/users/${record?.id}`} style={{ color: "blue" }}>{name ? name : "-"}</Link>
  },
  {
    key: 'email',
    dataIndex: 'email',
    title: 'Email',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (email) => <strong>{email}</strong>
  },
  {
    key: 'user_type',
    dataIndex: 'user_type',
    title: 'User Type',
    // width: 200,
    sorter: (a, b) => (a.type > b.type ? 1 : -1),
    render: (type) => <CustomTag type={type} />
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const UsersTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    fixed: 'right',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

const keyForTable =()=>{
  return Date.now()* Math.random()
}
  return (
    <Table
      rowKey={keyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default UsersTable;
