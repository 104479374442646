import React, { useEffect, useState } from 'react';

import { useFetchPageData, usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import * as doctor from '../../services/doctors';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import DoctorsTable from './DoctorsTable';
import { initialPageRef } from '../../utils/constants';
import SearchData from '../../layout/components/search/SearchData';
import { useNavigate, useLocation } from 'react-router'; // replace useHistory with useNavigate
import TableActions from '../../components/common/tables-components/TableActions';
import TablePageAddAction from '../../components/common/tables-components/TablePageAddAction';
import ImportExportData from '../../components/common/ImportExportData';

const pageData: IPageData = {
  title: 'Doctors',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Doctors'
    }
  ]
};

const DoctorsPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/doctors', []);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);
  const history = useNavigate(); // replace useHistory to useNavigate
  const history1 = useLocation();

  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = pageRef) => {
    // console.log("data cc");

    setLoading(true);
    const res: any = await doctor.getAll(params);
    setLoading(false);
    setTableData(res.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta.current_page ? meta.current_page : pageRef.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };
  const handleDelete = async (item) => {
    const data = await doctor.remove(item.user_id);
    try {
      if (data?.status === 200) {
        showSuccessMessage(data);
        getAllData();
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
    }
  };
  const addUpdatePage = (id, isView?) => {
    const layout = history1.pathname.split('/')[1]; // .location.pathname.split remove
    history(`/${layout}/doctors/${id}`); // .push remove
    if (isView) {
      localStorage.setItem('isViewDoctor', isView);
    } else {
      localStorage.removeItem('isViewDoctor');
    }
  };

  const handleEdit = (row) => {
    addUpdatePage(row?.id);
  };

  const handleView = (row) => {
    const isView = true;
    addUpdatePage(row?.id, isView);
    // const layout = history.location.pathname.split('/')[1];
    // history.push(`/${layout}/doctors-profile/${row?.id}`);
  };
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleView,
      handleDelete: handleDelete,
      handleEdit: handleEdit,
      module: 'doctors',
      operations: { list: true, update: true, remove: true, add: false }
    };
    return actionObj;
  };
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <ImportExportData url='doctor' />
      <DoctorsTable
        data={tableData?.data}
        pagination={pageRef}
        actions={(row) => TableActions(getObj(row))}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      {TablePageAddAction({ onAdd: () => addUpdatePage('create'), module: 'doctors' })}
      {/* {console.log(tableData?.data)} */}
      {/* <PageAction onClick={() => addUpdatePage('create')} icon='icofont-plus' type={'primary'} /> */}
    </>
  );
};

export default DoctorsPage;
