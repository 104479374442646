import React, { useEffect, useState } from 'react';

import { Button, Input, notification, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as permissions from '../../../../services/permissions';
import { showErrorMessage, showSuccessMessage } from '../../../../utils/AppUtils';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Item } = Form;

const PaymentProductionSettings = ({ allPaymentKeys }) => {
  const [form] = useForm();
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [customFormValues, setCustomFormValues] = useState<any>([]);

  useEffect(() => {
    const {
      PAYMENT_CANCEL,
      PAYMENT_SUCCESS,
      THAWANI_PUBLISHABLE_KEY_PRODUCTION,
      THAWANI_SECRET_KEY_PRODUCTION,
      PAYMENT_CHECKOUT_PRODUCTION,
      THAWANI_URL_PRODUCTION
    } = allPaymentKeys;
    form.setFieldsValue({
      PAYMENT_CANCEL,
      PAYMENT_SUCCESS,
      THAWANI_PUBLISHABLE_KEY_PRODUCTION,
      THAWANI_SECRET_KEY_PRODUCTION,
      PAYMENT_CHECKOUT_PRODUCTION,
      THAWANI_URL_PRODUCTION
    });
    setCustomFormValues({
      PAYMENT_CANCEL,
      PAYMENT_SUCCESS,
      THAWANI_PUBLISHABLE_KEY_PRODUCTION,
      THAWANI_SECRET_KEY_PRODUCTION,
      PAYMENT_CHECKOUT_PRODUCTION,
      THAWANI_URL_PRODUCTION
    });
  }, [allPaymentKeys]);

  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({ ...customFormValues, [formName]: value });
    setIsDisabledSubmit(true);
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
  };
  const testCredentials = async () => {
    try {
      const clonedCustomFormValues = { ...customFormValues };
      clonedCustomFormValues.PAYMENT_MODE = 'live';
      const data = await permissions.testPaymentCred(clonedCustomFormValues);
      if (data?.data?.status === 200) {
        notification.success({
          message: data?.statusText
        });
        setIsDisabledSubmit(false);
      } else {
        notification.error({
          message: data?.data?.detail
        });
      }
    } catch (e: any) {
      showErrorMessage(e.data);
    }
  };
  const handleSubmit = async () => {
    try {
      const clonedCustomFormValues = { ...customFormValues };
      clonedCustomFormValues.PAYMENT_MODE = 'live';
      const data = await permissions.updatePaymentSettings(customFormValues);
      if (data?.data?.status === 200) {
        operationsAfterSubmit(data);
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
    }
  };
  return (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
    >
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <div className='form-group'>
            <Item
              name='PAYMENT_SUCCESS'
              label='PAYMENT SUCCESS'
              rules={[{ required: true, message: 'PAYMENT_SUCCESS is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_SUCCESS')}
                placeholder='PAYMENT SUCCESS'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='PAYMENT_CANCEL'
              label='PAYMENT CANCEL'
              rules={[{ required: true, message: 'PAYMENT_CANCEL is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'PAYMENT_CANCEL')}
                placeholder='PAYMENT CANCEL'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='THAWANI_URL_PRODUCTION'
              label='THAWANI URL PRODUCTION'
              rules={[{ required: true, message: 'THAWANI_URL_PRODUCTION is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'THAWANI_URL_PRODUCTION')}
                placeholder='THAWANI URL PRODUCTION'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='THAWANI_SECRET_KEY_PRODUCTION'
              label='THAWANI SECRET KEY PRODUCTION'
              rules={[{ required: true, message: 'THAWANI_SECRET_KEY_PRODUCTION is required' }]}
            >
              <Input.Password
                onChange={(e) =>
                  handleCustomFormChange(e.target.value, 'THAWANI_SECRET_KEY_PRODUCTION')
                }
                placeholder='THAWANI SECRET KEY PRODUCTION'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='THAWANI_PUBLISHABLE_KEY_PRODUCTION'
              label='THAWANI PUBLISHABLE KEY PRODUCTION'
              rules={[
                { required: true, message: 'THAWANI_PUBLISHABLE_KEY_PRODUCTION is required' }
              ]}
            >
              <Input.Password
                onChange={(e) =>
                  handleCustomFormChange(e.target.value, 'THAWANI_PUBLISHABLE_KEY_PRODUCTION')
                }
                placeholder='THAWANI PUBLISHABLE KEY PRODUCTION'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

              />
            </Item>
          </div>

          <div className='form-group'>
            <Item
              name='PAYMENT_CHECKOUT_PRODUCTION'
              label='PAYMENT CHECKOUT PRODUCTION'
              rules={[{ required: true, message: 'PAYMENT_CHECKOUT_PRODUCTION is required' }]}
            >
              <Input.Password
                onChange={(e) =>
                  handleCustomFormChange(e.target.value, 'PAYMENT_CHECKOUT_PRODUCTION')
                }
                placeholder='PAYMENT CHECKOUT PRODUCTION'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              // name='PAYMENT_MODE'
              label='PAYMENT MODE'
              rules={[{ required: true, message: 'PAYMENT_MODE is required' }]}
            >
              <Input value='live' readOnly type='text' />
            </Item>
          </div>
          <div className='d-flex justify-content-between'>
            <Button onClick={testCredentials} type='default'>
              Test Credentials
            </Button>
            <Button
              type='primary'
              onClick={handleSubmit}
              disabled={isDisabledSubmit}
              block={false}
              htmlType='submit'
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PaymentProductionSettings;
