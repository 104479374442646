import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/patients/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params = {}) => {
  try {
    const url = '/patients' + getPageReferenceParam(params);
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const add = async (requestBody) => {
  try {
    const res = await api.post('/patients', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const addPatientCaseStudy = async (requestBody) => {
  try {
    const res = await api.post('/patient-case-study', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/patients/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const remove = async (id) => {
  try {
    const res = await api.delete('/patient', id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
