import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';

const columns: ColumnProps<any>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    fixed: 'left',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'category',
    dataIndex: 'category',
    title: 'Category',
    sorter: (a, b) => (a.category > b.category ? 1 : -1),
    render: (category) => <span>{category}</span>
  },
  {
    key: 'type',
    dataIndex: 'type',
    title: 'Type',
    sorter: (a, b) => (a.type > b.type ? 1 : -1),
    render: (type) => <span>{type}</span>
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: 'Amount',
    sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
    render: (amount) => <span>{amount}</span>
  },
  {
    key: 'category',
    dataIndex: 'category',
    title: 'Category',
    sorter: (a, b) => (a.category > b.category ? 1 : -1),
    render: (category) => <span>{category}</span>
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const ServicesFeeTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    width: 150,
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  // making unique key
  const keyForServiceTable =()=>{
     return Date.now()*Math.random()
  }


  return (
    <Table
      rowKey={keyForServiceTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default ServicesFeeTable;
