import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import PublicLayout from '../../layout/public/Public';
import { useForm } from 'antd/es/form/Form';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import { loginService, resetPassword } from '../../services/login';
import { useNavigate,useLocation } from 'react-router-dom';
import { isAuthenticated } from '../../utils/StorageService';
import { Navigate } from 'react-router-dom';

const { Item } = Form;
const ResetPassword = () => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const history1: any = useNavigate();
    const history: any = useLocation();
    let pathSplitted = history.pathname.split('/');

    const handleSubmit = async (values: any) => {
        let formatedData = {
            token: pathSplitted?.[3],
            ...values
        }
        try {
            setLoading(true);
            let data = await resetPassword(formatedData);
            setLoading(false);
            if (data?.status === 200) {
                showSuccessMessage(data)
                history1('/public/sign-in')
            } else {
                console.log(data)

                showErrorMessage(data);
                setLoading(false);
            }
        } catch (e: any) {
            console.log(e)
            showErrorMessage(e.data);
            setLoading(false);
        }
    };
    // if (isAuthenticated()) {
    //     return <Navigate to='/vertical/dashboard' />
    // }
    return (
        <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
            <h4 className='mt-0 mb-1 center-align'>Set Password</h4>

            <Form form={form} layout='vertical' onFinish={handleSubmit}>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        {
                            pattern: /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
                            message: 'Password must be between 8 to 20 characters, contain at least one special char and capital letter.'
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item
                    name="confirmed_password"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>

                <Button
                    block={false}
                    type='primary'
                    loading={loading}
                    htmlType='submit'
                    className='center-align width-100'
                >
                    Reset Password
                </Button>
            </Form>
            <br />
        </PublicLayout>
    );
};

export default ResetPassword;
