import PageAction from '../../../layout/components/page-action/PageAction';
import { getPermissions } from '../../../utils/StorageService';

const TablePageAddAction = ({ onAdd, module }: any) => {
  const permissions = JSON.parse(getPermissions());
  const modulePermissions = permissions?.[module] || {};
  return modulePermissions?.add ? (
    <PageAction onClick={onAdd} icon='icofont-plus' type={'primary'} />
  ) : null;
};
export default TablePageAddAction;
