import React, { useEffect, useState } from 'react';

import { Button, Modal, List, Typography } from 'antd';
import { Form } from 'antd';
import moment from 'moment';
const { Item } = Form;
type Props = {
    visible: boolean;
    values: any;
    onClose: () => void;
    isViewModal?: boolean;
};

const TransactionViewModal = ({
    visible,
    onClose,
    values,
}: Props) => {
    const getDetailItem = (label, value) => {
        return (
            <div className='d-flex justify-content-between'>
                <List.Item >
                    <Typography.Text strong>{label || ""}</Typography.Text>
                </List.Item>
                <List.Item >
                    <Typography.Text>{value ? value : "-"}</Typography.Text>
                </List.Item>
            </div>
        )
    }
    const TrasactionDetail = (
        <>
            {getDetailItem("Transaction Id", values?.transaction_id)}
            {getDetailItem("Appointment Id", values?.appointment_id)}
            {getDetailItem("Transaction Status", values?.status)}
            {getDetailItem("Charge ID", values?.charge_id)}
            {getDetailItem("Transaction Date", moment(new Date(values?.transaction_date)).format('DD MMM YYYY'))}
            {getDetailItem("Amount", values?.item)}
            {getDetailItem("Card Type", values?.card_type)}
            {getDetailItem("Thawani Fee", values?.thawani_fee)}
            {getDetailItem("Commision", values?.commision)}
            {getDetailItem("Markup", values?.markup)}
            {getDetailItem("Net Revenue", values?.net_revenue)}

            <div className='d-flex justify-content-between'>
                <Button onClick={onClose} type='default'>
                    Close
                </Button>
                <Button block={false} type='primary' onClick={onClose} >
                    Ok
                </Button>
            </div>
        </>
    );
    return (
        <Modal
            open={visible}
            onCancel={onClose}
            destroyOnClose
            footer={null}
            maskClosable={false}
            title={
                <h3 className='title'>Transaction </h3>
            }
        >
            {TrasactionDetail}
        </Modal>
    );
};

export default TransactionViewModal;
