import React, { useEffect } from 'react';

import { Routes as CustomRoutes, Route, Navigate, useNavigate, redirect } from 'react-router-dom';

import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';

import NotFound from './pages/sessions/404';
import { defaultRoutes, sessionRoutes } from './routing';

import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';
import { isAuthenticated, getUser } from './utils/StorageService';
import { StreamChat } from 'stream-chat';
import { streamChatKey } from './utils/constants';
import { useDispatch } from 'react-redux';
import { addChatClient, chatUserConnected } from './redux/chat-data/actions';
import { notification } from 'antd';
import SignIn from './pages/sessions/Sign-in';
import DashboardPage from './pages/dashboards/dashboard/Dashboard';

const client = StreamChat.getInstance(streamChatKey);

// const Routes = ({ routes, layout = '' }) => (
//   <CustomRoutes>
//     {routes.map((route, index) => (
//       <Route
//         key={index}
//         path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}`}
//         element={() => <route.component />}
//       />
//     ))}

//     <Route path='*'>
//       <Navigate to='/public/page-404' />
//     </Route>
//   </CustomRoutes>
// );

// const DefaultRoutes = ({ layout }) => <Routes routes={defaultRoutes} layout={layout} />;

// const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

const App = () => {
  useHideLoader();
  /* Redux dispatch */
  const dispatch = useDispatch();
  /* Get current user from storage */
  const currentUser = getUser();

  const history = useNavigate();
  // let pathSplitted = [];
  /* connect user to chat client */
  const connectUser = async () => {
    try {
      await client.connectUser(
        {
          id: currentUser?.id?.toString(),
          name: currentUser?.email,
          image: 'https://i.imgur.com/fR9Jz14.png'
        },
        client.devToken(currentUser?.id?.toString()) //token from database
      );
      // dispatchEvent
      await dispatch(chatUserConnected({ isUserConnected: true }));
      await dispatch(addChatClient({ client: client }));
    } catch (error: any) {
      notification.error({
        message: 'Error while connecting chat user client!' + error?.message
      });
    }
    console.log('User connected');
  };
  /* Set stream chat client */
  const createClient = async () => {
    try {
      if (isAuthenticated) {
        await client.disconnectUser();
        connectUser();
      } else {
        alert('User not authenticated!');
      }
    } catch (error: any) {
      alert('Error while setting the chat user client!' + error?.message);
    }
  };
  /* Set up chat client on mount */
  useEffect(() => {
    if (currentUser) {
      createClient();
    }
    // pathSplitted = history.location.pathname.split('/');

    return () => {
      client.disconnectUser();
    };
  }, []);
  return (
    <CustomRoutes>
      {sessionRoutes.map((value, index) => {
        return (
          <Route
            key={`session-routes-public-${index}`}
            path={`/public/${value.path}`}
            element={<value.component />}
          />
        );
      })}
      {defaultRoutes.map((value, index) => {
        return (
          <Route
            key={`default-routes-vertical-layout-${index}`}
            path={`/vertical/${value.path}`}
            element={
              <VerticalLayout>
                <value.component />
              </VerticalLayout>
            }
          />
        );
      })}
      {defaultRoutes.map((value, index) => {
        return (
          <Route
            key={`default-routes-horizontal-layout-${index}`}
            path={`/horizontal/${value.path}`}
            element={
              <HorizontalLayout>
                <value.component />
              </HorizontalLayout>
            }
          />
        );
      })}

      {/* <Route path='/public/sign-in' element={<SignIn />} /> */}
      {/* <Route path='/public'>
        <SessionRoutes />
      </Route>
// hello
// hello

      <Route path='/horizontal'>
        <HorizontalLayout>
          <DefaultRoutes layout='horizontal' />
        </HorizontalLayout>
      </Route>

      <Route path='/vertical'>
        <VerticalLayout>
          <DefaultRoutes layout='vertical' />
        </VerticalLayout>
      </Route> */}
      <Route
        path='/'
        element={
          isAuthenticated() === true ? (
            <VerticalLayout>
              <DashboardPage />
            </VerticalLayout>
          ) : (
            <SignIn />
          )
        }
      />

      <Route path='*' element={<NotFound />} />
    </CustomRoutes>
  );
};

export default App;
