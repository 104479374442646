import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export async function getById(id: number) {
  try {
    const url = '/modules-permissions/' + id;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
}
export const getAll = async (params = {}) => {
  try {
    const url = '/modules-permissions?user_type=' + params;
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const getAllModulesAndPermission = async () => {
  try {
    const url = '/modules';
    const res = await api.get(url);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const add = async (requestBody) => {
  try {
    const res = await api.post('/modules-permissions', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const update = async (requestBody, id) => {
  try {
    const res = await api.put('/modules-permissions/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const remove = async (id) => {
  try {
    const res = await api.delete('/modules-permissions/' + id);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

/* Email seetings  */
export const getEmailSettings = async (params = {}) => {
  try {
    const res = await api.get('settings?type=email');
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const testEmail = async (requestBody) => {
  try {
    const res = await api.post('/test-credentials/email', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const updateEmailSettings = async (requestBody) => {
  try {
    const res = await api.put('/settings/', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Payment seetings  */
export const getPaymentSettings = async (params = {}) => {
  try {
    const res = await api.get('settings?type=payment');
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const testPaymentCred = async (requestBody) => {
  try {
    const res = await api.post('/test-credentials/payment', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updatePaymentSettings = async (requestBody) => {
  try {
    const res = await api.put('/settings/', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Localization seetings  */
export const getLocalizations = async (params = {}) => {
  try {
    const res = await api.get('localization' + getPageReferenceParam(params));
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const updateLocalizationSettings = async (requestBody, id) => {
  try {
    const res = await api.put('/localization/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Localization seetings  */
export const getServiceFeeSettings = async (params = {}) => {
  try {
    const res = await api.get('service-fee' + getPageReferenceParam(params));
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Service fee settings */
export const addServiceFeeSettings = async (requestBody) => {
  try {
    const res = await api.post('/service-fee', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const updateServiceFeeSettings = async (requestBody, id) => {
  try {
    const res = await api.put('/service-fee/' + id, requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};
/* Email seetings  */
export const getAgoraSettings = async (params = {}) => {
  try {
    const res = await api.get('settings?type=agora');
    return res;
  } catch (err: any) {
    return err.data;
  }
};
export const getChatSettings = async (params = {}) => {
  try {
    const res = await api.get('settings?type=stream_chat');
    return res;
  } catch (err: any) {
    return err.data;
  }
};

export const updateChatSettings = async (requestBody) => {
  console.log("#requestBody : ",requestBody)
  try {
    const res = await api.put('/settings/', requestBody);
    return res;
  } catch (err: any) {
    return err.data;
  }
};