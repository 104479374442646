import React, { useEffect, useState } from 'react';

import { Collapse, notification } from 'antd';
import TransactionsTable from './TransactionsTable';
import { useFetchPageData, usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import * as appointment from '../../../services/appointments';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import { initialPageRef } from '../../../utils/constants';
import SearchData from '../../../layout/components/search/SearchData';
import TableActions from '../../../components/common/tables-components/TableActions';
import TablePageAddAction from '../../../components/common/tables-components/TablePageAddAction';
import TransactionViewModal from './TransactionViewModal';
import ImportExportData from '../../../components/common/ImportExportData';
// import FilterQueryForm from './FilterQueryForm';

const pageData: IPageData = {
  title: 'Transactions',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Transactions'
    }
  ]
};

const TransactionsPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/transactions', []);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);

  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}, filter?) => {
    setLoading(true);
    const res: any = await appointment.getAllTransactions(params);
    setLoading(false);
    // console.log("#res?.data?.data : ", res?.data)
    setTableData(res?.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta?.current_page ? meta?.current_page : pageRef?.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };

  /*Get Filtered data*/
  const getFilteredData = (values: any) => {
    if (!values) {
      setPageRef(initialPageRef);
      getAllData();
    } else {
      getAllData(values, 'isFiltered');
    }
    // setUserParams(values);
  };
  const handleViewModal = (row) => {
    setSelectedRow(row);
    setModalVisibility(true);
  };
  const closeAddingModal = () => {
    setSelectedRow(null);
    setModalVisibility(false);
  };
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleViewModal,
      // handleDelete: handleDelete,
      // handleEdit: handleEditModal,
      module: 'transactions',
      operations: { list: true, update: false, remove: false, add: false }
    };
    return actionObj;
  };
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      {/* <FilterQueryForm setFormValues={getFilteredData} /> */}
      <ImportExportData url='transaction' notImport={true} />
      <TransactionsTable
        data={tableData?.data}
        type='none'
        actions={(row) => TableActions(getObj(row))}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}

      />
      {/* Add update form appointment */}
      {modalVisibility && (
        <TransactionViewModal
          onClose={closeAddingModal}
          isViewModal={true}
          visible={modalVisibility}
          values={selectedRow ? selectedRow : {}}
        />
      )}
    </>
  );
};

export default TransactionsPage;
