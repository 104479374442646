/* eslint-disable no-useless-rename */
import React, { useEffect, useState } from 'react';

import { Button, notification, Steps } from 'antd';
import * as doctor from '../../services/doctors';
import {
  add as add,
  update as update,
  addQualification as addQualification,
  updateQualification as updateQualification,
  addExperience as addExperience,
  updateExperience as updateExperience,
  addAward as addAward,
  updateAward as updateAward,
  addPhysicalShifts as addPhysicalShifts,
  updatePhysicalShifts as updatePhysicalShifts,
  addVirtualShifts as addVirtualShifts,
  updateVirtualShifts as updateVirtualShifts,
  addVisitShifts as addVisitShifts,
  updateVisitShifts as updateVisitShifts
} from '../../services/doctors';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import RegisterForm from './components/RegisterDoctor';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import AddQualification from './components/AddQualification';
import AddExperience from './components/AddExperience';
import AddAwards from './components/AddAwards';
import AddHospitalTimings from './components/AddHospitalTimings';
import AddVirtualTimings from './components/AddVirtualTimings';
import AddVisitTimings from './components/AddVists';
import { useParams, useNavigate } from 'react-router-dom'; //useHistory replace useHistory
import { getUserType, updateUserInStorage } from '../../utils/StorageService';

const pageData: IPageData = {
  title: 'Doctor Onboarding',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Doctor Onboarding'
    }
  ]
};
const styles = {
  stepContainer: { marginTop: 20, marginBottom: 20 }

  // pointer:{cursor: 'pointer'},
  // click:{}
};
const DoctorAddUpdateForm = () => {
  const { id } = useParams<any>();
  usePageData(pageData);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [doctorId, setDoctorId] = useState('');

  const [doctorDetails, setDoctorDetails] = useState<any>({});
  const history: any = useNavigate(); // replace useHistory to useNavigate
  const isView = localStorage.getItem('isViewDoctor');
  /* getting new_user property to check doctor user signin first time with no profile */
  const new_user = localStorage.getItem('new_user');
  // const { Step } = Steps;
  /* Fetch data by id and set values */
  const fetchAndSetDoctorsData = async (resId?) => {
    const dataDoctorId = resId ? resId : doctorId ? doctorId : id;

    const res = await doctor.getById(dataDoctorId);
    if (res?.data?.data) {
      setDoctorDetails(res?.data?.data);
    }
    /* Only for special case when new_user doctor is signin  */

    if (resId) {
      next();
    }
  };
  /* Fetch details data if new_user !==1 */
  useEffect(() => {
    if (id && id !== 'create' && new_user !== '1') {
      fetchAndSetDoctorsData();
    }
  }, []);

  const formateFormBeforeSubmit = (formValues: any, reqMethod) => {
    /* Setting doctor_id except registering doctor in first step */
    if (reqMethod === update) {
      formValues.userId = doctorDetails?.user_id;
      formValues.user_id = doctorDetails?.user_id;
    }
    formValues.doctor_id = doctorId ? Number(doctorId) : Number(id);

    /* setting new_user property if doctor user signin first time with no profile */
    if (new_user === '1') {
      formValues.new_user = '1';
      formValues.userId = id;
      formValues.user_id = id;
    }
    return formValues;
  };

  /* performing operations after add method success */
  const operationsAfterSubmit = (res, addReqMetod?) => {
    if (addReqMetod?.name === 'add') {
      /* Setting doctor_id after registering doctor in first step for other steps */
      setDoctorId(res?.data?.data?.adddoctor?.id);
      fetchAndSetDoctorsData(res?.data?.data?.adddoctor?.id);
    }
    /* Removing new_user falg if doctor user signin first time with no profile is present */
    if (new_user === '1') {
      localStorage.removeItem('new_user');
      setDoctorId(res?.data?.data?.id);
      updateUserInStorage(res?.data?.data?.id);
      fetchAndSetDoctorsData(res?.data?.data?.id);
    }
    showSuccessMessage(res);
    if (current === 6) {
      /* Sucess message on final step */
      setTimeout(() => {
        notification.success({
          message: 'Doctor Information has been added/updated sucessfully!'
        });
      }, 1000);
      /* Redirect according to user type */
      const user = JSON.parse(localStorage.getItem('user')) || {};
      if (user?.user_type === 'doctor') {
        history.replace('/vertical/dashboard');
      } else {
        history(-1); //repalce .goBack() with (-1)
      }
    } else if (Object.values(doctorDetails)?.length) {
      /*moving to next step on form */
      next();
    }
  };

  const handleFormSubmit = async (formValues: any, addReqMetod: any, updateReqMethod: any) => {
    try {
      setLoading(true);

      if (id !== 'create') {
        const formValuesFormate = formateFormBeforeSubmit(formValues, updateReqMethod);
        const res = await updateReqMethod(formValuesFormate, doctorId ? doctorId : id);

        setLoading(false);
        if (res?.status === 200) {
          if (Number(new_user) !== 1) {
            fetchAndSetDoctorsData();
          }
          operationsAfterSubmit(res);
        } else {
          showErrorMessage(res);
        }
      } else {
        const formValuesFormate = formateFormBeforeSubmit(formValues, addReqMetod);

        const res = await addReqMetod(formValuesFormate);

        setLoading(false);
        if (res?.status === 200) {
          operationsAfterSubmit(res, addReqMetod);
          next();
        } else {
          showErrorMessage(res);
        }
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };

  const steps = [
    {
      title: 'Register',
      content: (
        <div style={styles.stepContainer}>
          <RegisterForm
            handleOnSubmitForm={(formValues) => handleFormSubmit(formValues, add, update)}
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails}
            history={history}
            id={id}
            isView={isView}
            new_user={new_user}
          />
        </div>
      )
    },
    {
      title: 'Qualification',
      content: (
        <div style={styles.stepContainer}>
          <AddQualification
            handleOnSubmitForm={(formValues) =>
              handleFormSubmit(formValues, addQualification, updateQualification)
            }
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails?.qualifications || {}}
            history={history}
            id={id}
            isView={isView}
          />
        </div>
      )
    },
    {
      title: 'Experience',
      content: (
        <div style={styles.stepContainer}>
          <AddExperience
            handleOnSubmitForm={(formValues) =>
              handleFormSubmit(formValues, addExperience, updateExperience)
            }
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails?.experiences || {}}
            history={history}
            id={id}
            isView={isView}
          />
        </div>
      )
    },
    {
      title: 'Awards',
      content: (
        <div style={styles.stepContainer}>
          <AddAwards
            handleOnSubmitForm={(formValues) => handleFormSubmit(formValues, addAward, updateAward)}
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails?.awards || {}}
            history={history}
            id={id}
            isView={isView}
          />
        </div>
      )
    },
    {
      title: 'Hospitals Timing',
      content: (
        <div style={styles.stepContainer}>
          <AddHospitalTimings
            handleOnSubmitForm={(formValues) =>
              handleFormSubmit(formValues, addPhysicalShifts, updatePhysicalShifts)
            }
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails?.clinicalDays || {}}
            history={history}
            id={id}
            isView={isView}
          />
        </div>
      )
    },
    {
      title: 'Virtual Timing',
      content: (
        <div style={styles.stepContainer}>
          <AddVirtualTimings
            handleOnSubmitForm={(formValues) =>
              handleFormSubmit(formValues, addVirtualShifts, updateVirtualShifts)
            }
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails?.virtualDays || {}}
            history={history}
            id={id}
            isView={isView}
          />
        </div>
      )
    },
    {
      title: 'Visit Timing',
      content: (
        <div style={styles.stepContainer}>
          <AddVisitTimings
            handleOnSubmitForm={(formValues) =>
              handleFormSubmit(formValues, addVisitShifts, updateVisitShifts)
            }
            loading={loading}
            key={Object?.keys(doctorDetails)?.length}
            values={doctorDetails?.visitDays || {}}
            history={history}
            id={id}
            isView={isView}
          />
        </div>
      )
    }
  ];
  console.log(steps.length, 'temp');

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const changeHandle = (value) => {
    setCurrent(value);
  };

  return (
    <>
      <Steps current={current} size='small' items={steps}  labelPlacement="vertical"/>

      {/* <Steps current={current} size='small'  >
        {steps.map((item, i) =>
          id !== 'create' && Object.values(doctorDetails)?.length && new_user !== '1' ? (
            <Step
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => setCurrent(i)}
              title={item.title}
            />
          ) : (
            <Step key={i} title={item.title} />
          )
        )}
      // </Steps> */}

      <div className='steps-content'>{steps[current]?.content}</div>
      {isView && new_user !== '1' && (
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='d-flex justify-content-between'>
              <Button
                onClick={() =>
                  getUserType() !== 'admin' ? history.push('/vertical/dashboard') : history(-1)
                }
                type='default'
              >
                Cancel
              </Button>
              {current > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type='primary' onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type='primary' onClick={() => history(-1)}>
                  Done
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorAddUpdateForm;
