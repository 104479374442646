import axios from 'axios';
import { history } from '../redux/store';
import { getToken, isAuthenticated } from '../utils/StorageService';

export const baseURL = 'https://backend.medcure.om/api';
// export const baseURL = 'http://192.168.20.138:3333/api'; 


export const mediaUpload = `${baseURL}/uploads`;
export const mediaImport = `${baseURL}/import`;
export const mediaExport = `${baseURL}/export`;
const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Setting Authorization
api.interceptors.request.use(
  (config) => {
    const token: any = `bearer ` + JSON.parse(getToken());
    if (isAuthenticated()) {
      config.headers.Authorization = token;
      config.headers.common['Accept'] = 'application/json';
      config.headers.crossorigin = true;
    }
    return config;
  },
  (error) => {
    console.log('#error api: ', JSON.stringify(error));
    // return Promise.reject(error)
  }
);

// Error Handling --- https://medium.com/@yaob/how-to-globally-use-axios-instance-and-interceptors-e28f351bb794

api.interceptors.response.use(
  (response) => {
    if (response?.status === 200 || response?.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error?.response?.status) {
      switch (error?.response?.status) {
        case 400:
          console.log('400');
          break;
        case 401:
          console.log('#session expired');
          // redirect to login screen
          localStorage.clear();
          history.replace('/public/sign-in');
          break;
        case 403:
          if (error.response.config.url.indexOf('/signIn') < 0) {
            // history.replace("/unauthorized")
          }
          console.log('#403');
          break;
        case 404:
          // history.push('/page-404');
          console.log('#404');
          // page not found
          break;
        case 500:
          console.log('#500');
          break;
        case 502:
          console.log('#502');
          break;
        default:
          console.log('Success');
      }
      return Promise.reject(error.response);
    }
  }
);
export default api;
