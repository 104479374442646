import React, { ReactNode } from 'react';

import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
import AvatarImageDisplay from '../../components/common/AvatarImageDisplay';
import { Link } from 'react-router-dom';

const columns: ColumnProps<any>[] = [
  {
    key: 'image',
    title: 'Image',
    dataIndex: 'image',
    width: 120,
    render: (img) => <AvatarImageDisplay img={img} />
  },
  {
    key: 'fullName',
    dataIndex: 'fullName',
    title: 'Name',
    sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
    render: (fullName, record) => (
      <Link to={`/public/doctors-profile/${record?.id}`} style={{ color: 'blue' }}>
        {fullName ? fullName : '-'}
      </Link>
    )
  },
  {
    key: 'current_hospital',
    dataIndex: 'current_hospital',
    title: 'Hospital',
    sorter: (a, b) => (a.id > b.id ? 1 : -1),
    render: (current_hospital) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {current_hospital?.name ? current_hospital?.name : 'Not available'}
      </span>
    )
  },
  {
    key: 'gender',
    dataIndex: 'gender',
    title: 'Gender',
    sorter: (a, b) => a.gender - b.gender,
    render: (gender) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {gender.toUpperCase()}
      </span>
    )
  },
  {
    key: 'address',
    dataIndex: 'address',
    title: 'Address',
    render: (address) => <span style={{ minWidth: 200, display: 'block' }}>{address}</span>
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const DoctorsTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    // fixed: 'right',
    width: 130,
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;
  const keyForTable = () => {
    return Math.random() * Date.now();
  };
  // console.log(data);

  return (
    <Table
      rowKey={keyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default DoctorsTable;
