import React, { ReactNode } from 'react';

import { Table, Tag } from 'antd';

import { ColumnProps } from 'antd/es/table';

const columns: ColumnProps<any>[] = [
  // {
  //   key: 'img',
  //   title: 'Photo',
  //   dataIndex: 'img',
  //   render: (img) => <PatientImg img={img} />
  // },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    // width:160,
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => <strong>{name}</strong>
  },
  {
    key: 'age',
    dataIndex: 'age',
    title: 'Age',
    sorter: (a, b) => a.age - b.age,
    render: (age) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {age}
      </span>
    )
  },
  {
    key: 'gender',
    dataIndex: 'gender',
    title: 'Gender',
    sorter: (a, b) => a.age - b.age,
    render: (gender) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {gender}
      </span>
    )
  },
  {
    key: 'resident_card_id',
    dataIndex: 'resident_card_id',
    title: 'Resident Card Id',
    render: (resident_card_id) => (
      <span style={{ minWidth: 60, display: 'block' }}>{resident_card_id}</span>
    )
  },
  {
    key: 'is_active',
    dataIndex: 'is_active',
    // width:120,
    title: 'Status',
    render: (is_active) => (
      <Tag style={{ backgroundColor: is_active === 1 ? '#17bd6a' : '#ed5564' }}>
        {is_active === 1 ? 'Active' : 'In-Active'}
      </Tag>
    )
  }
];

type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
};

const PatientsTable = ({ data, actions, pagination, loading, handleTableChange }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    fixed: 'right',
    width:120,
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  const keyForTable = () => {
    return Math.random() * Date.now();
  };


  return (
    <Table
      rowKey={keyForTable}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default PatientsTable;
