import React, { useEffect, useState } from 'react';

import { Form, Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import * as permissions from '../../../services/permissions';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';

const { Item } = Form;

const EmailSettings = () => {
  const [form] = useForm();
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>([]);

  /* Fetch fields data */
  useEffect(() => {
    fetchAndSetData();
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    permissions.getEmailSettings().then((res: any) => {
      let formatedObj = {};
      if (res?.data?.data.length) {
        res?.data?.data?.map((item) => (formatedObj[item.key] = item.value));
        form.setFieldsValue({
          ...formatedObj
        });
        setCustomFormValues(formatedObj);
      }
    });
  };
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({ ...customFormValues, [formName]: value });
    setIsDisabledSubmit(true);
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
  };
  const testEmailConfiguration = async () => {
    try {
      const data = await permissions.testEmail(customFormValues);
      if (data?.data?.status === 200) {
        showSuccessMessage(data);
        setIsDisabledSubmit(false);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
    }
  };
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const data = await permissions.updateEmailSettings(formValues);
      if (data?.status === 200) {
        setLoading(false);
        showSuccessMessage(data);
        operationsAfterSubmit(data);
      } else {
        showErrorMessage(data);
        setLoading(false);
      }
    } catch (e: any) {
      showErrorMessage(e.data);
    }
  };

  return (
    <Form
      form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        is_active: 1
      }}
    >
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <div className='form-group'>
            <Item
              name='FROM_EMAIL'
              label='From Email'
              rules={[{ required: true, message: 'From email is required' }]}
            >
              <Input
                placeholder='From'
                onChange={(e) => handleCustomFormChange(e.target.value, 'FROM_EMAIL')}
                type='email'
              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='SES_ACCESS_KEY'
              label='SES ACCESS KEY'
              rules={[{ required: true, message: 'SES_ACCESS_KEY is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'SES_ACCESS_KEY')}
                placeholder='SES ACCESS KEY'
                type='text'
                // defaultValue={customFormValues?.SES_ACCESS_KEY?.replace(/\d(?=\d{4})/g, '*')}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='SES_ACCESS_SECRET'
              label='SES ACCESS SECRET'
              rules={[{ required: true, message: 'SES_ACCESS_SECRET is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'SES_ACCESS_SECRET')}
                placeholder='SES ACCESS SECRET'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                type='text'
              />
            </Item>
          </div>
          <div className='form-group'>
            <Item
              name='SES_REGION'
              label='SES REGION'
              rules={[{ required: true, message: 'SES_REGION is required' }]}
            >
              <Input.Password
                onChange={(e) => handleCustomFormChange(e.target.value, 'SES_REGION')}
                placeholder='SES ACCESS SECRET'
                type='text'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Item>
          </div>
          <div className='d-flex justify-content-between'>
            <Button disabled={loading} onClick={testEmailConfiguration} type='default'>
              Test Email
            </Button>
            <Button
              type='primary'
              onClick={handleSubmit}
              disabled={isDisabledSubmit}
              block={false}
              htmlType='submit'
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default EmailSettings;
