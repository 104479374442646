import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
const columns: ColumnProps<any>[] = [

  {
    key: 'doctor',
    dataIndex: 'doctor',
    title: 'Doctor',
    fixed: 'left',
    className: 'text-align-rigth',
    sorter: (a, b) => (a.doctor > b.doctor ? 1 : -1),
    render: (doctor) => (
      <strong className='text-right text-color-200'>{doctor?.fullName}</strong>
    )
  },
  {
    key: 'appointment_id',
    dataIndex: 'appointment_id',
    title: 'Appointment Id',
    fixed: 'left',
    className: 'text-align-rigth',
    sorter: (a, b) => (a.appointment_id > b.appointment_id ? 1 : -1),
    render: (appointment_id) => (
      <strong className='text-right text-color-200'>{appointment_id}</strong>
    )
  },
  {
    key: 'revenue',
    dataIndex: 'revenue',
    title: 'Revenue',
    className: 'text-align-rigth',
    sorter: (a, b) => (a.revenue > b.revenue ? 1 : -1),
    render: (revenue) => <span className='text-right text-color-200'>{revenue} OMR</span>
  },
  {
    key: 'revnet_revenueenue',
    dataIndex: 'revenue',
    title: 'Net Revenue',
    className: 'text-align-rigth',
    sorter: (a, b) => (a.net_revenue > b.net_revenue ? 1 : -1),
    render: (net_revenue) => <span className='text-right text-color-200'>{net_revenue} OMR</span>
  }
]
type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  loading: boolean;
  actions?: any;
  type?: any;
};
const tableClasses = {
  accent: 'accent-header',
  none: ''
};
const RevnueTable = ({ data, actions, pagination, loading, handleTableChange, type = 'none' }: Props) => {
  const actionColumn: ColumnProps<any> = {
    key: 'actions',
    title: 'Actions',
    fixed: 'right',
    render: actions
  };
  const displayedColumns = actions ? [...columns, actionColumn] : columns;
  const tableClass = tableClasses[type];

  const KeyForTable = ()=>{
    return Date.now() * Math.random()
  }

  return (
    <Table
      rowKey={KeyForTable}
      className={tableClass}
      dataSource={data?.length ? data : []}
      columns={displayedColumns}
      pagination={pagination}
      loading={loading || false}
      onChange={handleTableChange}
    />
  );
};

export default RevnueTable;
