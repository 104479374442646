import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input, Modal, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as specializations from '../../../services/specializations';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import api from '../../../services/Api';

const { Item } = Form;
type Props = {
  visible: boolean;
  values: any;
  onClose: () => void;
  getAllData: () => void;
  isViewModal?: boolean;
};

const SpecializationsAddUpdateForm = ({
  visible,
  onClose,
  values,
  getAllData,
  isViewModal
}: Props) => {
  const [form] = useForm();
  // const [allCities, setAllCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>({});

  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    setCustomFormValues({
      ...customFormValues,
      [formName]: value
    });
  };
  useEffect(() => {
    const { id } = values;
    if (values && id) {
      const clonedValues = Object.assign({}, values);
      form.setFieldsValue({
        ...clonedValues
      });
    }
  }, [values]);
  const handleSubmit = async (formValues: any) => {
    formValues.userId = formValues.user_id;
    try {
      setLoading(true);
      if (values?.id) {
        const data = await specializations.update(formValues, values.id);
        setLoading(false);
        if (data?.status === 200) {
          showSuccessMessage(data);
          getAllData();
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      } else {
        const data = await specializations.add(formValues);
        setLoading(false);
        if (data?.status === 200) {
          getAllData();
          showSuccessMessage(data);
          setTimeout(() => {
            form.resetFields();
            onClose();
          }, 1000);
        } else {
          showErrorMessage(data);
        }
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  const AddUpdateForm = (
    <>
    <Form
    disabled={isViewModal}
    form={form}
      requiredMark
      layout='vertical'
      scrollToFirstError
      className='mb-4'
      onFinish={handleSubmit}
      initialValues={{
        language_id: 1
      }}
    >
      <div className='form-group'>
        <Item name='title' rules={[{ required: true, message: 'Specialization Name is required' }]}>
          <Input placeholder='Specialization Name' type='text' />
        </Item>
      </div>
      <ConfigProvider direction={`rtl`}>
        <div className='form-group'>
          <Item className='arabic' name='title_ar' rules={[{ required: true, message: `مطلوب اسم ` }]}>
            <Input className='arabic' placeholder={`اسم`} type='text' />
          </Item>
        </div>
      </ConfigProvider>
      {isViewModal ? (
        <></>
        // <div className='d-flex justify-content-between'>
        //   <Button onClick={onClose} type='default'>
        //     Close
        //   </Button>
        //   <Button block={false} type='primary' onClick={onClose} loading={loading}>
        //     Ok
        //   </Button>
        // </div>
      ) : (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' loading={loading} htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
    {isViewModal ? (
        <div className='d-flex justify-content-between'>
          <Button onClick={onClose} type='default'>
            Close
          </Button>
          <Button block={false} type='primary' onClick={onClose} loading={loading}>
            Ok
          </Button>
        </div>
      ) : (
        <></>
        // <div className='d-flex justify-content-between'>
        //   <Button onClick={onClose} type='default'>
        //     Close
        //   </Button>
        //   <Button block={false} type='primary' loading={loading} htmlType='submit'>
        //     Submit
        //   </Button>
        // </div>
      )}
    </>
  );
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={
        <h3 className='title'>{values?.id ? isViewModal
          ? 'View Specialization'
          : 'Update Specialization' : 'Add Specialization'}</h3>
      }
    >
      {AddUpdateForm}
    </Modal>
  );
};

export default SpecializationsAddUpdateForm;
