import { getPageReferenceParam } from '../utils/QueryParamsString';
import api from './Api';

export const getRevnue = async (params = {}) => {
    try {
        const url = '/revenue'
        const res = await api.get(url);
        return res;
    } catch (err: any) {
        return err.data;
    }
};
// export const getIncomeData = async (params = {}) => {
//     try {
//         const url = '/revenue'
//         const res = await api.get(url);
//         return res;
//     } catch (err: any) {
//         return err.data;
//     }
// };
// export const getPatientGenders = async (params = {}) => {
//     try {
//         // const url = '/patients-gender'
//         // const res = await api.get(url);
//         // return res;
//         return [
//             { value: 154, name: 'Female' },
//             { value: 173, name: 'Male' }
//         ]
//     } catch (err: any) {
//         return err.data;
//     }
// };
