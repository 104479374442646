import React, { ReactNode } from 'react';

import { Avatar, Rate, Table, List, Popconfirm, Button } from 'antd';
import { getPermissions } from '../../utils/StorageService';
const permissions = JSON.parse(getPermissions());
const modulePermissions = permissions?.['reviews'] || {};
const getUserName = (item) => {
  const refUser = item[item?.ref_type];
  return refUser?.fullName
    ? refUser?.fullName
    : refUser?.name
    ? refUser?.name
    : refUser?.title
    ? refUser?.title
    : refUser?.email;
};
type Props = {
  data: [];
  pagination: {};
  handleTableChange: () => void;
  handleDelete: any;
  loading: boolean;
  actions?: any;
};

const ReviewsList = ({
  data,
  actions,
  pagination,
  loading,
  handleDelete,
  handleTableChange
}: Props) => {
  return (
    <List
      itemLayout='vertical'
      size='large'
      pagination={pagination}
      dataSource={data?.length ? data : []}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            modulePermissions['remove'] ? (
              <Popconfirm
                title='Are you sure delete this review?'
                onConfirm={() => handleDelete(item)}
                key={`popup${item.id}`}
              >
                <a>Hide</a>
              </Popconfirm>
            ) : null
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar
                size={48}
                shape='square'
                style={{ color: 'black', fontSize: '10px', marginTop: 20 }}
              >
                {item.ref_type?.charAt(0)?.toUpperCase() + item?.ref_type?.slice(1)}
              </Avatar>
            }
            title={<p>{getUserName(item)}</p>}
            description={<Rate disabled style={{ fontSize: 14 }} defaultValue={item?.rating} />}
          />
          <div style={{ marginLeft: 70 }}>{item?.comment}</div>
        </List.Item>
      )}
    />
  );
};
export default ReviewsList;
