import React, { useEffect, useState } from 'react';

import { Button, Popconfirm } from 'antd';
import SpecializationsTable from './SpecializationsTable';

import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IPageData } from '../../../interfaces/page';
import SpecializationsAddUpdateForm from './SpecializationsAddUpdateForm';
import * as specializations from '../../../services/specializations';

import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import { initialPageRef } from '../../../utils/constants';
import SearchData from '../../../layout/components/search/SearchData';
import TablePageAddAction from '../../../components/common/tables-components/TablePageAddAction';
import TableActions from '../../../components/common/tables-components/TableActions';
import ImportExportData from '../../../components/common/ImportExportData';

const pageData: IPageData = {
  title: 'Specializations',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'specializations'
    }
  ]
};

const SpecializationsPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/specializations', []);
  const [isViewModal, setIsViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);

  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setLoading(true);
    const res: any = await specializations.getAll(params);
    setLoading(false);
    setTableData(res.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta.current_page ? meta.current_page : pageRef.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };
  const handleDelete = async (item) => {
    const data = await specializations.remove(item.id);
    try {
      if (data?.status === 200) {
        showSuccessMessage(data);
        getAllData();
      } else {
        showErrorMessage(data);
      }
    } catch (e: any) {
      showErrorMessage(e?.data);
    }
  };

  const openAddingModal = () => setModalVisibility(true);

  const closeAddingModal = () => {
    setSelectedRow(null);
    setIsViewModal(false);
    setModalVisibility(false);
  };

  const handleEditModal = (row) => {
    setSelectedRow(row);
    setModalVisibility(true);
  };
  const handleViewModal = (row) => {
    setSelectedRow(row);
    setIsViewModal(true);
    setModalVisibility(true);
  };
  const getObj = (row) => {
    let actionObj = {
      row,
      handleView: handleViewModal,
      handleDelete: handleDelete,
      handleEdit: handleEditModal,
      module: 'specializations',
      operations: { list: true, update: true, remove: true, add: false }
    };
    return actionObj;
  };
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <ImportExportData url='specialization' />
      <SpecializationsTable
        data={tableData?.data}
        actions={(row) => TableActions(getObj(row))}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      {TablePageAddAction({ onAdd: openAddingModal, module: 'specializations' })}
      {/* Add update form appointment */}
      {modalVisibility && (
        <SpecializationsAddUpdateForm
          onClose={closeAddingModal}
          visible={modalVisibility}
          isViewModal={isViewModal}
          values={selectedRow ? selectedRow : {}}
          getAllData={getAllData}
        />
      )}
    </>
  );
};

export default SpecializationsPage;
