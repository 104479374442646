import React, { useEffect, useState } from 'react';

import { Button, Popconfirm } from 'antd';
import ReviewsList from './ReviewsList';

import { useFetchPageData, usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import ReviewAddUpdateForm from './ReviewAddUpdateForm';
import * as reviews from '../../services/reviews';
import { showErrorMessage, showSuccessMessage } from '../../utils/AppUtils';
import { initialPageRef } from '../../utils/constants';
import SearchData from '../../layout/components/search/SearchData';
import TablePageAddAction from '../../components/common/tables-components/TablePageAddAction';

const pageData: IPageData = {
  title: 'Reviews',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Reviews'
    }
  ]
};

const ReviewPage = () => {
  usePageData(pageData);
  const [tableData, setTableData] = useFetchPageData<any>('/reviews', []);

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageRef, setPageRef] = useState<any>(initialPageRef);

  /* Set pagination after first time data fetch */
  useEffect(() => {
    if (pageRef?.total === 0 && tableData?.data?.length) {
      setPagination(tableData?.meta);
    }
  }, [tableData]);
  /* Get latest data */
  const getAllData = async (params = {}) => {
    setLoading(true);
    const res: any = await reviews.getAll(params);
    setLoading(false);
    setTableData(res.data);
    const meta = res?.data?.data?.meta;
    setPagination(meta);
  };
  /* Set pagination state*/
  const setPagination = (meta) => {
    setPageRef((pagination) => ({
      ...pagination,
      current: meta.current_page ? meta.current_page : pageRef.current,
      pageSize: meta?.per_page,
      total: meta?.total,
      sort: ''
    }));
  };
  /* Handle pagination state change */
  const handleTableChange = (pagination?, filters?, sorter?) => {
    if (pagination.current !== pageRef.current) {
      getAllData({
        ...pagination,
        current: pagination?.current,
        pageSize: pagination?.pageSize,
        total: pagination?.total,
        sort: '',
        ...filters
      });
    }
  };

  /* Delete table item */
  const handleDelete = async (item) => {
    const data = await reviews.remove(item.id);
    try {
      if (data?.status === 200) {
        showSuccessMessage(data);
        getAllData();
      } else {
        showErrorMessage(data);
      }
    } catch (e) {
      showErrorMessage(data);
    }
  };

  const openAddingModal = () => setModalVisibility(true);

  const closeAddingModal = () => {
    setSelectedRow(null);
    setModalVisibility(false);
  };

  const actions = (reviews: any) => (
    <div className='buttons-list nowrap'>
      <Popconfirm
        title='Are you sure delete?'
        onConfirm={handleDelete.bind({}, reviews)}
        key={`popup${reviews.id}`}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <>
      <SearchData data={{ getAllData, pageRef }} />
      <ReviewsList
        data={tableData?.data}
        actions={actions}
        handleDelete={(item) => handleDelete(item)}
        pagination={pageRef}
        handleTableChange={handleTableChange}
        loading={loading}
      />
      {TablePageAddAction({ onAdd: openAddingModal, module: 'reviews' })}

      {/* Add update form reviews */}
      {modalVisibility && (
        <ReviewAddUpdateForm
          onClose={closeAddingModal}
          visible={modalVisibility}
          values={selectedRow ? selectedRow : {}}
          getAllData={getAllData}
        />
      )}
    </>
  );
};

export default ReviewPage;
