import React, { useEffect, useState } from 'react';

import { Button, Select, Input, Divider, TimePicker } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import * as labortry from '../../../services/laboratries';
import { showErrorMessage, showSuccessMessage } from '../../../utils/AppUtils';
import moment from 'moment';
import api from '../../../services/Api';
import AsyncSearchSelect from '../../../components/AsyncSearchSelect';
import { useParams, useNavigate } from 'react-router-dom'; //useHistory replace useNavigate
import GoogleMapPlaces from '../../maps/components/GoogleMapPlaces';
import { IPageData } from '../../../interfaces/page';
import Spinner from '../../../components/Spinner';
import { usePageData } from '../../../hooks/usePage';
import FileUpload from '../../../components/UploadImages/FileUpload';
import { getUserType, updateUserInStorage } from '../../../utils/StorageService';
// import ImageLoader from '../../../layout/components/ImageLoader';
import PhoneInput from 'react-phone-number-input';

const { Item } = Form;
const { TextArea } = Input;
const pageData: IPageData = {
  title: 'Laboratory',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Dashboard',
      route: 'dashboard'
    },
    {
      title: 'Laboratory'
    }
  ]
};
const { RangePicker } = TimePicker;
const LaboratryAddUpdateForm = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [allTests, setAllTests] = useState([]);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [customFormValues, setCustomFormValues] = useState<any>({
    hours_type: '24_hours',
    start_time: '00:00',
    end_time: '23:59'
  });
  const [values, setValues] = useState<any>({});
  const [allCountries, setAllCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  usePageData(pageData);

  const history: any = useNavigate(); //useHistory replace useHistory
  const { id } = useParams<any>();
  const isView = localStorage.getItem('isViewLab');
  /* getting new_user property to check user signin first time with no profile */
  const new_user = localStorage.getItem('new_user');
  /* Fetch fields data */
  useEffect(() => {
    /* Fetch fields data */

    api.get('/lab-tests').then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setAllTests(res?.data?.data?.data);
      }
    });
    api.get('/countries?per_page=1000').then((res: any) => {
      if (res?.data?.data?.countries?.data?.length) {
        setAllCountries(res?.data?.data?.countries?.data);
      }
    });
    if (id && id !== 'create' && new_user !== '1') {
      fetchAndSetData();
    }
  }, []);
  /* Fetch data by id and set values */
  const fetchAndSetData = () => {
    labortry.getById(+id).then((res: any) => {
      if (res?.data?.data && Object.values(res?.data?.data).length) {
        setValues(res?.data?.data);
      }
    });
  };
  /* Fetch data by id and set values */
  const fetchAndSetStatesData = (value) => {
    api.get(`/states?country_id=${value}&per_page=1000`).then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setStates(res?.data?.data?.data);
      }
    });
  };
  /* Fetch data by id and set values */
  const fetchAndSetCitiesData = (value) => {
    api.get(`/cities?state_id=${value}&per_page=1000`).then((res: any) => {
      if (res?.data?.data?.data?.length) {
        setCities(res?.data?.data?.data);
      }
    });
  };
  useEffect(() => {
    if (Object?.keys(values)?.length && id !== 'create') {
      const clonedValues = Object.assign({}, values);
      if (clonedValues.date) {
        clonedValues.date = moment(clonedValues.date);
      }
      if (clonedValues.labTest.length) {
        const test_ids = [];
        clonedValues.labTest.map((item) => test_ids.push(item.test_id));
        clonedValues.test_ids = test_ids;
      }
      fetchAndSetStatesData(clonedValues?.country_id);
      fetchAndSetCitiesData(clonedValues?.state_id);
      form.setFieldsValue({
        ...clonedValues
      });
      setCustomFormValues(clonedValues);
    }
  }, [values]);
  /* Handle custom form onchange */
  const handleCustomFormChange = (value, formName) => {
    if (formName === 'latLng') {
      form.setFieldsValue({
        lat: value?.lat,
        longitude: value?.longitude,
        address: value?.address,
        map_link: value?.map_link
      });
    } else {
      if (formName === 'country_id') {
        form.resetFields(['state_id', 'city_id']);
        fetchAndSetStatesData(value);
      } else if (formName === 'state_id') {
        form.resetFields(['city_id']);
        fetchAndSetCitiesData(value);
      }
      setCustomFormValues({ ...customFormValues, [formName]: value });
    }
  };
  /* Time range picker value */
  function handleTimePickerRangeChange(dates) {
    const start_time = moment(new Date(dates[0])).format('HH:mm');
    const end_time = moment(new Date(dates[1])).format('HH:mm');
    console.log('start_time: ', start_time, ', end_time: ', end_time);
    setCustomFormValues({ ...customFormValues, start_time, end_time });
  }
  /* performing operations before hitting api */
  const formateFormBeforeSubmit = (formValues: any) => {
    if (values.user_id) {
      formValues.userId = values.user_id;
      formValues.user_id = values.user_id;
    }

    formValues.lat = formValues?.lat?.toString();
    formValues.longitude = formValues?.longitude?.toString();
    formValues.logo = customFormValues?.logo;
    formValues.start_time = customFormValues?.start_time?.slice(0, 5);
    formValues.end_time = customFormValues?.end_time?.slice(0, 5);
    formValues.hours_type = customFormValues?.hours_type;
    /* Removing new_user falg  user signin first time with no profile is present */
    if (Number(new_user) === 1) {
      formValues.new_user = '1';
      formValues.userId = id;
      formValues.user_id = id;
    }
    return formValues;
  };
  /* performing operations after success */
  const operationsAfterSubmit = (res) => {
    showSuccessMessage(res);
    setTimeout(() => {
      if (Number(new_user) === 1) {
        updateUserInStorage(res?.data?.data?.id);
        localStorage.removeItem('new_user');
      }
      if (getUserType() !== 'admin') {
        history('/vertical/dashboard');
      } else {
        history(-1);
      }
    }, 1000);
  };
  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const formValuesFormate = formateFormBeforeSubmit(formValues);
      if (id && id !== 'create') {
        const res = await labortry.update(formValuesFormate, id);
        setLoading(false);
        if (res?.status === 200) {
          operationsAfterSubmit(res);
        } else {
          showErrorMessage(res);
        }
      } else {
        const res = await labortry.add(formValuesFormate);
        setLoading(false);
        if (res?.status === 200) {
          operationsAfterSubmit(res);
        } else {
          showErrorMessage(res);
        }
      }
    } catch (e: any) {
      showErrorMessage(e.data);
      setLoading(false);
    }
  };
  if (id && id !== 'create' && !Object.values(values).length && Number(new_user) !== 1) {
    return <Spinner />;
  }

  const ViewPageDisable = () => (isView === 'true' ? true : false);
  return (
    <>
      <Form
        disabled={ViewPageDisable()}
        form={form}
        requiredMark
        layout='vertical'
        scrollToFirstError
        className='mb-4'
        onFinish={handleSubmit}
        initialValues={{
          is_active: 1
        }}
      >
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <Item name='logo'>
                <FileUpload
                  uploadType='logo'
                  mediaLink={form.getFieldValue('logo')}
                  placeholder='Upload Image'
                  handleUpload={(img) => handleCustomFormChange(img, 'logo')}
                />
              </Item>
            </div>
            {id && id === 'create' && (
              <div className='form-group'>
                <Item
                  name='userId'
                  rules={[{ required: true, message: 'Please select the labortry' }]}
                >
                  <AsyncSearchSelect
                    placeholder='Search Laboratory User'
                    url='/users?type=laboratory&'
                    dataObj='laboratory'
                    initialValues={
                      values?.user && {
                        label: values['user']?.name,
                        value: values['user']?.id
                      }
                    }
                    selectedValue={(item) => form.setFieldsValue({ userId: item?.value })}
                  />
                </Item>
              </div>
            )}
            <div className='form-group'>
              <Item
                name='hospital_id'
                rules={[{ required: true, message: 'Please select the hospital' }]}
              >
                <AsyncSearchSelect
                  placeholder='Search Hospital'
                  url='/hospitals?'
                  dataObj='hospital'
                  initialValues={
                    values?.hospital && {
                      label: values['hospital']?.name,
                      value: values['hospital']?.id
                    }
                  }
                  selectedValue={(item) => form.setFieldsValue({ hospital_id: item?.value })}
                />
              </Item>
            </div>
            <div className='form-group'>
              <Item name='name' rules={[{ required: true, message: 'Name is required' }]}>
                <Input placeholder='Name' type='text' />
              </Item>
            </div>

            <div className='form-group'>
              <Item name='about' rules={[{ required: true, message: 'about is required' }]}>
                <TextArea rows={3} placeholder='About' />
              </Item>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                  <Item name='hours_type'>
                    <Select
                      placeholder='Opening hours'
                      onChange={(value) => handleCustomFormChange(value, 'hours_type')}
                      // defaultValue={'24_hours'}
                    >
                      <Select.Option value='24_hours'>Open 24 hours</Select.Option>
                      <Select.Option value='selected_Hours'>Selected Hours</Select.Option>
                    </Select>
                  </Item>
                </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                  <RangePicker
                    disabled={
                      customFormValues?.hours_type && customFormValues?.hours_type === '24_hours'
                        ? true
                        : false
                    }
                    style={{ width: '100%' }}
                    value={[
                      //@ts-ignore
                      customFormValues?.start_time &&
                      customFormValues?.start_time !== 'Invalid date'
                        ? moment(customFormValues?.start_time, 'HH:mm:ss')
                        : moment('00:00', 'HH:mm'),
                      //@ts-ignore
                      customFormValues?.start_time && customFormValues?.end_time !== 'Invalid date'
                        ? moment(customFormValues?.end_time, 'HH:mm:ss')
                        : moment('23:59', 'HH:mm')
                    ]}
                    format='HH:mm'
                    use12Hours
                    size='large'
                    onChange={handleTimePickerRangeChange}
                  />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <Item name='phone' rules={[{ required: true, message: 'Phone Number is required' }]}>
                <PhoneInput
                  defaultCountry='OM'
                  placeholder='Phone Number'
                  international
                  style={{ borderRadius: 20, height: 40, padding: 5 }}
                  value={customFormValues?.phone}
                  onChange={(value) => handleCustomFormChange(value, 'phone')}
                />
              </Item>
            </div>
            {/* Select Location */}
            {mapModalVisible && (
              <GoogleMapPlaces
                visible={mapModalVisible}
                onClose={() => setMapModalVisible(false)}
                setLatLng={(latLng) => handleCustomFormChange(latLng, 'latLng')}
                lat={form.getFieldValue('lat')}
                longitude={form.getFieldValue('longitude')}
                address={form.getFieldValue('address')}
                map_link={form.getFieldValue('map_link')}
                title={<h3 className='title'>{values?.id ? 'Update Location' : 'Add Location'}</h3>}
              />
            )}
            <Divider orientation='left' className='divider-legend'>
              Select Location
            </Divider>
            <Button
              type='dashed'
              style={{ width: '100%', marginBottom: 10 }}
              onClick={() => setMapModalVisible(true)}
            >
              Select Lab Location
            </Button>
            <div className='form-group'>
              <Item name='map_link' rules={[{ required: true, message: 'Map link is required' }]}>
                <Input placeholder='GoogleMap Location Link' type='text' readOnly={true} />
              </Item>
            </div>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Item name='lat' rules={[{ required: true, message: 'lat is required' }]}>
                    <Input readOnly placeholder='Location Latitude' value={customFormValues?.lat} />
                  </Item>
                </div>
              </div>

              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Item
                    name='longitude'
                    rules={[
                      {
                        required: true,
                        message: 'Longitude is required'
                      }
                    ]}
                  >
                    <Input
                      readOnly
                      placeholder='Location longitude'
                      value={customFormValues?.longitude}
                    />
                  </Item>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <Item
                name='address'
                rules={[
                  {
                    required: true,
                    message: 'address is required'
                  }
                ]}
              >
                <TextArea
                  rows={3}
                  value={customFormValues?.address}
                  onChange={(e) => handleCustomFormChange(e.target.value, 'address')}
                  placeholder='Address'
                />
              </Item>
            </div>
            <Divider />
            {/* next row */}
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Item
                    name='country_id'
                    rules={[{ required: true, message: 'Country is required' }]}
                  >
                    <Select
                      placeholder='Country'
                      showSearch={true}
                      filterOption={(input, option) => {
                        const filtered = option?.props?.children?.filter((item) =>
                          item?.toLowerCase()?.includes(input?.toLowerCase())
                        );
                        return filtered.length >= 1 ? true : false;
                      }}
                      optionFilterProp='children'
                      allowClear
                      onChange={(value) => handleCustomFormChange(value, 'country_id')}
                    >
                      {allCountries?.length > 0 &&
                        allCountries?.map((item: any, i) => (
                          <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                            {item.name}{' '}
                          </Select.Option>
                        ))}
                    </Select>
                  </Item>
                </div>
              </div>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Item name='state_id' rules={[{ required: true, message: 'State is required' }]}>
                    <Select
                      onChange={(value) => handleCustomFormChange(value, 'state_id')}
                      placeholder='State'
                      showSearch={true}
                      filterOption={(input, option) => {
                        const filtered = option?.props?.children?.filter((item) =>
                          item?.toLowerCase()?.includes(input?.toLowerCase())
                        );
                        return filtered.length >= 1 ? true : false;
                      }}
                      optionFilterProp='children'
                      allowClear
                    >
                      {states?.map((item: any, i) => (
                        <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                          {item.name}{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <Item name='city_id' rules={[{ required: true, message: 'City is required' }]}>
                <Select
                  placeholder='City'
                  showSearch={true}
                  filterOption={(input, option) => {
                    const filtered = option?.props?.children?.filter((item) =>
                      item?.toLowerCase()?.includes(input?.toLowerCase())
                    );
                    return filtered.length >= 1 ? true : false;
                  }}
                  optionFilterProp='children'
                  allowClear
                  onChange={(value) => handleCustomFormChange(value, 'city_id')}
                >
                  {cities?.length > 0 &&
                    cities?.map((item: any, i) => (
                      <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                        {item.name}{' '}
                      </Select.Option>
                    ))}
                </Select>
              </Item>
            </div>
            <div className='form-group'>
              <Item
                name='test_ids'
                rules={[{ required: true, message: 'tests selection is required' }]}
              >
                <Select placeholder='Select Tests' mode='multiple' allowClear>
                  {allTests?.length > 0 &&
                    allTests?.map((item: any, i) => (
                      <Select.Option key={`item-${item.id}-${i}`} value={item.id}>
                        {item.name}{' '}
                      </Select.Option>
                    ))}
                </Select>
              </Item>
            </div>
            <div className='form-group'>
              <Item name='is_active'>
                <Select placeholder='Status'>
                  <Select.Option value={1}>Active</Select.Option>
                  <Select.Option value={0}>InActive</Select.Option>
                </Select>
              </Item>
            </div>

            {isView ? (
              <></>
            ) : (
              // <div className='d-flex justify-content-between'>
              //   <Button onClick={() => history(-1)} type='default'>
              //     Go back
              //   </Button>
              //   <Button block={false} onClick={() => history(-1)} type='primary'>
              //     Ok
              //   </Button>
              // </div>
              <div className='d-flex justify-content-between'>
                <Button
                  onClick={() =>
                    getUserType() !== 'admin' ? history('/vertical/dashboard') : history(-1)
                  }
                  type='default'
                >
                  Cancel
                </Button>
                <Button
                  block={false}
                  type='primary'
                  disabled={loading}
                  // loading={loading}
                  htmlType='submit'
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
      <div style={{ width: '530px' }}>
        {isView ? (
          <div className='d-flex justify-content-between'>
            <Button onClick={() => history(-1)} type='default'>
              Go back
            </Button>
            <Button block={false} onClick={() => history(-1)} type='primary'>
              Ok
            </Button>
          </div>
        ) : (
          <></>
          // <div className='d-flex justify-content-between'>
          //   <Button
          //     onClick={() =>
          //       getUserType() !== 'admin' ? history('/vertical/dashboard') : history(-1)
          //     }
          //     type='default'
          //   >
          //     Cancel
          //   </Button>
          //   <Button
          //     block={false}
          //     type='primary'
          //     disabled={loading}
          //     // loading={loading}
          //     htmlType='submit'
          //   >
          //     Submit
          //   </Button>
          // </div>
        )}
      </div>
    </>
  );
};

export default LaboratryAddUpdateForm;
